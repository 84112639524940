<template>
  <div
    class="full-width row items-start no-wrap q-px-md q-py-sm offer-position-container"
    @keydown.up.stop
    @keydown.down.stop
    @keydown.left.stop
    @keydown.right.stop
  >
    <div
      v-if="position"
      class="offer-position-drag-handle-container offer-position-drag-handle-reference"
    >
      <q-icon
        name="sym_r_drag_indicator"
        color="neutral-5"
        class="offer-position-drag-handle"
      />
    </div>
    <div class="full-width col column no-wrap gap-sm">
      <div class="row items-center no-wrap full-width gap-sm">
        <div class="position-number">
          {{ order !== null ? `${order + 1}.` : "" }}
        </div>
        <single-product-input
          ref="productInputEl"
          :group="group"
          :position="position"
          v-model:product="product"
          :disabled="disabled"
          class="col"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @keydown.stop.left
          @keydown.stop.right
          @keydown.stop.escape="productInputEl?.blur()"
        />
        <amount-and-unit-input
          v-if="position"
          ref="amountAndUnitInputEl"
          v-model:amount="amount"
          v-model:selectedUnit="unit"
          :unitChoices="unitChoices"
          :disabled="disabled"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          :highlighted="product !== null && amount === null"
        />
        <price-input
          v-if="organization?.usePrices && position"
          v-model="unitPrice"
          :disabled="disabled"
          :adjustable="product?.isPriceAdjustable ?? false"
        />
      </div>
      <div
        class="row items-center no-wrap full-width gap-sm"
        v-if="position && product?.isNameEditable"
      >
        <div class="position-number"></div>
        <input
          ref="productNameCustomInputEl"
          name="productNameCustom"
          class="standalone-input full-width col"
          v-model="productNameCustom"
          :disabled="disabled"
          :placeholder="$t('Custom Product Name')"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @keydown.stop.left
          @keydown.stop.right
          @keydown.stop.escape="productNameCustomInputEl?.blur()"
        />
      </div>
      <div
        class="row items-center no-wrap full-width gap-sm"
        v-if="position && product?.hasVariant"
      >
        <div class="position-number"></div>
        <input
          ref="variantInputEl"
          name="variant"
          class="standalone-input full-width col"
          v-model="variant"
          :disabled="disabled"
          :placeholder="$t('Product Variant')"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          @keydown.stop.left
          @keydown.stop.right
          @keydown.stop.escape="variantInputEl?.blur()"
        />
        <q-btn
          v-if="position && product && product.isVariantConfigurable"
          flat
          dense
          size="sm"
          color="neutral-7"
          icon="sym_r_tune"
          @click="
            showProductVariantConfigurator(group, position, variant || '')
          "
        >
          <q-tooltip>{{
            $t("inquiryPositionsPage.offerPositionGroup.configureVariant")
          }}</q-tooltip>
        </q-btn>
      </div>
      <div class="row items-end no-wrap full-width gap-sm" v-if="position">
        <div class="position-number"></div>
        <notes-input
          v-model:notes="notes"
          :disabled="disabled"
          @focus="$emit('focus')"
          @blur="$emit('blur')"
          class="col col-grow"
        />
        <div class="row items-center no-wrap gap-sm next-to-notes-container">
          <div class="alternative-checkbox-container">
            <q-checkbox
              class="checkbox-margin-right"
              dense
              size="sm"
              color="neutral-5"
              v-model="isAlternative"
              :disable="disabled"
              :label="$t('Alternative position')"
              @focus="$emit('focus')"
              @blur="$emit('blur')"
            />
          </div>
          <total-price-display
            v-if="organization?.usePrices && position"
            :position="position"
          />
        </div>
      </div>
    </div>
    <div class="col-auto q-ml-sm">
      <q-btn
        :style="{ visibility: position ? 'visible' : 'hidden' }"
        dense
        flat
        size="sm"
        icon="sym_r_delete"
        color="neutral-6"
        :disabled="disabled"
        @click="deleteOfferPosition(group.id, position!.id)"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import NumberInput from "@/components/NumberInput.vue";
import { useProductVariantConfigurator } from "@/composables/useProductVariantConfigurator";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPosition } from "@/types/offerPosition";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import type { Unit } from "@/types/product";
import {
  convertAmount,
  getAmountAndUnitForNewOfferPosition,
} from "@/utils/productUnitAmount";
import { watchDebounced, watchIgnorable } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, nextTick, ref, watch } from "vue";
import AmountAndUnitInput from "./AmountAndUnitInput.vue";
import NotesInput from "./NotesInput.vue";
import PriceInput from "./PriceInput.vue";
import SingleProductInput from "./SingleProductInput.vue";
import TotalPriceDisplay from "./TotalPriceDisplay.vue";

const { deleteOfferPosition, updateOfferPosition, addOfferPosition } =
  useCurrentOfferPositionGroupsStore();

const { showProductVariantConfigurator } = useProductVariantConfigurator();
const { organization } = storeToRefs(useCurrentOrganizationStore());

const props = defineProps<{
  group: OfferPositionGroup;
  position?: OfferPosition;
  disabled: boolean;
  order: number | null;
}>();

const emit = defineEmits(["focus", "blur", "offerPositionAdded"]);

const productInputEl = ref<typeof SingleProductInput | null>(null);
const variantInputEl = ref<HTMLInputElement | null>(null);
const productNameCustomInputEl = ref<HTMLInputElement | null>(null);
const amountAndUnitInputEl = ref<typeof NumberInput | null>(null);

const product = ref(props.position?.product || null);
const amount = ref(
  props.position ? props.position.amount : props.group.boqAmount || 1
);
const unit = ref(props.position?.unit || props.group.boqUnit);
const notes = ref(props.position?.notes || "");
const isAlternative = ref(props.position?.isAlternative || false);
const unitPrice = ref(props.position?.unitPrice || null);
const variant = ref(props.position?.variant || null);
const productNameCustom = ref(props.position?.productNameCustom || null);
const debouncedVariant = ref(variant.value);
const debouncedProductNameCustom = ref(productNameCustom.value);

watchDebounced(
  variant,
  (value) => {
    debouncedVariant.value = value;
  },
  { debounce: 500 }
);

watchDebounced(
  productNameCustom,
  (value) => {
    debouncedProductNameCustom.value = value;
  },
  { debounce: 500 }
);

watch(
  () => props.position,
  (position) => {
    // This change comes from the data store, so shouldn't trigger another update of said store
    dontTriggerUpdate(() => {
      product.value = position?.product || null;
      amount.value = position ? position.amount : props.group.boqAmount || 1;
      unit.value = position?.unit || props.group.boqUnit;
      notes.value = position?.notes || "";
      isAlternative.value = position?.isAlternative || false;
      variant.value = position?.variant || null;
      debouncedVariant.value = variant.value;
      productNameCustom.value = position?.productNameCustom || null;
      debouncedProductNameCustom.value = productNameCustom.value;
      unitPrice.value = position?.unitPrice || null;
    });
  }
);

const unitChoices = computed(() => {
  const product = props.position?.product || null;
  if (!product) return [];
  return [product.unit, ...product.alternativeUnits];
});

defineExpose({
  select: () => {
    if (productNameCustomInputEl.value) {
      productNameCustomInputEl.value.focus();
    } else if (variantInputEl.value) {
      variantInputEl.value.focus();
    } else if (amountAndUnitInputEl.value) {
      amountAndUnitInputEl.value.focus();
    }
  },
});

const addOfferPositionIfProductSelected = async () => {
  if (props.position) return;
  if (!product.value) return;

  const [newAmount, newUnit] = getAmountAndUnitForNewOfferPosition(
    props.group.boqAmount,
    props.group.boqUnit,
    product.value
  );
  unit.value = newUnit;
  amount.value = newAmount;

  addOfferPosition(props.group.id, {
    product: product.value,
    amount: newAmount,
    unit: newUnit,
    variant: variant.value,
    isAlternative: isAlternative.value,
    notes: notes.value,
    unitPrice: unitPrice.value ?? product.value.listPrice,
  });

  resetOfferPositionContainer();

  emit("offerPositionAdded");
};
watch(product, addOfferPositionIfProductSelected);
watch(product, async () => {
  await nextTick();
  if (productNameCustomInputEl.value) {
    productNameCustomInputEl.value.focus();
  }
});

const { ignoreUpdates: dontTriggerUpdate } = watchIgnorable(
  () => [
    product.value,
    amount.value,
    unit.value,
    debouncedVariant.value,
    debouncedProductNameCustom.value,
    isAlternative.value,
    notes.value,
    unitPrice.value,
  ],
  async () => {
    await update();
  }
);

function resetOfferPositionContainer() {
  product.value = null;
  amount.value = props.group.boqAmount;
  unit.value = null;
  notes.value = "";
  variant.value = null;
  debouncedVariant.value = null;
  productNameCustom.value = null;
  debouncedProductNameCustom.value = null;
  isAlternative.value = false;
  unitPrice.value = null;
}

async function update() {
  if (!props.position) {
    return;
  }
  const data = getOfferPositionData();
  if (!data) return;
  await updateOfferPosition(props.group.id, props.position.id, data);
}

function getOfferPositionData() {
  /// Do a full (PUT-like) update as soon as all required fields are set
  /// This prevents race conditions and out-of-sync state with the backend
  if (!props.position) throw new Error("No position to get changed data from");
  const result = {} as Partial<OfferPosition>;

  if (!product.value) {
    // valid OP needs a product
    return;
  }
  result.product = product.value;
  if (product.value !== props.position.product) {
    if (!product.value.isNameEditable) {
      productNameCustom.value = null;
    }
    result.productConfidence = 1;
    result.isAiSuggestion = false;
  }

  result.unit = getUnitAfterProductChange();

  const changedAmount = getChangedAmountAfterUnitChange(result);
  if (changedAmount !== undefined) {
    result.amount = changedAmount;
  } else {
    result.amount = amount.value;
  }

  result.variant = variant.value;
  result.productNameCustom = productNameCustom.value;
  result.isAlternative = isAlternative.value;
  result.notes = notes.value;
  result.unitPrice = getPriceAfterUnitChange();
  return result;
}

function getPriceAfterUnitChange(): number | null {
  if (!product.value || !props.position)
    throw new Error("No position to get changed data from");

  if (product.value.id === props.position.product.id) {
    // unchanged product -> keep the same unit price
    return unitPrice.value;
  }

  return product.value.listPrice;
}

function getUnitAfterProductChange(): Unit {
  if (!product.value || !props.position) {
    throw new Error("No position to get changed data from");
  }

  const currentUnit = unit.value || props.position.unit;

  const allowedProductUnits = product.value.alternativeUnits.concat(
    product.value.unit
  );

  if (allowedProductUnits.includes(currentUnit)) {
    return currentUnit;
  }

  // Otherwise, switch to BOQ unit if possible
  if (
    props.group.boqUnit &&
    allowedProductUnits.includes(props.group.boqUnit)
  ) {
    return props.group.boqUnit;
  }

  // Otherwise, switch to product unit
  return product.value.unit;
}

function getChangedAmountAfterUnitChange(
  result: Partial<OfferPosition>
): number | null | undefined {
  if (!props.position) throw new Error("No position to get changed data from");

  if (!props.position.amount) return;

  if (!result.unit) return;

  if (result.unit === props.position.unit) return; // Unit doesn't change -> amount doesn't change

  const newProduct = result.product || props.position.product;

  const convertedAmount = convertAmount(
    props.position.amount,
    props.position.unit,
    result.unit,
    newProduct
  );

  // If the conversion fails, keep the amount
  return convertedAmount == null ? undefined : convertedAmount;
}
</script>

<style scoped lang="scss">
.alternative-checkbox-container {
  width: 129px;
}

.amount-input {
  width: 60px;
}

.notes-badge {
  font-size: 10px;
}

.checkbox-margin-right {
  margin-right: 32px;
}

.next-to-notes-container {
  height: 24px;
}

.position-number {
  width: 24px;
  padding-right: 4px;
  text-align: right;
}

.offer-position-drag-handle-container {
  width: 8px;
}

.offer-position-drag-handle {
  cursor: move;
  margin-top: 4px;
  display: none;
  transform: translateX(-16px);
  font-size: 16px;
}

.offer-position-container:hover .offer-position-drag-handle {
  display: block;
}
</style>
