<template>
  <q-menu
    ref="menuEl"
    :model-value="show"
    no-parent-event
    anchor="center middle"
    self="center middle"
    @keydown.stop.enter="$emit('select-variant', variant)"
    @keydown.stop.tab="$emit('select-variant', variant)"
    @keydown.stop.esc="$emit('cancel')"
    @hide="$emit('cancel')"
  >
    <q-card class="q-pa-md column">
      <product-chip
        v-if="product"
        :product="product"
        :is-ai-suggestion="false"
      />
      <q-input
        outlined
        dense
        stack-label
        v-model="variant"
        autofocus
        :label="$t('product.variant')"
        class="q-mt-sm"
        @keydown.stop.a
        @keydown.stop.f
      />
    </q-card>
  </q-menu>
</template>

<script setup lang="ts">
import type { Product } from "@/types/product";
import { QMenu } from "quasar";
import { ref, watch } from "vue";
import ProductChip from "@/components/InquiryPositionsPage/ProductChip.vue";

const props = defineProps<{
  show: boolean;
  product: Product | null;
}>();

defineEmits<{
  "select-variant": [variant: string];
  cancel: [];
}>();

const variant = ref("");

watch(
  () => props.show,
  (show) => {
    if (show) {
      variant.value = "";
    }
  }
);
</script>
