export type OrganizationRoleName =
  | "organization_admin"
  | "product_admin"
  | "organization_user";

export const organizationRoleOptions: OrganizationRoleName[] = [
  "organization_admin",
  "product_admin",
  "organization_user",
];

export type InboxRoleName = "inbox_admin" | "inbox_worker";

export const inboxRoleOptions: InboxRoleName[] = [
  "inbox_admin",
  "inbox_worker",
];

export interface OrganizationRole {
  role: OrganizationRoleName;
  objectId: number;
  objectModel: "organization";
}

export interface InboxRole {
  role: InboxRoleName;
  objectId: number;
  objectModel: "inbox";
}

export type Role = OrganizationRole | InboxRole;

export const RoleLabels: Record<OrganizationRoleName | InboxRoleName, string> =
  {
    organization_admin: "Organization Admin",
    organization_user: "Organization User",
    product_admin: "Product Admin",
    inbox_admin: "Inbox Admin",
    inbox_worker: "Inbox Worker",
  };
