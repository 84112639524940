import type { Point } from "@/types/boundingRegion";

export function degToRad(deg: number): number {
  return (deg * Math.PI) / 180;
}

export function getRotation(leftTop: Point, rightTop: Point) {
  const xDist = rightTop[0] - leftTop[0];
  const yDist = rightTop[1] - leftTop[1];
  return Math.atan2(yDist, xDist);
}

export function distance2D(point: Point, otherPoint: Point): number {
  // using Pythagoras
  const xDist = otherPoint[0] - point[0];
  const yDist = otherPoint[1] - point[1];
  return Math.sqrt(xDist * xDist + yDist * yDist);
}
