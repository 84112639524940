import { getInbox } from "@/api/inbox";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Inbox, InboxView } from "@/types/inbox";
import type { InquiryStatus } from "@/types/inquiry";
import { IN_PROGRESS_BASE_INQUIRY_STATUSES } from "@/types/inquiry";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export const useCurrentInboxStore = defineStore("currentInbox", () => {
  const { organizationId, inboxId, inboxView } = useRouteParams();

  const inbox = ref<Inbox | null>(null);
  const is404 = ref(false);

  async function loadData() {
    if (isNaN(organizationId.value) || isNaN(inboxId.value)) {
      return;
    }

    try {
      inbox.value = await getInbox(organizationId.value, inboxId.value);
    } catch (error: any) {
      if (error.response?.status === 404) {
        is404.value = true;
        return;
      } else {
        throw error;
      }
    }
    is404.value = false;
  }
  loadData();
  watch([organizationId, inboxId], loadData);

  const availableInquiryStatuses = computed(() => {
    if (!inbox.value) {
      return [];
    }
    return filterAvailableStatusesByView(
      inbox.value.availableStatuses,
      inboxView.value
    );
  });

  return {
    inboxId,
    inboxView,
    inbox,
    is404,
    availableInquiryStatuses,
  };
});

function filterAvailableStatusesByView(
  availableStatuses: InquiryStatus[],
  view: InboxView
) {
  switch (view) {
    case "all":
      return availableStatuses;
    case "mine":
    case "in_progress":
      return availableStatuses.filter((status) =>
        IN_PROGRESS_BASE_INQUIRY_STATUSES.includes(status.originalStatus)
      );
    case "archived":
      return availableStatuses.filter(
        (status) => status.originalStatus === "ARCHIVED"
      );
    case "finalized":
      return availableStatuses.filter(
        (status) => status.originalStatus === "FINALIZED"
      );
  }
}
