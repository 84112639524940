import { client } from "@/api/client/client";
import { DEFAULT_PAGE_SIZE_FOR_QUOTATIONS } from "@/config/constants";
import type {
  ApiQuotationWrite,
  Quotation,
  QuotationStatus,
  QuotationWrite,
} from "@/types/quotation";
import { formatISO, parseISO } from "date-fns";
import { parseCursor } from "./utils";

type ApiQuotation = {
  createdAt: string;
  acceptedOrDeclinedAt: string | null;
} & Omit<Quotation, "createdAt" | "acceptedOrDeclinedAt">;

export interface ListQuotationFilterOptions {
  status?: QuotationStatus[];
  assignedUserId?: (number | null)[];
  inboxId?: number[];
  inquiryShortCode?: string[];
  tags?: number[];
  customer?: string[];
  buildingProject?: string[];
  ordering?: string;
}

export async function listQuotations(
  organizationId: number,
  pageSize: number = DEFAULT_PAGE_SIZE_FOR_QUOTATIONS,
  cursor: string | null = null,
  filterOptions: ListQuotationFilterOptions = {},
  returnCount: boolean = true
): Promise<{
  data: Quotation[];
  next: string | null;
  previous: string | null;
  count: number | null;
}> {
  const response = await client.get(
    `/organizations/${organizationId}/quotations/`,
    {
      params: {
        page_size: pageSize,
        page_result_count: returnCount,
        cursor,
        ...processFilterOptions(filterOptions),
      },
    }
  );

  return {
    data: response.data.results.map(postprocessQuotation),
    next: parseCursor(response.data.next),
    previous: parseCursor(response.data.previous),
    count: response.data.count,
  };
}

export async function getQuotation(
  organizationId: number,
  quotationId: number
): Promise<Quotation> {
  const response = await client.get(
    `/organizations/${organizationId}/quotations/${quotationId}/`
  );

  return postprocessQuotation(response.data);
}

export async function updateQuotation(
  organizationId: number,
  quotationId: number,
  data: Partial<QuotationWrite>
): Promise<Quotation> {
  const apiData = preprocessQuotation(data);
  const response = await client.patch<ApiQuotation>(
    `/organizations/${organizationId}/quotations/${quotationId}/`,
    apiData
  );

  return postprocessQuotation(response.data);
}

export async function downloadQuotation(
  organizationId: number,
  quotationId: number
): Promise<void> {
  window.open(
    `/api/organizations/${organizationId}/quotations/${quotationId}/download/`,
    "_blank"
  );
}

function postprocessQuotation(object: ApiQuotation): Quotation {
  const { createdAt, acceptedOrDeclinedAt, ...rest } = object;

  return {
    ...rest,
    createdAt: parseISO(createdAt),
    acceptedOrDeclinedAt: acceptedOrDeclinedAt
      ? parseISO(acceptedOrDeclinedAt)
      : null,
  };
}

function preprocessQuotation(
  quotation: Partial<QuotationWrite>
): Partial<ApiQuotationWrite> {
  return {
    ...quotation,
    acceptedOrDeclinedAt: quotation.acceptedOrDeclinedAt
      ? formatISO(quotation.acceptedOrDeclinedAt)
      : null,
  };
}

function processFilterOptions(filterOptions: ListQuotationFilterOptions) {
  const {
    assignedUserId,
    status,
    customer,
    inboxId,
    inquiryShortCode,
    buildingProject,
    ...rest
  } = filterOptions;

  return {
    ...rest,
    assignedUserId: assignedUserId
      ?.map((id) => (id === null ? "null" : id))
      .join(","),
    status: status?.join(","),
    customer: customer?.join(","),
    inboxId: inboxId?.join(","),
    inquiryShortCode: inquiryShortCode?.join(","),
    buildingProject: buildingProject?.join(","),
  };
}
