<template>
  <q-btn
    icon="sym_r_filter_list"
    :label="$t('Filter')"
    class="font-weight-inherit no-wrap"
    dense
    size="sm"
  >
    <q-menu no-route-dismiss :offset="[0, 2]">
      <q-item
        dense
        clickable
        v-ripple
        active-class="text-primary-7 bg-primary-1"
        :active="!!filterOptions.status?.length"
      >
        <q-item-section>
          <div class="row items-center">
            <q-icon :name="icons.filterStatus" size="xs" class="q-mr-sm" />
            {{ $t("Status") }}
          </div>
        </q-item-section>
        <q-menu anchor="top right" self="top left" no-route-dismiss>
          <q-item
            dense
            clickable
            v-ripple
            v-for="status in availableSupplierRfqStatuses"
            :key="status"
            @click="toggleStatusOption(status)"
            :class="{
              'bg-neutral-3': filterOptions.status?.includes(status),
            }"
          >
            <q-item-section>
              <q-item-label class="status-option-label">
                <q-checkbox
                  dense
                  size="sm"
                  :model-value="filterOptions.status?.includes(status)"
                  @click="toggleStatusOption(status)"
                  class="q-mr-sm"
                />
                <supplier-rfq-status-icon :status="status" class="q-mr-sm" />
                {{ $t(`supplierRfqStatus.${status}`) }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-menu>
      </q-item>
      <q-item
        dense
        clickable
        v-ripple
        active-class="text-primary-7 bg-primary-1"
        :active="!!filterOptions.assignedUserId?.length"
      >
        <q-item-section>
          <div class="row items-center">
            <q-icon :name="icons.filterUser" size="xs" class="q-mr-sm" />
            {{ $t("User") }}
          </div>
        </q-item-section>
        <multi-user-select-menu
          anchor="top right"
          self="top left"
          no-route-dismiss
          v-model:selectedUsers="selectedUsers"
          :store="currentOrganizationUsersStore"
        />
      </q-item>
      <q-item
        dense
        clickable
        v-ripple
        active-class="text-primary-7 bg-primary-1"
        :active="filterOptions.buildingProject !== undefined"
      >
        <q-item-section>
          <div class="row items-center">
            <q-icon
              :name="icons.filterBuildingProject"
              size="xs"
              class="q-mr-sm"
            />
            {{ $t("inquiry.buildingProject") }}
          </div>
        </q-item-section>
        <q-menu anchor="center right" self="center left" no-route-dismiss>
          <q-item dense>
            <q-input
              borderless
              dense
              :placeholder="
                $t('supplierRfqsPage.filterMenuButton.searchBuildingProject')
              "
              v-model="filterBuildingProjectString"
              debounce="300"
              autofocus
            />
          </q-item>
        </q-menu>
      </q-item>
      <q-item
        dense
        clickable
        v-ripple
        active-class="text-primary-7 bg-primary-1"
        :active="filterOptions.inquiryShortCode !== undefined"
      >
        <q-item-section>
          <div class="row items-center">
            <q-icon :name="icons.filterShortCode" size="xs" class="q-mr-sm" />
            {{ $t("objects.inquiry") }}
          </div>
        </q-item-section>
        <q-menu anchor="center right" self="center left" no-route-dismiss>
          <q-item dense>
            <q-input
              borderless
              dense
              :placeholder="
                $t('supplierRfqsPage.filterMenuButton.searchShortCode')
              "
              v-model="filterShortCodeString"
              debounce="300"
              autofocus
            />
          </q-item>
        </q-menu>
      </q-item>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import SupplierRfqStatusIcon from "@/components/SupplierRfqsPage/SupplierRfqStatusIcon.vue";
import MultiUserSelectMenu from "@/components/User/MultiUserSelectMenu.vue";
import icons from "@/config/icons";
import { useCurrentOrganizationUsersStore } from "@/stores/currentOrganizationUsers";
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import type { SupplierRFQStatus } from "@/types/supplierRfq";
import type { UserOrEmptyUser } from "@/types/user";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const currentSupplierRfqsStore = useCurrentSupplierRfqsStore();
const currentOrganizationUsersStore = useCurrentOrganizationUsersStore();
const { filterOptions } = storeToRefs(currentSupplierRfqsStore);
const { usersWithEmptyUser } = storeToRefs(currentOrganizationUsersStore);

const availableSupplierRfqStatuses: SupplierRFQStatus[] = [
  "DRAFT",
  "SENT",
  "ANSWERED",
];

const filterBuildingProjectString = computed({
  get: () => filterOptions.value.buildingProject || "",
  set: (buildingProject: string) => {
    currentSupplierRfqsStore.addFilter(
      "buildingProject",
      buildingProject || undefined
    );
  },
});

const filterShortCodeString = computed({
  get: () => filterOptions.value.inquiryShortCode || "",
  set: (inquiryShortCode: string) => {
    currentSupplierRfqsStore.addFilter(
      "inquiryShortCode",
      inquiryShortCode || undefined
    );
  },
});

function toggleStatusOption(status: SupplierRFQStatus) {
  currentSupplierRfqsStore.addFilter(
    "status",
    filterOptions.value.status?.includes(status)
      ? filterOptions.value.status.filter((s) => s !== status)
      : [...(filterOptions.value.status || []), status]
  );
}

const selectedUsers = computed({
  get: () =>
    usersWithEmptyUser.value.filter((user: UserOrEmptyUser) =>
      filterOptions.value.assignedUserId?.includes(user.id)
    ),
  set: (users: UserOrEmptyUser[]) => {
    currentSupplierRfqsStore.addFilter(
      "assignedUserId",
      users.map((user) => user.id)
    );
  },
});
</script>

<style scoped lang="scss">
.status-option-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
</style>
