<template>
  <div
    :style="highlightStyle"
    :class="elClass"
    ref="htmlElementRef"
    @click="$emit('click')"
    @mouseenter="$emit('mouseenter')"
    @mouseleave="$emit('mouseleave')"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type { Polygon } from "@/types/boundingRegion";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { computed, ref } from "vue";
import type { CanvasSize } from "../PdfViewer/types";
import {
  getDivStyle,
  polygonToDivCoordinates,
} from "../PdfViewer/utils/polygonToDiv";

const props = defineProps<{
  boundingRegion: Polygon;
  pageRotation: number;
  canvasSize: CanvasSize;
  group: OfferPositionGroup;
  isSelected: boolean;
}>();

defineEmits(["click", "mouseenter", "mouseleave"]);

const htmlElementRef = ref<HTMLElement | undefined>(undefined);

const highlightStyle = computed(() => {
  const divCoordinates = polygonToDivCoordinates(
    props.boundingRegion,
    props.pageRotation,
    props.canvasSize
  );
  return getDivStyle(divCoordinates, props.pageRotation, true);
});

const elClass = computed(() => {
  return {
    "display-highlight-marker": true,
    offered: props.group.isOffered,
    completed: props.group.isCompleted,
    selected: props.isSelected,
  };
});

const scrollIntoView = () => {
  if (htmlElementRef.value) {
    htmlElementRef.value.scrollIntoView({
      // @ts-ignore  // value is correct, typescript spec is wrong, see MDN
      behavior: "instant",
      block: "center",
      inline: "center",
    });
  }
};

defineExpose({ scrollIntoView });
</script>

<style lang="scss">
.display-highlight-marker {
  scroll-margin-top: 240px;
  position: absolute;
  pointer-events: auto;
  cursor: pointer;

  &.offered {
    border-left: 16px solid $neutral-3;
  }

  &.completed {
    border-left: 16px solid $neutral-5;
  }

  &.selected {
    border-left: 16px solid $primary-1;
    pointer-events: none; // allow text selection below
  }
}
</style>
