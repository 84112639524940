import type { ApiNotification, Notification } from "@/types/notification";
import type { Paginated } from "@/types/pagination";
import { parseISO } from "date-fns";
import { client } from "./client/client";
import { parseCursor } from "./utils";

export async function listNotifications(
  organizationId: number,
  pageSize: number,
  cursor: string | null
): Promise<Paginated<Notification>> {
  const { data } = await client.get<Paginated<ApiNotification>>(
    `/organizations/${organizationId}/notifications/`,
    {
      params: { page_size: pageSize, cursor },
    }
  );

  return {
    ...data,
    next: parseCursor(data.next),
    previous: parseCursor(data.previous),
    results: data.results.map(postprocessApiNotification),
  };
}

export async function markNotificationAsRead(
  organizationId: number,
  notificationId: number
) {
  await client.patch(
    `/organizations/${organizationId}/notifications/${notificationId}/`,
    {
      isRead: true,
    }
  );
}

export async function markNotificationAsUnread(
  organizationId: number,
  notificationId: number
) {
  await client.patch(
    `/organizations/${organizationId}/notifications/${notificationId}/`,
    {
      isRead: false,
    }
  );
}

export async function markAllNotificationsAsRead(organizationId: number) {
  await client.post(
    `/organizations/${organizationId}/notifications/mark_all_as_read/`
  );
}

export async function getUnreadNotificationCount(organizationId: number) {
  const { data } = await client.get<{ count: number }>(
    `/organizations/${organizationId}/notifications/unread_count/`
  );
  return data.count;
}

function postprocessApiNotification(
  apiNotification: ApiNotification
): Notification {
  return {
    ...apiNotification,
    createdAt: parseISO(apiNotification.createdAt),
  };
}
