<template>
  <q-btn
    size="sm"
    flat
    dense
    :icon="icon"
    class="positions-table-item-toggle-offered-button q-mr-sm"
    color="neutral-7"
    @click.stop.prevent="$emit('toggle')"
    @mousedown.stop
  >
    <q-tooltip :delay="500">
      {{
        isOffered
          ? $t("inquiryPositionsPage.toggleOfferedButton.removeFromOffer")
          : $t("inquiryPositionsPage.toggleOfferedButton.addToOffer")
      }}
      <kbd class="kbd-dark q-ml-xs">a</kbd>
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  isOffered: boolean;
}>();

defineEmits<{
  toggle: [];
  focus: [];
  blur: [];
}>();

const icon = computed(() => {
  if (props.isOffered) {
    return "sym_r_radio_button_checked";
  } else {
    return "sym_r_radio_button_unchecked";
  }
});
</script>

<style scoped lang="scss">
.positions-table-item-toggle-offered-button:focus {
  background-color: $neutral-4;
}
</style>
