import { provideEventBus, useEventBus } from "./useEventBus";

type PositionsEvents = {
  tableRowClick: string;
  selectFromDocument: string;
  clearSelection: void;
};

export const providePositionsEvents = () =>
  provideEventBus<PositionsEvents>("positionsEvents");
export const usePositionsEvents = () =>
  useEventBus<PositionsEvents>("positionsEvents");
