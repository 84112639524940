<template>
  <div class="flex row no-wrap gap-xs items-center">
    <q-checkbox
      size="sm"
      class="select-all-checkbox"
      :model-value="allSelected || null"
      @update:model-value="$emit('select-all')"
      v-show="anySelected"
      :disable="isLoading"
    />
    <div class="text-neutral-8" v-show="anySelected">
      {{ $t("leads selected", selectedInquiries.length) }}
    </div>
    <status-select-button
      icon-only
      @update:status="(status) => $emit('update:status', status)"
      @retry-analysis="
        $emit(
          'retry-analysis',
          selectedInquiries.map((i) => i.id)
        )
      "
      :status="selectedStatus"
      v-show="anySelected"
      :disabled="isLoading"
    />
    <user-select-button
      icon-only
      @update:user="(user) => $emit('assign', user)"
      :user="selectedUser.user"
      :is-indeterminate="selectedUser.isIndeterminate"
      v-show="anySelected"
      :disabled="isLoading"
    />
    <move-multi-inquiry-button
      :selected-inquiries="selectedInquiries"
      @move="$emit('move', $event)"
      v-show="anySelected"
      :disabled="
        isLoading ||
        selectedInquiries.some(
          (i) =>
            !CAN_MOVE_TO_OTHER_INBOX_INQUIRY_STATUSES.includes(
              i.status.originalStatus
            )
        )
      "
    />
    <delete-multi-inquiry-button
      :selected-inquiries="selectedInquiries"
      @delete="$emit('delete')"
      v-show="anySelected"
      :disabled="isLoading"
    />
  </div>
</template>

<script setup lang="ts">
import type { InquiryStatus, ShortInquiry } from "@/types/inquiry";
import type { User } from "@/types/user";
import { computed } from "vue";
import DeleteMultiInquiryButton from "./DeleteMultiInquiryButton.vue";
import MoveMultiInquiryButton from "./MoveMultiInquiryButton.vue";
import StatusSelectButton from "./Status/StatusSelectButton.vue";
import UserSelectButton from "./User/UserSelectButton.vue";
import { CAN_MOVE_TO_OTHER_INBOX_INQUIRY_STATUSES } from "@/types/inquiry";

const props = defineProps<{
  isLoading: boolean;
  allSelected: boolean;
  selectedInquiries: ShortInquiry[];
}>();

const anySelected = computed(() => props.selectedInquiries.length > 0);

const selectedUser = computed(() => {
  const userIds = new Set(
    props.selectedInquiries.map((i) => i.assignedUser?.id)
  );
  if (userIds.size !== 1)
    return {
      user: null,
      isIndeterminate: true,
    };
  return {
    user: props.selectedInquiries[0].assignedUser,
    isIndeterminate: false,
  };
});

const selectedStatus = computed(() => {
  const statusIds = new Set(props.selectedInquiries.map((i) => i.status.name));
  if (statusIds.size !== 1) return null;
  return props.selectedInquiries[0].status;
});

defineEmits<{
  (event: "download-files"): void;
  (event: "download-values"): void;
  (event: "move", targetInboxId: number): void;
  (event: "delete"): void;
  (event: "update:status", status: InquiryStatus): void;
  (event: "assign", user: User | null): void;
  (event: "select-all"): void;
  (event: "retry-analysis", inquiryIds: number[]): void;
}>();
</script>

<style scoped lang="scss">
.select-all-checkbox {
  margin-left: -8.75px; // align visible checkbox with rest of page
}
</style>
