import { inject, provide, type ComputedRef } from "vue";

const OCR_TEXT_SELECTION = "ocrTextSelection";

export interface SelectedWordIndex {
  pageNumber: number;
  index: number;
}

export interface OcrTextSelection {
  startSelection: (pageNumber: number, index: number) => void;
  updateSelection: (pageNumber: number, index: number) => void;
  stopOrResetSelection: () => void;
  isSelected: (pageNumber: number, index: number) => boolean;
  copyText: () => void;
  selectedText: ComputedRef<string | null>;
  registerKeypressHandler: () => void;
}

export function provideTextSelection(methods: OcrTextSelection) {
  provide(OCR_TEXT_SELECTION, methods);
}

export function useTextSelection(): OcrTextSelection {
  const methods = inject<OcrTextSelection>(OCR_TEXT_SELECTION);
  if (!methods) {
    throw new Error(
      "useTextSelection must be used inside OcrTextSelectionProvider"
    );
  }
  return methods;
}
