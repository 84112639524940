<template>
  <q-card
    class="bg-white q-pa-md"
    @keydown.enter="handleEnter"
    @click="focusEditor"
  >
    <comment-editor
      v-model="comment"
      :placeholder="$t('inquiryPage.inquiryHistory.newComment.placeholder')"
      :editable="isEditable"
      ref="commentEditorEl"
    />
    <div class="q-mt-md row justify-end">
      <q-btn
        flat
        dense
        icon="sym_r_publish"
        color="neutral-7"
        :disable="!comment || isPublishing"
        :loading="isPublishing"
        @click.stop="publish"
      >
        <q-tooltip>
          {{ $t("inquiryPage.inquiryHistory.newComment.publish") }}
        </q-tooltip>
      </q-btn>
    </div>
  </q-card>
</template>

<script setup lang="ts">
import { useCurrentInquiryHistoryStore } from "@/stores/currentInquiryHistory";
import type { CommentBodyContent } from "@/types/inquiryHistory";
import { ifCmdOrCtrl } from "@/utils/cmdOrCtrl";
import { ref, shallowRef } from "vue";
import CommentEditor from "./CommentEditor/CommentEditor.vue";

const comment = shallowRef<CommentBodyContent | null>(null);
const isEditable = ref(true);
const isPublishing = ref(false);

const commentEditorEl = ref<HTMLElement | null>(null);

const { addComment } = useCurrentInquiryHistoryStore();

async function publish() {
  if (!comment.value) return;

  isEditable.value = false;
  isPublishing.value = true;

  await addComment(comment.value);

  // @ts-ignore  // ts bug, assignment is correct
  comment.value = null;
  isEditable.value = true;
  isPublishing.value = false;
}

const handleEnter = ifCmdOrCtrl((event: KeyboardEvent) => {
  event.stopPropagation();
  event.preventDefault();
  event.stopImmediatePropagation();
  publish();
});

function focusEditor() {
  commentEditorEl.value?.focus();
}
</script>
