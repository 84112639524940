<template>
  <div class="row no-wrap">
    <q-btn
      dense
      flat
      size="md"
      icon="sym_r_keyboard_arrow_up"
      :disable="!hasPrevious"
      :color="hasPrevious ? 'white' : 'neutral-4'"
      text-color="neutral-7"
      @click="navigate('previous')"
      ><q-tooltip>{{ $t("Previous lead") }}</q-tooltip>
    </q-btn>
    <q-btn
      dense
      flat
      size="md"
      icon="sym_r_keyboard_arrow_down"
      :disable="!hasNext"
      :color="hasNext ? 'white' : 'neutral-4'"
      text-color="neutral-7"
      @click="navigate('next')"
      ><q-tooltip>{{ $t("Next lead") }}</q-tooltip>
    </q-btn>
  </div>
</template>

<script setup lang="ts">
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

export type Direction = "previous" | "next";

const { inquiry } = storeToRefs(useCurrentInquiryStore());

const hasPrevious = computed(() => inquiry.value?.previousInquiryId != null);
const hasNext = computed(() => inquiry.value?.nextInquiryId != null);

const { currentRoute, resolve, push } = useRouter();

function navigate(direction: Direction) {
  const targetInquiryId =
    direction === "previous"
      ? inquiry.value?.previousInquiryId
      : inquiry.value?.nextInquiryId;

  if (!targetInquiryId) return;
  if (!currentRoute.value.name) throw Error("Route name is undefined");
  const newRoute = {
    name: currentRoute.value.name,
    params: { ...currentRoute.value.params },
    query: { ...currentRoute.value.query },
  };
  newRoute.params.inquiryId = targetInquiryId.toString();

  const newPath = resolve(newRoute).fullPath;
  push(newPath);
}
</script>
