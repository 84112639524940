<template>
  <q-list
    class="full-width column no-wrap gap-y-xs sidebar-menu"
    v-if="inboxes"
  >
    <sidebar-menu-item
      :to="{ name: 'notifications' }"
      :label="$t('notificationsPage.label')"
      :unread-count="unreadCount"
    >
      <template #avatar>
        <q-icon name="sym_r_notifications" size="20px" />
      </template>
    </sidebar-menu-item>
    <template v-if="organization?.useQuotationCreation">
      <sidebar-menu-header>
        {{ $t("sidebarMenu.quotations") }}
      </sidebar-menu-header>
      <sidebar-menu-item
        :to="{ name: 'quotations' }"
        :label="$t('sidebarMenu.quotations')"
      >
        <template #avatar>
          <q-icon :name="icons.quotationsPage" size="20px" />
        </template>
      </sidebar-menu-item>
    </template>
    <sidebar-menu-header>
      {{ $t("sidebarMenu.inquiries") }}
    </sidebar-menu-header>
    <div ref="inboxGroupsEl" class="column no-wrap gap-y-xs">
      <sidebar-inbox-group
        v-for="inbox in inboxes"
        :key="inbox.id"
        :inbox="inbox"
      />
    </div>
    <template v-if="organization?.useSupplierData">
      <sidebar-menu-header>
        {{ $t("sidebarMenu.supplierRfqs") }}
      </sidebar-menu-header>
      <sidebar-menu-item
        :to="{
          name: 'supplier-rfqs',
          query: { assignedUserId: currentUser?.id },
        }"
        :label="$t('sidebarMenu.supplierRfqs')"
      >
        <template #avatar>
          <q-icon :name="icons.supplierRfq" size="20px" />
        </template>
      </sidebar-menu-item>
    </template>
    <sidebar-menu-header>
      {{ $t("sidebarMenu.masterData") }}
    </sidebar-menu-header>
    <sidebar-menu-item :to="{ name: 'products' }" :label="$t('Products')">
      <template #avatar>
        <q-icon :name="icons.product" size="20px" />
      </template>
    </sidebar-menu-item>
    <sidebar-menu-item
      v-if="organization?.useCustomerData"
      :to="{ name: 'customers' }"
      :label="$t('Customers')"
    >
      <template #avatar>
        <q-icon :name="icons.customer" size="20px" />
      </template>
    </sidebar-menu-item>
    <sidebar-menu-item
      v-if="organization?.useSupplierData"
      :to="{ name: 'suppliers' }"
      :label="$t('sidebarMenu.suppliers')"
    >
      <template #avatar>
        <q-icon :name="icons.supplier" size="20px" />
      </template>
    </sidebar-menu-item>
    <div class="changelog-widget-spacer"></div>
  </q-list>
</template>

<script setup lang="ts">
import icons from "@/config/icons";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useCurrentUserStore } from "@/stores/currentUser";
import { useInboxesStore } from "@/stores/inboxes";
import { useNotificationsStore } from "@/stores/notifications";
import { useUserSettingsStore } from "@/stores/userSettings";
import { whenever } from "@vueuse/core";
import { useSortable } from "@vueuse/integrations/useSortable";
import { storeToRefs } from "pinia";
import { nextTick, ref } from "vue";
import SidebarInboxGroup from "./SidebarInboxGroup.vue";
import SidebarMenuHeader from "./SidebarMenuHeader.vue";
import SidebarMenuItem from "./SidebarMenuItem.vue";

const { inboxes } = storeToRefs(useInboxesStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());
const { unreadCount } = storeToRefs(useNotificationsStore());

const inboxGroupsEl = ref<HTMLElement | null>(null);

const { updateFrontendPreferences } = useUserSettingsStore();

const { user: currentUser } = storeToRefs(useCurrentUserStore());

whenever(inboxGroupsEl, () => {
  if (!inboxes.value) {
    console.error("Inboxes not loaded - cannot initialize sortable");
    return;
  }
  useSortable(inboxGroupsEl, inboxes.value, {
    handle: ".sidebar-menu-item-drag-handle-container",
    animation: 150,
    onEnd: async () => {
      await nextTick();
      if (!inboxes.value) {
        console.error(
          "Inboxes not loaded - cannot save sidebarInboxOrder preference"
        );
        return;
      }

      updateFrontendPreferences({
        sidebarInboxOrder: inboxes.value.map((inbox) => inbox.id),
      });
    },
  });
});
</script>

<style lang="scss">
.sidebar-menu {
  padding-bottom: 10px;

  .q-item {
    border-radius: $generic-border-radius;
    padding-left: 6px;
    padding-right: 6px;
  }

  .sidebar-menu-item-drag-handle-container {
    width: 8px;
    flex-shrink: 0;
  }
}
.changelog-widget-spacer {
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
}
</style>
