<template>
  <gaeb-table-row
    :is-html="isHtml"
    :topline="$t('gaebViewer.performanceDescription', { id: element.number })"
    :text-short="element.textShort"
    :text-html="itemText"
    class="text-primary-7"
  />
</template>

<script setup lang="ts">
import type { GaebPerformanceDescription } from "@/types/gaeb";
import { computed } from "vue";
import GaebTableRow from "./GaebTableRow.vue";

const props = defineProps<{
  element: GaebPerformanceDescription;
  isHtml: boolean;
}>();

const itemText = computed(() => {
  if (props.isHtml) return props.element.textHtml.replace(/>\s+</g, "><");
  return props.element.textHtml;
});
</script>

<style scoped lang="scss"></style>
