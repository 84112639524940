<script setup lang="ts">
import ChangelogWidget from "@/components/ChangelogWidget.vue";
import { useConfirmUnsavedChanges } from "@/composables/useConfirmUnsavedChanges";
import { RouterView } from "vue-router";

useConfirmUnsavedChanges();
</script>

<template>
  <router-view />
  <changelog-widget />
</template>
