<template>
  <div class="row no-wrap items-center gap-x-lg gap-y-md">
    <svg :width="size" :height="size" :viewBox="viewBox">
      <circle
        v-for="(slice, index) in reverseSlices"
        :key="index"
        class="donut-segment"
        :r="radius"
        :cx="center"
        :cy="center"
        :stroke="slice.color"
        :stroke-dasharray="slice.strokeDasharray"
        :stroke-dashoffset="slice.strokeDashoffset"
        :stroke-width="
          hoveredIndex == reverseSlices.length - index - 1
            ? borderSize * 1.1
            : borderSize
        "
        :stroke-linecap="slice.value > 0 ? 'round' : 'butt'"
        fill="transparent"
        @mouseover="hoveredIndex = reverseSlices.length - index - 1"
        @mouseout="hoveredIndex = null"
      />
    </svg>
    <div class="column gap-y-xs no-wrap">
      <div
        v-for="(slice, index) in data"
        :key="index"
        class="row gap-x-sm items-center no-wrap"
        @mouseover="hoveredIndex = index"
        @mouseout="hoveredIndex = null"
      >
        <div
          :class="['donut-legend', { hovered: hoveredIndex == index }]"
          :style="{ 'background-color': slice.color, 'flex-shrink': 0 }"
        />
        <div>
          <span class="text-base">{{ slice.value }}</span> {{ " " }}
          <span class="text-xs text-deemphasized">{{ slice.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

const props = defineProps<{
  data: { value: number; color: string; label: string }[];
  size: number;
  borderSize: number;
}>();

const hoveredIndex = ref<number | null>(null);

const total = computed(() =>
  props.data.reduce((acc, slice) => acc + slice.value, 0)
);

const reverseSlices = computed(() => {
  const result = [...slices.value];
  result.reverse();
  return result;
});

const center = computed(() => props.size / 2);
const radius = computed(() => center.value - 10); // Adjust for padding around the donut chart
const circumference = computed(() => 2 * Math.PI * radius.value);
const viewBox = computed(() => `0 0 ${props.size} ${props.size}`);

const slices = computed(() =>
  props.data.map((slice, index) => {
    const buffer = 2;
    const value = Math.max(
      (slice.value / total.value) * circumference.value - buffer,
      0
    );
    const strokeDasharray = `${value} ${circumference.value - value}`;
    const strokeDashoffset = props.data
      .slice(0, index)
      .reduce(
        (acc, slice) => acc - (slice.value / total.value) * circumference.value,
        circumference.value / 4
      );
    return {
      ...slice,
      strokeDasharray,
      strokeDashoffset,
    };
  })
);
</script>

<style scoped lang="scss">
.donut-segment {
  transition: stroke-width 0.3s ease;
}

.donut-legend {
  width: 16px;
  height: 16px;
  border-radius: 50%;

  transition: transform 0.3s ease;

  &.hovered {
    transform: scale(1.1);
  }
}
</style>
