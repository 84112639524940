import type { Polygon } from "@/types/boundingRegion";
import { degToRad, distance2D, getRotation } from "./geometry";
import type { CanvasSize } from "../types";

export interface DivCoordinates {
  left: number;
  top: number;
  width: number;
  height: number;
  rotation: number;
}

/** Convert a Polygon into a div with appropriate rotation.
 *
 * NOTE: Polygon needs to be defined relative to page.
 */
export function polygonToDivCoordinates(
  polygon: Polygon,
  pageRotation: number,
  canvasSize: CanvasSize
): DivCoordinates {
  const [leftTop, rightTop] = polygon;

  const left = leftTop[0] * 100;
  const top = leftTop[1] * 100;

  const { width, height } = getSize(polygon, pageRotation, canvasSize);
  const rotation = getRotation(leftTop, rightTop);

  return { left, top, width, height, rotation };
}

export function getDivStyle(
  div: DivCoordinates,
  pageRotation: number,
  includeSize: boolean,
  increaseWidthByPx: number = 0
): string {
  const styles = [];
  const transformOrigin = getTransformOrigin(pageRotation);

  styles.push(
    `${transformOrigin[0]}: calc(${div.left}% - ${increaseWidthByPx}px);`
  );
  styles.push(`${transformOrigin[1]}: ${div.top}%;`);
  if (includeSize) {
    styles.push(`width: calc(${div.width}% + ${increaseWidthByPx * 2}px);`);
    styles.push(`height: ${div.height}%;`);
  }
  styles.push(`transform-origin: ${transformOrigin.join(" ")};`);
  styles.push(
    `transform: rotate(${
      degToRad(pageRotation) + div.rotation
    }rad) translate(${getTranslation(pageRotation)});`
  );

  return styles.join(" ");
}

function getTransformOrigin(pageRotation: number) {
  switch (pageRotation) {
    case 0:
      return ["left", "top"];
    case 90:
      return ["top", "right"];
    case 180:
      return ["right", "bottom"];
    case 270:
      return ["bottom", "left"];
  }

  throw Error(`Invalid page rotation: ${pageRotation}deg`);
}

function getTranslation(pageRotation: number) {
  switch (pageRotation) {
    case 0:
      return "0%, 0%";
    case 90:
      return "100%, 0%";
    case 180:
      return "100%, 100%";
    case 270:
      return "0%, 100%";
  }

  throw Error(`Invalid page rotation: ${pageRotation}deg`);
}

function getSize(
  polygon: Polygon,
  pageRotation: number,
  canvasSize: CanvasSize
) {
  const [leftTop, rightTop, , leftBottom] = polygon;

  let width = distance2D(leftTop, rightTop) * 100;
  let height = distance2D(leftTop, leftBottom) * 100;

  if ([90, 270].includes(pageRotation)) {
    // reference axes for width and height are switched
    width = (width * canvasSize.height) / canvasSize.width;
    height = (height * canvasSize.width) / canvasSize.height;
  }

  return { width, height };
}
