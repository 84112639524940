/** Common editor extensions for editing and rendering */
import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";

export const defaultExtensions = [
  StarterKit,
  Link.configure({
    openOnClick: true,
  }),
];
