<template>
  <gaeb-table-row
    :is-html="isHtml"
    :topline="$t('gaebViewer.markupItem')"
    :text-short="element.textShort"
    :text-html="elementText"
  />
</template>

<script setup lang="ts">
import type { GaebMarkupItem } from "@/types/gaeb";
import { computed } from "vue";
import GaebTableRow from "./GaebTableRow.vue";

const props = defineProps<{
  element: GaebMarkupItem;
  isHtml: boolean;
}>();

const elementText = computed(() => {
  if (props.isHtml) return props.element.textHtml.replace(/>\s+</g, "><");
  return props.element.textHtml;
});
</script>

<style scoped lang="scss"></style>
