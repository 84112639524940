<template>
  <div class="ocrTextLayer" @mouseup.stop.prevent="handleMouseUp">
    <div
      v-for="(word, index) in words"
      v-bind:key="index"
      :style="getStyle(ocrPage, word, props.canvasSize)"
      @mousedown.stop.prevent="(evt) => handleMouseDown(evt, index)"
      @mouseenter="handleMouseEnter(index)"
      :class="{
        selected: isSelected(pageNumber, index),
      }"
      data-testid="ocr-word"
    />
    <q-menu touch-position context-menu>
      <q-list dense style="min-width: 100px">
        <q-item
          clickable
          v-close-popup
          :disable="selectedText == null"
          @click="copyText"
        >
          <q-item-section data-testid="copy-context-menu">{{
            $t("Copy text")
          }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </div>
</template>

<script setup lang="ts">
import type { OCRPage, OCRWord } from "@/types/ocrResult";
import { computed, onMounted } from "vue";
import type { CanvasSize } from "./types";
import { useTextSelection } from "./utils/ocrTextSelection";
import { getDivStyle, polygonToDivCoordinates } from "./utils/polygonToDiv";

const props = defineProps<{
  ocrPage: OCRPage;
  canvasSize: CanvasSize;
  pageRotation: number;
}>();

const pageNumber = computed(() => props.ocrPage.pageNumber);
const words = computed<OCRWord[]>(() => props.ocrPage.words);

const {
  isSelected,
  startSelection,
  stopOrResetSelection,
  updateSelection,
  selectedText,
  copyText,
  registerKeypressHandler,
} = useTextSelection();

onMounted(() => {
  registerKeypressHandler();
});

function handleMouseDown(evt: MouseEvent, wordIndex: number) {
  if (evt.button === 0) {
    startSelection(pageNumber.value, wordIndex);
  }
}

function handleMouseUp(evt: MouseEvent) {
  if (evt.button === 0) {
    stopOrResetSelection();
  }
}

function handleMouseEnter(wordIndex: number) {
  updateSelection(pageNumber.value, wordIndex);
}

function getStyle(page: OCRPage, word: OCRWord, canvasSize: CanvasSize) {
  const divCoordinates = polygonToDivCoordinates(
    word.polygon,
    props.pageRotation,
    canvasSize
  );
  return getDivStyle(divCoordinates, props.pageRotation, true);
}
</script>

<style lang="scss">
.ocrTextLayer {
  font-family: Helvetica, sans-serif;
  position: absolute;
  text-align: initial;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 1;
  line-height: 1;
  z-index: 100; // put above the pdf canvas and pdfjs text layer
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;

  div {
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0% 0%;

    &.selected {
      background-color: rgba($primary, 0.2);
    }
  }
}
</style>
