import { differenceInBusinessDays, endOfDay } from "date-fns";

export function isClose(
  date: Date | null,
  showOverdueClose: boolean,
  warningDays: number
): boolean {
  if (!date || !showOverdueClose) return false;
  const submission = endOfDay(date);
  const now = new Date();
  return differenceInBusinessDays(submission, now) < warningDays;
}

export function isOverdue(
  date: Date | null,
  showOverdueClose: boolean
): boolean {
  if (!date || !showOverdueClose) return false;
  const submission = endOfDay(date);
  const now = new Date();
  return submission < now;
}
