import type { ProductGroup } from "@/types/productGroup";
import { client } from "@/api/client/client";

export async function listProductGroups(
  organizationId: number
): Promise<ProductGroup[]> {
  const response = await client.get<ProductGroup[]>(
    `/organizations/${organizationId}/product_groups/`
  );
  return response.data;
}

export async function createProductGroup(
  organizationId: number,
  name: string
): Promise<ProductGroup> {
  const response = await client.post<ProductGroup>(
    `/organizations/${organizationId}/product_groups/`,
    { name }
  );
  return response.data;
}

export async function updateProductGroup(
  organizationId: number,
  productGroupId: number,
  data: Partial<ProductGroup>
): Promise<ProductGroup> {
  const response = await client.patch<ProductGroup>(
    `/organizations/${organizationId}/product_groups/${productGroupId}/`,
    data
  );
  return response.data;
}

export async function deleteProductGroup(
  organizationId: number,
  productGroupId: number
): Promise<void> {
  await client.delete(
    `/organizations/${organizationId}/product_groups/${productGroupId}/`
  );
}
