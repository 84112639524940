import { computed, type Ref } from "vue";
import { useI18n } from "vue-i18n";

export function useInboxFormValidation(
  name: Ref<string | null>,
  shortCode?: Ref<string | null>
) {
  const { t } = useI18n();

  const validations = computed(() => ({
    name: !!name.value && name.value.length > 0,
    shortCode:
      shortCode === undefined ||
      (!!shortCode.value && shortCode.value.length <= 5),
  }));

  const rules = {
    name: (v: string) => !!v || t("Name is required"),
    shortCode: (v: string) => {
      if (!v) return t("Short code is required");
      if (!/^[a-zA-Z0-9]+$/.test(v))
        return t("Short code has to contain only letters or numbers");
      return true;
    },
  };

  const errorMessages = {
    name: t("Name is required"),
    shortCode: t("Short code has to be between 1 and 5 symbols"),
  };

  const canBeSubmitted = computed(() =>
    Object.values(validations.value).every((v) => v)
  );

  return {
    rules,
    canBeSubmitted,
    errorMessages,
    validations,
  };
}
