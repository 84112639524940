<template>
  <div v-if="inbox && inquiry">
    <email-display v-if="inquiry.email" :email="inquiry.email" :inbox="inbox" />
    <first-document-display v-else :inquiry="inquiry" :inbox="inbox" />
  </div>
  <div v-else class="q-pa-md">
    <q-skeleton style="height: 400px" />
  </div>
</template>

<script setup lang="ts">
import EmailDisplay from "@/components/EmailDisplay/EmailDisplay.vue";
import FirstDocumentDisplay from "./FirstDocumentDisplay.vue";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { inbox } = storeToRefs(useCurrentInboxStore());
</script>
