<template>
  <header-bar secondary>
    <div class="q-mr-sm">{{ inquiry?.shortCode }}</div>
    <status-select-button
      v-if="inquiry"
      class="q-mr-sm"
      icon-only
      :status="inquiry.status"
      :inquiry-id="inquiryId"
      @update:status="(status) => updateStatus(status || null)"
      @retry-analysis="retryAnalysis"
    />
    <user-select-button
      v-if="inquiry"
      class="q-mr-sm"
      icon-only
      :user="inquiry.assignedUser"
      @update:user="(user) => updateAssignedUser(user || null)"
    />
    <finalize-current-inquiry-button v-if="inquiry" class="q-mr-sm" />
    <export-current-inquiry-button
      v-if="inquiry && organization?.availableExportType"
      class="q-mr-sm"
    />
    <download-current-inquiry-button v-if="inquiry" class="q-mr-sm" />
    <CreateDraftQuotationButton
      v-if="inquiry && organization?.useQuotationCreation"
      class="q-mr-sm"
    />
    <q-btn dense flat color="neutral-7" class="q-mr-sm" v-if="inquiry">
      <q-icon name="sym_r_more_horiz" />
      <q-menu>
        <q-list separator dense>
          <!--  ADD this back if you know how to solve the bug!-->
          <!--            <move-inquiry-button-->
          <!--              :inquiry="inquiry"-->
          <!--              @move-inquiry="moveInquiry"-->
          <!--            />-->
          <q-item
            :clickable="!!canMoveToAnotherInbox"
            v-ripple
            :disable="!canMoveToAnotherInbox"
          >
            <q-item-section v-if="inquiry">
              <q-item-label>
                <q-icon
                  name="sym_r_trending_flat"
                  class="q-mr-sm"
                  size="24px"
                  color="neutral-7"
                />
                {{ $t("moveInquiryButton.label") }}
              </q-item-label>
            </q-item-section>
            <inbox-select-menu
              @select="confirmMove"
              v-if="canMoveToAnotherInbox"
            />
          </q-item>
          <q-item
            clickable
            v-ripple
            @click="copyInquiry"
            v-close-popup
            :disable="
              inquiry.status.originalStatus == 'ANALYZING' ||
              inquiry.status.originalStatus == 'ANALYSIS_ERROR'
            "
          >
            <q-item-section>
              <q-item-label>
                <q-icon
                  name="sym_r_file_copy"
                  class="q-mr-sm"
                  size="24px"
                  color="neutral-7"
                />
                {{ $t("copyInquiryButton.label") }}
              </q-item-label>
            </q-item-section>
          </q-item>

          <delete-inquiry-menu-entry
            :inquiry="inquiry"
            @delete-inquiry="deleteInquiry"
          />
        </q-list>
      </q-menu>
    </q-btn>
    <template #right>
      <slot name="right" />
    </template>
  </header-bar>
  <q-dialog v-model="isLoading" persistent>
    <q-spinner />
  </q-dialog>
</template>

<script setup lang="ts">
import * as inquiry_api from "@/api/inquiry";
import DeleteInquiryMenuEntry from "@/components/DeleteInquiryMenuEntry.vue";
import ExportCurrentInquiryButton from "@/components/ExportCurrentInquiryButton.vue";
import HeaderBar from "@/components/Header/HeaderBar.vue";
import StatusSelectButton from "@/components/Status/StatusSelectButton.vue";
import UserSelectButton from "@/components/User/UserSelectButton.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { NamedInbox } from "@/types/inbox";
import { CAN_MOVE_TO_OTHER_INBOX_INQUIRY_STATUSES } from "@/types/inquiry";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import DownloadCurrentInquiryButton from "./DownloadCurrentInquiryButton.vue";
import FinalizeCurrentInquiryButton from "./FinalizeCurrentInquiryButton.vue";
import CreateDraftQuotationButton from "./CreateDraftQuotationButton.vue";
import InboxSelectMenu from "./InboxSelectMenu.vue";
import { computed, ref } from "vue";
import { useCurrentOrganizationInboxNamesStore } from "@/stores/currentOrganizationInboxNames";

const { organizationId, inboxId, inquiryId } = useRouteParams();

const router = useRouter();

const { moveToAnotherInbox } = useCurrentInquiryStore();
const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { organization } = storeToRefs(useCurrentOrganizationStore());
const { updateStatus, updateAssignedUser, retryAnalysis } =
  useCurrentInquiryStore();

const isLoading = ref(false);

async function deleteInquiry() {
  if (inquiry.value) {
    isLoading.value = true;
    try {
      await inquiry_api.deleteInquiry(inboxId.value, inquiryId.value);
    } finally {
      isLoading.value = false;
    }
  }
  router.push({ name: "inbox" });
}

const q = useQuasar();
const { t } = useI18n();

const { inboxNames } = storeToRefs(useCurrentOrganizationInboxNamesStore());

const canMoveToAnotherInbox = computed(
  () =>
    inboxNames.value.length >= 2 &&
    inquiry.value &&
    CAN_MOVE_TO_OTHER_INBOX_INQUIRY_STATUSES.includes(
      inquiry.value.status.originalStatus
    )
);

function confirmMove(targetInbox: NamedInbox) {
  q.dialog({
    title: t("moveInquiryButton.confirmMove.title"),
    message: t("moveInquiryButton.confirmMove.message", {
      inquiryId: inquiry.value!.shortCode,
      targetInboxName: targetInbox.name,
    }),
    ok: {
      dense: true,
      label: t("moveInquiryButton.confirmMove.ok"),
    },
    cancel: {
      flat: true,
      dense: true,
    },
  }).onOk(() => {
    moveToAnotherInbox(targetInbox.id);
  });
}

async function copyInquiry() {
  if (!inquiry.value) return;

  let newInquiry;

  q.loading.show({
    message: t("copyInquiryButton.copyingInquiry"),
    boxClass: "bg-white text-neutral-10 shadow-2",
    spinnerColor: "primary",
  });
  try {
    newInquiry = await inquiry_api.copyInquiry(inboxId.value, inquiryId.value);
  } finally {
    q.loading.hide();
  }

  if (newInquiry) {
    router.push({
      name: "inquiry",
      params: {
        organizationId: organizationId.value,
        inboxId: inboxId.value,
        inquiryId: newInquiry.id,
      },
    });
  }
}
</script>

<style scoped lang="scss">
.header-menu-item {
  color: $neutral-7;
  font-weight: 600;
}
</style>
