<template>
  <q-item clickable v-ripple @click="confirmDelete">
    <q-item-section>
      <q-item-label>
        <q-icon
          name="sym_r_delete"
          class="q-mr-sm"
          size="24px"
          color="neutral-7"
        />
        {{ $t("deleteSingleInquiryMenuEntry.label") }}
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<script setup lang="ts">
import type { ShortInquiry } from "@/types/inquiry";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";

const q = useQuasar();
const { t } = useI18n();

const props = defineProps<{
  inquiry: ShortInquiry;
}>();

const emit = defineEmits<{
  "delete-inquiry": [];
}>();

function confirmDelete() {
  q.dialog({
    title: t("deleteSingleInquiryMenuEntry.confirmDelete.title"),
    message: t("deleteSingleInquiryMenuEntry.confirmDelete.message", {
      inquiryId: props.inquiry.shortCode,
    }),
    ok: {
      dense: true,
      label: t("Delete"),
      color: "negative",
    },
    cancel: {
      flat: true,
      dense: true,
    },
  }).onOk(() => {
    emit("delete-inquiry");
  });
}
</script>

<style lang="scss">
.btn--no-hover {
  .q-focus-helper {
    display: none;
  }
}
</style>
