<template>
  <div class="slim-page">
    <settings-page-header section="organization" page="usageDashboard" />
    <q-table
      flat
      dense
      class="usage-dashboard-table"
      table-header-class="bg-neutral-3"
      row-key="month"
      :loading="isLoading"
      :columns="columns"
      :rows="monthlyUsage"
      hide-pagination
      :rows-per-page-options="[12]"
    />
  </div>
</template>

<script setup lang="ts">
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import type { QTableProps } from "quasar";
import { useI18n } from "vue-i18n";
import { ref, onMounted } from "vue";
import type { Ref } from "vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { getMonthlyUsage } from "@/api/organization";
import type { MonthlyUsage } from "@/types/organization";

const isLoading = ref(false);
const monthlyUsage: Ref<MonthlyUsage[]> = ref([]);
const { t } = useI18n();

const { organizationId } = useRouteParams();

const columns: QTableProps["columns"] = [
  {
    name: "month",
    label: t("settings.organization.usageDashboard.month"),
    align: "left",
    field: "month",
    sortable: false,
    format: (val: string, row: MonthlyUsage) => {
      return t(`shortMonthsFrom1.${val}`) + " " + row.year;
    },
  },
  {
    name: "amount",
    label: t("settings.organization.usageDashboard.amountOfInquiries"),
    align: "right",
    field: "amount",
    sortable: false,
    style:
      "max-width: 400px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
  },
];

async function loadMonthlyData() {
  isLoading.value = true;
  try {
    monthlyUsage.value = await getMonthlyUsage(organizationId.value);
  } finally {
    isLoading.value = false;
  }
}
onMounted(loadMonthlyData);
</script>
