<template>
  <div class="flex row items-center no-wrap gap-x-md text-xs">
    <div class="flex row items-center gap-x-md">
      <span>{{ t("supplierRfqsPage.pagination.rowsPerPage") }}</span>
      <q-select
        :model-value="pageSize"
        @update:model-value="updatePageSize"
        :options="[10, 20, 50, 100]"
        dense
        borderless
        class="text-xs"
      />
    </div>
    <div v-if="count">{{ t("supplierRfqsPage.pagination.count", count) }}</div>
    <div>
      <q-btn
        flat
        dense
        icon="sym_r_chevron_left"
        @click="goToPreviousPage"
        :disable="!previousCursor"
      />
      <q-btn
        flat
        dense
        icon="sym_r_chevron_right"
        @click="goToNextPage"
        :disable="!nextCursor"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const currentSupplierRfqsStore = useCurrentSupplierRfqsStore();
const { goToNextPage, goToPreviousPage } = currentSupplierRfqsStore;
const { numResults, pageNumber, pageSize, nextCursor, previousCursor } =
  storeToRefs(currentSupplierRfqsStore);

const { t } = useI18n();

const count = computed(() => ({
  from: firstResultIndex.value,
  to: lastResultIndex.value,
  total: numResults.value,
}));

const firstResultIndex = computed(
  () => (pageNumber.value - 1) * pageSize.value + 1
);
const lastResultIndex = computed(() =>
  Math.min(pageNumber.value * pageSize.value, numResults.value ?? Infinity)
);

const updatePageSize = (newPageSize: number) => {
  currentSupplierRfqsStore.setPageSize(newPageSize);
};
</script>
