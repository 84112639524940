export const VIEWPORT_RATIO = 0.95;

export function getFullWidthScale(
  viewport: { width: number; height: number },
  el: HTMLElement,
  rotation: number
) {
  if (!viewport.width) return 0;

  // use page height for width if it is rotated "sideways"
  const pageDisplayWidth = [90, 270].includes(rotation)
    ? viewport.height
    : viewport.width;

  return (el.clientWidth * VIEWPORT_RATIO) / pageDisplayWidth;
}

export function getFullHeightScale(
  viewport: { width: number; height: number },
  el: HTMLElement,
  rotation: number
) {
  if (!viewport.height) return 0;

  // use page width for height if it is rotated "sideways"
  const pageDisplayHeight = [90, 270].includes(rotation)
    ? viewport.width
    : viewport.height;

  return (el.clientHeight * VIEWPORT_RATIO) / pageDisplayHeight;
}

export function getFullPageScale(
  viewport: { width: number; height: number },
  el: HTMLElement,
  rotation: number
) {
  return Math.min(
    getFullWidthScale(viewport, el, rotation),
    getFullHeightScale(viewport, el, rotation)
  );
}
