export default {
  ANALYZING: "Analyse läuft",
  ANALYSIS_ERROR: "Analyse fehlgeschlagen",
  REVIEWING: "Zu prüfen",
  FINALIZED: "Abgeschlossen",
  CLOSED: "Archiviert",
  ARCHIVED: "Archiviert",
  ERROR: "Fehler",
  EXPORTING: "Exportieren",
  EXPORT_ERROR: "Export fehlgeschlagen",
};
