<template>
  <router-link
    :to="{
      name: 'supplier-rfqs.current-supplier-rfq',
      params: { supplierRfqId: supplierRfq.id },
      query: currentRoute.query,
    }"
    class="no-decoration router-link"
  >
    <div
      class="row no-wrap items-center gap-md q-px-md q-py-sm supplier-rfq-item"
      :class="{ 'bg-neutral-2': supplierRfq.id === currentSupplierRfqId }"
    >
      <supplier-rfq-status-icon
        :status="supplierRfq.status"
        @markAsAnswered="updateSupplierRfq({ status: 'ANSWERED' })"
        @markAsUnanswered="updateSupplierRfq({ status: 'SENT' })"
      />
      <div class="supplier-rfq-item__short-code ellipsis">
        <router-link
          :to="{
            name: 'inquiry-positions',
            params: {
              inboxId: supplierRfq.inquiry.inboxId,
              inquiryId: supplierRfq.inquiry.id,
              inboxView: 'all',
            },
          }"
          class="text-neutral-7"
          >{{ supplierRfq.inquiry.shortCode }}</router-link
        >
      </div>
      <div class="supplier-rfq-item__subject ellipsis">
        {{ supplierRfq.subject }}
      </div>
      <div
        class="supplier-rfq-item__supplier ellipsis row no-wrap items-center gap-sm"
      >
        <q-icon :name="icons.supplier" size="xs" color="neutral-5" />
        <div class="ellipsis">{{ supplierRfq.supplier.name }}</div>
      </div>
      <supplier-rfq-dates-display
        :supplier-rfq="supplierRfq"
        class="supplier-rfq-item__dates"
      />
      <user-select-button
        icon-only
        :user="supplierRfq.assignedUser"
        class="supplier-rfq-item__user"
        @update:user="
          (user) =>
            updateSupplierRfq({
              assignedUser: user?.id ? user : null,
            })
        "
        @click.left.stop.prevent
        :store="orgUsersStore"
      />
    </div>
    <q-menu context-menu>
      <q-item dense clickable v-close-popup @click="onDelete">
        <q-item-section>
          <div class="row items-center gap-sm no-wrap">
            <q-icon :name="icons.delete" color="neutral-5" size="20px" />
            {{ $t("supplierRfqsPage.deleteSupplierRfq") }}
          </div>
        </q-item-section>
      </q-item>
    </q-menu>
  </router-link>
</template>

<script setup lang="ts">
import UserSelectButton from "@/components/User/UserSelectButton.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import icons from "@/config/icons";
import { useCurrentOrganizationUsersStore } from "@/stores/currentOrganizationUsers";
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import type { SupplierRFQ } from "@/types/supplierRfq";
import { useRouter } from "vue-router";
import SupplierRfqStatusIcon from "../SupplierRfqStatusIcon.vue";
import SupplierRfqDatesDisplay from "./SupplierRfqDatesDisplay.vue";

const props = defineProps<{
  supplierRfq: SupplierRFQ;
}>();

const store = useCurrentSupplierRfqsStore();

const orgUsersStore = useCurrentOrganizationUsersStore();

const { supplierRfqId: currentSupplierRfqId } = useRouteParams();

const router = useRouter();
const { currentRoute } = router;

async function onDelete() {
  await store.deleteSupplierRfq(props.supplierRfq.id);
  if (
    currentRoute.value.params.supplierRfqId === props.supplierRfq.id.toString()
  ) {
    router.push({
      name: "supplier-rfqs",
      params: { supplierRfqId: null },
      query: currentRoute.value.query,
    });
  }
}

async function updateSupplierRfq(data: Partial<SupplierRFQ>) {
  await store.updateSupplierRfq(props.supplierRfq.id, data);
}
</script>

<style scoped lang="scss">
.supplier-rfq-item {
  cursor: pointer;

  &:hover {
    background-color: $neutral-2;
  }

  &__short-code {
    min-width: 62px;
    color: $neutral-7;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__subject {
    flex-grow: 1;
    min-width: 0;
  }

  &__supplier {
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__dates {
    width: 100px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.router-link {
  color: inherit;
}
</style>
