import router from "@/router";
import { useCurrentUserStore } from "@/stores/currentUser";
import axios from "axios";
import { showHttpErrorNotification } from "./client/client";
import { CSRF_HEADER_NAME, getCsrfToken } from "./client/csrf";

/** Login to the application.
 *
 * Returns true if login was successful and false if it was not successful
 *
 * NOTE: We use axios directly instead of the default HTTP client from client.ts since the default
 * client intercepts HTTP non-authorized errors
 *
 */
export async function login(email: string, password: string): Promise<boolean> {
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
  try {
    await axios.post<{}>("/api/user/login/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        [CSRF_HEADER_NAME]: getCsrfToken(),
      },
    });

    const userStore = useCurrentUserStore();
    await userStore.loadUser();

    return true;
  } catch (e: any) {
    if ([401, 403].includes(e.response?.status)) return false;
    showHttpErrorNotification(e);
    throw e;
  }
}

export async function resetPassword(email: string) {
  await axios.post("/api/user/password_reset/", { email });
}

export async function confirmResetPassword(
  uidb64: string,
  token: string,
  password: string
) {
  await axios.post("/api/user/password_reset_confirm/", {
    uidb64,
    token,
    new_password: password,
  });
}

export async function logout() {
  await axios.post("/api/user/logout/", undefined, {
    headers: { [CSRF_HEADER_NAME]: getCsrfToken() },
  });

  const userStore = useCurrentUserStore();
  userStore.clear();

  await router.push({ name: "login" });
}
