<template>
  <div
    v-if="isSelected || isHovered"
    :style="highlightStyle"
    :class="elClass"
    ref="htmlElementRef"
    @click="$emit('click')"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type { Polygon } from "@/types/boundingRegion";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { computed, ref } from "vue";
import type { CanvasSize } from "../PdfViewer/types";
import {
  getDivStyle,
  polygonToDivCoordinates,
} from "../PdfViewer/utils/polygonToDiv";

const props = defineProps<{
  boundingRegion: Polygon;
  pageRotation: number;
  canvasSize: CanvasSize;
  group: OfferPositionGroup;
  isSelected: boolean;
}>();

const isHovered = ref(false);

defineEmits(["click", "mouseenter", "mouseleave", "focus"]);

const htmlElementRef = ref<HTMLDivElement | undefined>(undefined);

const highlightStyle = computed(() => {
  const divCoordinates = polygonToDivCoordinates(
    props.boundingRegion,
    props.pageRotation,
    props.canvasSize
  );
  return getDivStyle(divCoordinates, props.pageRotation, true, 2);
});

const elClass = computed(() => {
  return {
    "aid-marker": true,
    offered: props.group.isOffered,
    completed: props.group.isCompleted,
    hovered: isHovered,
    selected: props.isSelected,
  };
});

const scrollIntoView = () => {
  if (htmlElementRef.value) {
    htmlElementRef.value.scrollIntoView({
      behavior: "instant",
      block: "center",
      inline: "center",
    });
  }
};

defineExpose({ scrollIntoView });
</script>

<style lang="scss">
.aid-marker {
  border: 2px solid $primary-4;
  scroll-margin-top: 240px;
  position: absolute;
  pointer-events: auto;
  cursor: pointer;
}
</style>
