import type { BaseInquiryStatus } from "@/types/inquiry";

export function getStatusTextColor(status: BaseInquiryStatus) {
  switch (status) {
    case "ANALYSIS_ERROR":
      return "dark";
    case "EXPORTING":
      return "dark";
    case "EXPORT_ERROR":
      return "dark";
    case "ANALYZING":
      return "white";
    case "ARCHIVED":
      return "white";
    case "FINALIZED":
      return "white";
    case "REVIEWING":
      return "white";
  }
  return "white";
}

export function getStatusIcon(status: BaseInquiryStatus) {
  switch (status) {
    case "ANALYSIS_ERROR":
      return "sym_r_error";
    case "EXPORTING":
      return "sym_r_hourglass_empty";
    case "EXPORT_ERROR":
      return "sym_r_error";
    case "ANALYZING":
      return "sym_r_hourglass_empty";
    case "ARCHIVED":
      return "sym_r_archive";
    case "FINALIZED":
      return "sym_r_check_circle";
    case "REVIEWING":
      return "sym_r_assignment";
  }
  return "help";
}
