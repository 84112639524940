export default {
  "Full-text search": "Volltextsuche",
  "File search": "Dateisuche",
  "Status selected": "Status: {n} ausgewählt",
  User: "Benutzer",
  "Drop files here or click to select":
    "Dateien hier ablegen oder klicken zum Auswählen",
  "Only documents with the following statuses can be exported":
    "Nur Dokumente mit folgenden Status können exportiert werden",
  "Only documents with the following statuses can be archived":
    "Nur Dokumente mit folgenden Status können archiviert werden",
  "Delete documents": "Dokumente löschen",
  "Are you sure you want to delete the selected documents?":
    "Sind Sie sicher, dass Sie die ausgewählten Dokumente löschen möchten?",
  Assign: "Bearbeiter zuweisen",
  Unassign: "Zuweisung entfernen",
  "Has warnings": "Hat Warnungen",
  "No warnings": "Keine Warnungen",
  Any: "Alle",
  "Delete leads": "Anfragen löschen",
  "Are you sure you want to delete the selected leads?":
    "Sind Sie sicher, dass Sie die ausgewählten Anfragen löschen möchten?",
  "value missing": "Wert fehlt",
  "Change status": "Status ändern",
};
