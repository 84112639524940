import type { Inbox, NewInbox, ShortInbox, NamedInbox } from "@/types/inbox";
import { client, clientWithoutErrorNotifications } from "./client/client";

export async function listInboxes(organizationId: number) {
  const result = await client.get<ShortInbox[]>(
    `/organizations/${organizationId}/inboxes/`
  );
  return result.data;
}

export async function listInboxNames(organizationId: number) {
  const result = await client.get<NamedInbox[]>(
    `/organizations/${organizationId}/list_inboxes/`
  );
  return result.data;
}

export async function getInbox(
  organizationId: number,
  inboxId: number
): Promise<Inbox> {
  const result = await client.get<Inbox>(
    `/organizations/${organizationId}/inboxes/${inboxId}/`
  );
  return result.data;
}

export async function updateInbox(
  organizationId: number,
  inboxId: number,
  data: any // TODO: type this
): Promise<Inbox> {
  const result = await clientWithoutErrorNotifications.patch<Inbox>(
    `/organizations/${organizationId}/inboxes/${inboxId}/`,
    data
  );
  return result.data;
}

export async function deleteInbox(organizationId: number, inboxId: number) {
  await client.delete(`/organizations/${organizationId}/inboxes/${inboxId}/`);
}

export async function createInbox(
  organizationId: number,
  newInbox: NewInbox
): Promise<Inbox> {
  const result = await client.post<Inbox>(
    `/organizations/${organizationId}/inboxes/`,
    newInbox
  );
  return result.data;
}
