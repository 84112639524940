<template>
  <div class="slim-page">
    <settings-page-header section="account" page="access" />
    <q-form @submit="updatePassword" class="q-gutter-y-sm q-pa-md" v-if="user">
      <set-password-form
        :user-inputs="[user.firstName, user.lastName, user.email]"
        autofocus
        @update:password="password = $event"
        @update:error-status="passwordError = $event"
      />
      <div class="row justify-end">
        <q-btn
          dense
          type="submit"
          color="primary"
          :label="$t('settings.account.access.changePassword')"
          :disable="!user || !password || passwordError"
          :loading="isUpdatingPassword"
        />
      </div>
    </q-form>
    <div v-else>
      <q-skeleton type="QInput" />
      <q-skeleton type="QInput" />
    </div>
  </div>
</template>

<script setup lang="ts">
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useCurrentUserStore } from "@/stores/currentUser";
import { useQuasar } from "quasar";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import SetPasswordForm from "@/components/SetPasswordForm.vue";

const userStore = useCurrentUserStore();
if (!userStore.user) {
  userStore.loadUser();
}

const user = ref(userStore.user);
const password = ref("");
const passwordError = ref(false);

const isUpdatingPassword = ref(false);

const q = useQuasar();
const { t } = useI18n();

watch(
  () => userStore.user,
  (newUser) => {
    user.value = newUser;
  }
);

async function updatePassword() {
  isUpdatingPassword.value = true;
  try {
    await userStore.updatePassword(password.value);
    q.notify({
      type: "positive",
      message: t("settings.account.access.passwordUpdated"),
    });
    password.value = "";
  } finally {
    isUpdatingPassword.value = false;
  }
}
</script>
