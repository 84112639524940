export default {
  Allocation: "Zuweisung",
  Split: "Aufteilen",
  "Keep in this request": "In dieser Anfrage behalten",
  "Keep in both requests": "In beiden Anfragen behalten",
  "Move to new request": "In neue Anfrage verschieben",
  "No files remain in this request":
    "Keine Dateien verbleiben in dieser Anfrage",
  "No files are moved to the new request":
    "Keine Dateien werden in die neue Anfrage verschoben",
  "{count} files remain in this request":
    "{count} Dateien verbleiben in dieser Anfrage",
  "{count} files are moved to the new request":
    "{count} Dateien werden in die neue Anfrage verschoben",
  "Split request": "Anfrage aufteilen",
  "leadSplitter.leadSplitSuccessfully":
    "Neue Anfrage mit {total} Objekten erstellt.",
  "Open new lead": "Neue Anfrage öffnen",
};
