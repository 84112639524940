<template>
  <q-page class="fit" :style-fn="() => ''">
    <header-bar>
      <div class="row no-wrap items-center q-ml-sm gap-sm">
        <sort-menu-button
          dense
          size="sm"
          :sort-by="sortBy"
          :descending="descending"
          @update-sort="store.updateSort"
        />
        <quotations-filter-menu-button dense size="sm" />
        <quotations-filter-chips />
      </div>
    </header-bar>
    <quotations-table class="page-below-header-bar" />
  </q-page>
</template>

<script setup lang="ts">
import HeaderBar from "@/components/Header/HeaderBar.vue";
import QuotationsFilterChips from "@/components/Quotations/QuotationsFilterChips.vue";
import QuotationsFilterMenuButton from "@/components/Quotations/QuotationsFilterMenuButton.vue";
import QuotationsTable from "@/components/Quotations/QuotationsTable.vue";
import SortMenuButton from "@/components/Quotations/SortMenuButton.vue";
import { useCurrentQuotationsStore } from "@/stores/currentQuotations";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

const store = useCurrentQuotationsStore();
const { sortBy, descending } = storeToRefs(store);

onMounted(() => {
  store.reloadQuotations();
});
</script>
