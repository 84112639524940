import type { InboxView } from "@/types/inbox";
import { computed, type ComputedRef } from "vue";
import { useRoute } from "vue-router";

export function useRouteParams() {
  const route = useRoute();

  const organizationId = computed(() => +route.params.organizationId);
  const inboxId = computed(() => +route.params.inboxId);
  const inboxView = computed(() => route.params.inboxView);
  const inquiryId = computed(() => +route.params.inquiryId);
  const notificationId = computed(() => +route.params.notificationId);
  const supplierRfqId = computed(() => +route.params.supplierRfqId);

  return {
    organizationId,
    inboxId,
    inboxView,
    inquiryId,
    notificationId,
    supplierRfqId,
  } as {
    organizationId: ComputedRef<number>;
    inboxId: ComputedRef<number>;
    inboxView: ComputedRef<InboxView>;
    inquiryId: ComputedRef<number>;
    notificationId: ComputedRef<number>;
    supplierRfqId: ComputedRef<number>;
  };
}
