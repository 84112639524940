<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-form @submit="onSubmit">
        <q-card-section>
          <div class="q-mb-md">
            {{
              $t(
                "Please enter the email addresses of users to invite, separated by newline."
              )
            }}
          </div>
          <q-input
            dense
            outlined
            type="textarea"
            :disable="isLoading"
            v-model="emailsString"
            lazy-rules
            :label="$t('Emails')"
            :placeholder="'email1@company.com\nemail2@othercompany.com\n...'"
            :rules="[
              (val) =>
                (val && val.length > 0) || $t('This field must not be empty.'),
            ]"
          />
        </q-card-section>
        <q-card-section>
          <inbox-select
            outlined
            v-if="inboxes"
            :disable="isLoading"
            v-model="inboxesToInvite"
            :inboxes="inboxes"
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            dense
            flat
            :disable="isLoading"
            :label="$t('Cancel')"
            @click="onDialogCancel"
          />
          <q-btn
            dense
            color="primary"
            :disable="isLoading"
            :label="$t('Invite')"
            type="submit"
          />
        </q-card-actions>
      </q-form>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { inviteUser } from "@/api/user";
import InboxSelect from "./InboxSelect.vue";
import type { ShortInbox } from "@/types/inbox";
import { useDialogPluginComponent, useQuasar } from "quasar";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const q = useQuasar();
const { t } = useI18n();

const props = defineProps<{ organizationId: number; inboxes: ShortInbox[] }>();

interface LabelNumberValue {
  label: string;
  value: number;
}

const inboxesToInvite = ref<LabelNumberValue[]>([]);
const emailsString = ref<string | undefined>();
const isLoading = ref(false);

defineEmits(useDialogPluginComponent.emits);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

async function onSubmit() {
  isLoading.value = true;
  const emails =
    emailsString.value?.split("\n").filter((email: string) => email.length) ||
    [];
  try {
    await Promise.all(
      emails.map((email) =>
        inviteUser(
          props.organizationId,
          email,
          inboxesToInvite.value.map((i) => i.value)
        )
      )
    );
  } catch (e) {
    q.notify({
      type: "negative",
      message: t("Failed to invite users."),
    });
  } finally {
    isLoading.value = false;
  }

  onDialogOK();
}
</script>
