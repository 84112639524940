<template>
  <q-input
    outlined
    bg-color="white"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event!.toString())"
    type="email"
    lazy-rules
    :rules="[isNotEmpty, isEmail]"
    :label="$t('Email')"
  >
    <template v-slot:prepend>
      <q-icon name="sym_r_email" />
    </template>
  </q-input>
</template>

<script setup lang="ts">
import { useFormValidationRules } from "@/composables/formValidation/useFormValidationRules";

defineProps<{
  modelValue: string;
}>();

defineEmits<{
  "update:modelValue": [value: string];
}>();

const { isNotEmpty, isEmail } = useFormValidationRules();
</script>
