<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-form @submit="onSubmit">
        <q-card-section>
          <q-input
            dense
            outlined
            type="text"
            :disable="isLoading"
            v-model="name"
            :label="$t('Name')"
            :rules="[
              (val) =>
                !groups.map((g) => g.name).includes(val) ||
                $t('settings.organization.productGroups.nameUnique'),
            ]"
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            dense
            flat
            :disable="isLoading"
            :label="$t('Cancel')"
            @click="onDialogCancel"
          />
          <q-btn
            dense
            color="primary"
            :disable="isLoading"
            :label="$t('Create')"
            type="submit"
          />
        </q-card-actions>
      </q-form>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import * as api from "@/api/productGroup";
import { useDialogPluginComponent, useQuasar } from "quasar";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import type { ProductGroup } from "@/types/productGroup";

const q = useQuasar();
const { t } = useI18n();

const props = defineProps<{ organizationId: number; groups: ProductGroup[] }>();

const name = ref<string>("");
const isLoading = ref(false);

defineEmits(useDialogPluginComponent.emits);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

async function onSubmit() {
  isLoading.value = true;
  try {
    await api.createProductGroup(props.organizationId, name.value);
  } catch (e) {
    q.notify({
      type: "negative",
      message: t("settings.organization.productGroups.creationError"),
    });
  } finally {
    isLoading.value = false;

    onDialogOK();
  }
}
</script>
