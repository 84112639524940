<template>
  <q-select
    dense
    outlined
    :readonly="readonly"
    :model-value="displayDatesOption"
    :options="DISPLAY_DATES_OPTIONS"
    :label="$t('settings.inbox.general.displayDates.label')"
    @update:model-value="({ value }) => $emit('update:displayDates', value)"
  >
  </q-select>
</template>

<script setup lang="ts">
import type { DisplayDates } from "@/types/inbox";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  displayDates: DisplayDates;
  readonly: boolean;
}>();

const displayDatesOption = computed(() => ({
  label: t(`settings.inbox.general.displayDates.${props.displayDates}`),
  value: props.displayDates,
}));

defineEmits<{
  "update:displayDates": [value: DisplayDates];
}>();

const { t } = useI18n();

const DISPLAY_DATES_OPTIONS = [
  "SUBMISSION_CREATED",
  "CUSTOMER_SUBMISSION_SUBMISSION",
  "CUSTOMER_SUBMISSION_CREATED",
].map((value) => ({
  label: t(`settings.inbox.general.displayDates.${value}`),
  value,
}));
</script>
