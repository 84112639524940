import { ref, watch, type Ref } from "vue";

export function useKeyboardSelection<T>(options: Ref<T[]>) {
  const selectedIndex = ref<number | null>(null);

  watch(options, () => {
    selectedIndex.value = null;
  });

  function selectNext() {
    if (selectedIndex.value === null) {
      selectedIndex.value = 0;
    } else {
      selectedIndex.value = (selectedIndex.value + 1) % options.value.length;
    }
  }

  function selectPrevious() {
    if (selectedIndex.value === null) {
      selectedIndex.value = options.value.length - 1;
    } else {
      selectedIndex.value =
        (selectedIndex.value - 1 + options.value.length) % options.value.length;
    }
  }

  return {
    selectedIndex,
    selectNext,
    selectPrevious,
  };
}
