<template>
  <div class="overflow-hidden">
    <div class="full-width ellipsis">
      {{ address.shortDisplayAddress }}
      <q-tooltip v-if="!showEmail">
        {{ address.displayAddress }}
      </q-tooltip>
    </div>
    <div v-if="showEmail" class="text-deemphasized flex row no-wrap text-xs">
      <div class="ellipsis">{{ emailName }}</div>
      <div>@</div>
      <div class="text-no-wrap">{{ emailDomain }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { EmailAddress } from "@/types/email";
import { computed } from "vue";

const props = defineProps<{
  address: EmailAddress;
  showEmail?: boolean;
}>();

const emailName = computed(() => {
  return props.address.email.split("@")[0];
});

const emailDomain = computed(() => {
  return props.address.email.split("@")[1];
});
</script>
