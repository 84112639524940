import Cookies from "js-cookie";
import axios from "axios";

export const CSRF_COOKIE_NAME = "csrftoken";
export const CSRF_HEADER_NAME = "X-CSRF-Token";

export function getCsrfToken() {
  return Cookies.get(CSRF_COOKIE_NAME);
}

export async function getCsrfCookie() {
  await axios.get("/api/user/login/");
}
