<template>
  <component
    :is="componentMap[element.type]"
    :element="element"
    :is-html="isHtml"
  />
</template>

<script setup lang="ts">
import type { GaebElement, GaebItemType } from "@/types/gaeb";
import type { Component } from "vue";
import GaebElementMarkupItem from "./GaebElementMarkupItem.vue";
import GaebElementPerformanceDescription from "./GaebElementPerformanceDescription.vue";
import GaebElementPosition from "./GaebElementPosition.vue";
import GaebElementRemark from "./GaebElementRemark.vue";
import GaebSection from "@/components/GaebViewer/GaebSection.vue";

defineProps<{
  element: GaebElement;
  isHtml: boolean;
}>();

const componentMap: Record<GaebItemType, Component> = {
  position: GaebElementPosition,
  remark: GaebElementRemark,
  markup_item: GaebElementMarkupItem,
  performance_description: GaebElementPerformanceDescription,
  section: GaebSection,
};
</script>
