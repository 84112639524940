<template>
  <div :class="{ 'supplier-rfq-select-input': true, disabled, isActive }">
    <div class="count-indicator">
      {{ group.supplierRfqsNotDraft.length }}
      <q-icon :name="icons.supplierRfq" size="16px" color="neutral-7">
        <template v-if="group.supplierRfqsNotDraft.length > 0">
          <div class="badge" />
          <q-tooltip>
            <div>
              {{
                $t(
                  "inquiryPositionsPage.offerPositionGroup.existingSupplierRfqs"
                )
              }}
            </div>
            <ul class="q-pl-md">
              <li
                v-for="supplier in group.supplierRfqsNotDraft"
                :key="supplier.supplier.id"
              >
                {{ supplier.supplier.name }}
              </li>
            </ul>
          </q-tooltip>
        </template>
      </q-icon>
    </div>
    <div class="main-container">
      <supplier-chip
        v-for="supplier in group.supplierRfqsDraft"
        :key="supplier.supplier.id"
        :supplier="supplier.supplier"
      />
    </div>
    <SupplierSelectMenu />
  </div>
</template>

<script setup lang="ts">
import SupplierChip from "@/components/InquiryPositionsPage/SupplierChip.vue";
import icons from "@/config/icons";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { ref } from "vue";
import SupplierSelectMenu from "./SupplierSelectMenu.vue";

defineProps<{
  group: OfferPositionGroup;
  disabled: boolean;
}>();

const isActive = ref(false);
</script>

<style scoped lang="scss">
.badge {
  background-color: $positive;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  right: 0;
}

.supplier-rfq-select-input {
  cursor: pointer;
  background-color: white;
  border: 1px solid $neutral-5;
  height: 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  .count-indicator {
    padding: 2px 6px;
    border-right: 1px solid $neutral-5;
    color: $neutral-8;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .main-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    padding: 1px 2px;
  }

  &.disabled {
    pointer-events: none;
    background-color: $neutral-1;
    border-color: $neutral-4;

    .count-indicator {
      border-color: $neutral-4;
      color: $neutral-4;
    }
  }

  &:hover {
    border-color: $neutral-9;
  }

  &.isActive {
    border-color: $neutral-9;
    border-width: 2px;
    outline: none;
  }
}

/* Chip enter animation */
.chip-enter-active,
.chip-leave-active {
  transition: all 0.15s ease;
}
.chip-enter-from,
.chip-leave-to {
  opacity: 0;
  transform: scale(0.5);
}
.chip-enter-to,
.chip-leave-from {
  opacity: 1;
  transform: scale(1);
}
</style>
