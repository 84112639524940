import type { QTableProps } from "quasar";
import { onMounted, ref, watch, type UnwrapRef } from "vue";
import { useRouteParams } from "./useRouteParams";

type ApiListFn<T> = (
  organizationId: number,
  searchTerm: string | null,
  cursor: string | null,
  pageSize: number
) => Promise<{
  next: string | null;
  previous: string | null;
  count: number;
  results: UnwrapRef<T[]>;
}>;

export function useMasterDataPage<T>(apiListFn: ApiListFn<T>) {
  const searchTerm = ref<string | null>(null);
  const isLoading = ref(false);
  const data = ref<T[]>([]);
  const page = ref(1);
  const pageSize = ref(50);
  const nextCursor = ref<string | null>(null);
  const previousCursor = ref<string | null>(null);
  const total = ref(0);

  const { organizationId } = useRouteParams();

  watch(searchTerm, () => {
    resetPagination();
    loadData();
  });

  function nextPage() {
    if (nextCursor.value) {
      page.value++;
      loadData(nextCursor.value);
    }
  }

  function previousPage() {
    if (previousCursor.value) {
      page.value--;
      loadData(previousCursor.value);
    }
  }

  function resetPagination() {
    nextCursor.value = null;
    previousCursor.value = null;
    page.value = 1;
  }

  function handleTableRequest(request: {
    pagination: QTableProps["pagination"];
  }) {
    const { pagination } = request;
    if (pagination === undefined) return;

    if (
      pagination.rowsPerPage !== undefined &&
      pagination.rowsPerPage !== pageSize.value
    ) {
      handlePageSizeUpdate(pagination.rowsPerPage);
      return;
    }

    if (pagination.page !== undefined) handlePageUpdate(pagination.page);
  }

  function handlePageSizeUpdate(newPageSize: number) {
    pageSize.value = newPageSize;
    resetPagination();
    loadData();
  }

  function handlePageUpdate(newPage: number) {
    if (newPage > page.value && nextCursor.value) {
      page.value++;
      loadData(nextCursor.value);
    } else if (newPage < page.value && previousCursor.value) {
      page.value--;
      loadData(previousCursor.value);
    }
  }

  async function loadData(cursor: string | null = null) {
    isLoading.value = true;

    try {
      const result = await apiListFn(
        organizationId.value,
        searchTerm.value,
        cursor,
        pageSize.value
      );
      nextCursor.value = result.next;
      previousCursor.value = result.previous;
      total.value = result.count;
      data.value = result.results;
    } finally {
      isLoading.value = false;
    }
  }
  onMounted(loadData);

  return {
    isLoading,
    data,
    pageSize,
    page,
    total,
    searchTerm,
    nextPage,
    previousPage,
    handleTableRequest,
  };
}
