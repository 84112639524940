<template>
  <div class="slim-page">
    <settings-page-header section="organization" page="positionDetection" />
    <q-form @submit="saveKeywords" class="q-pa-md">
      <div>
        <q-input
          v-if="!isLoading"
          dense
          outlined
          v-model="positiveKeywordsText"
          :readonly="!canWrite"
          :label="
            $t('settings.organization.positionDetection.positiveKeywords')
          "
          type="textarea"
        />
        <q-skeleton type="QInput" style="height: 129px" v-else />
      </div>
      <div class="q-mt-md">
        <q-input
          v-if="!isLoading"
          dense
          outlined
          :readonly="!canWrite"
          v-model="negativeKeywordsText"
          :label="
            $t('settings.organization.positionDetection.negativeKeywords')
          "
          type="textarea"
        />
        <q-skeleton type="QInput" style="height: 129px" v-else />
      </div>
      <q-btn
        v-if="canWrite"
        dense
        class="q-mt-md float-right"
        type="submit"
        color="primary"
        :label="$t('settings.organization.positionDetection.save')"
        :disable="isLoading"
        :loading="isLoading"
      />
    </q-form>
  </div>
</template>

<script setup lang="ts">
import * as api from "@/api/organization";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const positiveKeywordsText = ref<string>("");
const negativeKeywordsText = ref<string>("");
const isLoading = ref(true);

const { organizationId } = useRouteParams();

const { hasCurrentOrganizationRole } = storeToRefs(
  useCurrentPermissionsStore()
);
const canWrite = computed(
  () => hasCurrentOrganizationRole.value["product_admin"]
);

const q = useQuasar();
const { t } = useI18n();

async function loadKeywords() {
  isLoading.value = true;
  try {
    const keywords = await api.getKeywords(organizationId.value);
    positiveKeywordsText.value = keywords.positive.join("\n");
    negativeKeywordsText.value = keywords.negative.join("\n");
  } finally {
    isLoading.value = false;
  }
}
onMounted(loadKeywords);

async function saveKeywords() {
  isLoading.value = true;
  try {
    await api.setKeywords(organizationId.value, {
      positive: positiveKeywordsText.value
        .split("\n")
        .map((x) => x.trim())
        .filter((x) => x),
      negative: negativeKeywordsText.value
        .split("\n")
        .map((x) => x.trim())
        .filter((x) => x),
    });
    q.notify({
      message: t("settings.organization.positionDetection.notifySaved"),
      color: "positive",
    });
  } finally {
    isLoading.value = false;
  }
}
</script>
