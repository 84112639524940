export default {
  "Document received": "Dokument empfangen",
  "Unknown user": "Unbekannter Benutzer",
  "Unknown field": "Unbekanntes Feld",
  "Unknown table": "Unbekannte Tabelle",
  documentTimelineValueChange:
    "{user} hat den Wert des Feldes {field} von {fromValue} auf {toValue} geändert.",
  documentTimelineRowCreation:
    "{user} hat eine neue Zeile zur Tabelle {table} hinzugefügt.",
  documentTimelineRowDeletion:
    "{user} hat eine Zeile in der Tabelle {table} gelöscht.",
};
