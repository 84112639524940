<template>
  <tags-select-button
    v-if="inbox && inquiry"
    outline
    :dense="false"
    :flat="false"
    color="neutral-5"
    :show-label="true"
    :available-tags="inbox.tags"
    :tags="inquiry.tags"
    @add-tag="addTag"
    @remove-tag="removeTag"
  >
    <slot />
  </tags-select-button>
  <q-skeleton v-else type="rect" style="height: 40px" />
</template>

<script setup lang="ts">
import TagsSelectButton from "@/components/Tags/TagsSelectButton.vue";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";

const { inbox } = storeToRefs(useCurrentInboxStore());
const store = useCurrentInquiryStore();
const { inquiry } = storeToRefs(store);
const { addTag, removeTag } = store;
</script>
