<template>
  <div class="row no-wrap gap-sm">
    <div>
      <q-btn dense :disable="disable || !supplierRfq" text-color="neutral-7">
        <q-icon name="sym_r_attach_file" size="20px" class="q-mr-xs" />
        <q-icon name="sym_r_arrow_drop_down" size="20px" />
        <q-menu>
          <q-list dense>
            <q-item
              clickable
              v-ripple
              v-close-popup
              :active="supplierRfq && !supplierRfq.attachOriginalBoq"
              @click="emit('update:attachOriginalBoq', false)"
            >
              <q-item-section>
                <q-item-label>
                  <q-icon name="sym_r_list_alt" size="sm" class="q-mr-sm" />
                  <span>{{
                    $t("supplierRfqsPage.attachments.attachExcerpts")
                  }}</span>
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              clickable
              v-ripple
              v-close-popup
              :active="supplierRfq && supplierRfq.attachOriginalBoq"
              @click="emit('update:attachOriginalBoq', true)"
            >
              <q-item-section>
                <q-item-label>
                  <q-icon name="sym_r_content_copy" size="sm" class="q-mr-sm" />
                  <span>{{
                    $t("supplierRfqsPage.attachments.attachOriginalBoq")
                  }}</span>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <div class="row gap-sm items-center">
      <q-chip
        dense
        v-for="filename in originalBoqAttachmentNames"
        :key="filename"
        color="white"
        class="q-mr-sm attachment-chip"
      >
        <div class="q-pa-xs text-neutral-10">
          {{ filename }}
        </div>
      </q-chip>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SupplierRFQ } from "@/types/supplierRfq";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  supplierRfq?: SupplierRFQ;
  disable?: boolean;
}>();

const emit = defineEmits(["update:attachOriginalBoq"]);

const originalBoqAttachmentNames = computed(() => {
  return (
    props.supplierRfq?.boqDocumentFilenames.map(getOriginalBoqAttachmentName) ??
    []
  );
});

const { t } = useI18n();

function getOriginalBoqAttachmentName(filename: string) {
  if (props.supplierRfq?.attachOriginalBoq) {
    return filename;
  }

  const filenameParts = filename.split(".");
  const basename = filenameParts.slice(0, -1).join(".");
  const suffix = filenameParts.slice(-1)[0];
  return `${basename} (${t("supplierRfqsPage.attachments.excerpt")}).${suffix}`;
}
</script>

<style scoped lang="scss">
.attachment-chip {
  cursor: default;
  border: 1px solid var(--q-neutral-5);

  &.attachment-chip-content {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
