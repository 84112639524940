<template>
  <div class="fit column no-wrap" ref="gaebViewerEl">
    <q-linear-progress v-if="isLoading" indeterminate class="full-width" />

    <div class="q-pa-sm gaeb-viewer col-shrink full-height">
      <div class="q-py-md q-px-lg bg-white shadow-2" v-if="boq">
        <div class="q-mb-md">
          <h5 class="text-h5">
            {{ boq.boqLabel || $t("gaebViewer.billOfQuantities") }}
          </h5>
          <p v-if="boq.date" class="text-subtitle1">
            {{ boqDateOrNull }}
          </p>
          <template v-if="boq.buildingProjectId">
            <div class="text-subtitle1">
              {{ $t("gaebViewer.buildingProjectId") }}:
              {{ boq.buildingProjectId }}
            </div>
          </template>
          <p class="text-subtitle1">
            {{
              [boq.projectLabel, boq.projectName].filter(Boolean).join(" - ")
            }}
          </p>
          <p v-if="boq.projectDescription">
            {{ boq.projectDescription }}
          </p>
          <template v-if="boq.owner">
            <q-separator class="q-my-md" />
            <div class="text-h6 q-mb-xs">
              {{ $t("gaebViewer.ownerInformation") }}
            </div>
            <gaeb-owner :owner="boq.owner" />
          </template>
          <template v-if="boq.contractualRegulations.length > 0">
            <q-separator class="q-my-md" />
            <div class="text-h6 text-primary-7 q-mb-xs">
              {{ $t("gaebViewer.contractualRegulations") }}
            </div>
            <div
              v-for="(regulation, idx) in regulationTexts"
              :key="`${boq.buildingProjectId}-${idx}`"
              v-html="regulation"
              class="text-primary-7 q-mb-md"
              :class="{ 'gaeb-pre-wrap': !boq.isHtml }"
            ></div>
          </template>
        </div>
        <div class="text-h6 q-mb-xs">
          {{ $t("gaebViewer.positions") }}
        </div>
        <div
          class="bg-neutral-2 text-neutral-7 text-xs q-py-sm q-px-lg row no-wrap gap-sm"
        >
          <div class="gaeb-display-id-col-width">
            {{ $t("gaebViewer.position") }}
          </div>
          <div>{{ $t("gaebViewer.description") }}</div>
          <q-space />
          <div>{{ $t("gaebViewer.amount") }}</div>
        </div>
        <gaeb-element
          v-for="(element, idx) in boq.elements"
          :key="getPositionIdOrNull(element) ?? idx"
          :element="element"
          :is-html="boq.isHtml"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { provideGaebScrollToHighlight } from "@/composables/gaebViewer/useGaebScrollToHighlight";
import { getDocumentGaebBoq } from "@/api/document";

import type { Document } from "@/types/document";
import type {
  GaebDocumentBoq,
  GaebElement as GaebItemType,
  GaebPosition,
} from "@/types/gaeb";
import { computed, provide, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import GaebElement from "./GaebElement.vue";
import GaebOwner from "./GaebOwner.vue";
import { useRouteParams } from "@/composables/useRouteParams";

const { inboxId, inquiryId } = useRouteParams();

const props = defineProps<{
  document: Document;
  useSelection?: boolean;
}>();

const isLoading = ref(false);

const boq = ref<GaebDocumentBoq | undefined>(undefined);
const gaebViewerEl = ref<HTMLDivElement | null>(null);

async function loadBoq() {
  isLoading.value = true;
  try {
    boq.value = await getDocumentGaebBoq(
      inboxId.value,
      inquiryId.value,
      props.document.id
    );
  } finally {
    isLoading.value = false;
  }
}

function reset() {
  boq.value = undefined;
  isLoading.value = false;
  gaebViewerEl.value?.scrollTo({ top: 0 });
  loadBoq();
}

watch(() => props.document, reset, { immediate: true });

const { d } = useI18n();

const boqDateOrNull = computed(() => {
  if (!boq.value?.date) {
    return null;
  }
  const date = new Date(boq.value.date);
  return d(date, "longDate");
});

const regulationTexts = computed(() => {
  return boq.value?.contractualRegulations.map((regulation) =>
    boq.value?.isHtml ? regulation.replace(/>\s+</g, "><") : regulation
  );
});

function getPositionIdOrNull(element: GaebItemType): string | null {
  if (element.type === "position") return (element as GaebPosition).referenceId;
  if (element.type === "section") return null;
  return element.xmlId ?? null;
}

provide<boolean>("gaeb-viewer-use-selection", props.useSelection);

const { scrollToHighlight } = provideGaebScrollToHighlight();

defineExpose({ scrollToHighlight });
</script>

<style lang="scss">
.gaeb-display-id-col-width {
  width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
}

.gaeb-pre-wrap {
  white-space: pre-wrap;
}

.gaeb-viewer {
  overflow-y: auto;

  img {
    max-width: 100%;
  }
}
</style>
