<template>
  <q-btn flat dense :disable="isDisabled" @click.left="handleClick">
    <q-avatar size="sm" :color="color" text-color="white">
      <q-icon :name="icon" size="14px" />
      <q-tooltip>{{ $t(`supplierRfqStatus.${status}`) }}</q-tooltip>
    </q-avatar>
    <q-menu v-if="props.status !== 'DRAFT'">
      <q-list dense>
        <q-item
          clickable
          v-close-popup
          v-if="props.status === 'SENT'"
          @click="$emit('markAsAnswered')"
        >
          <q-item-section>
            <q-item-label>
              <q-avatar
                size="sm"
                :color="colors.ANSWERED"
                text-color="white"
                class="q-mr-sm"
              >
                <q-icon :name="icons.supplierRfqStatus.ANSWERED" size="14px" />
              </q-avatar>
              {{ $t(`supplierRfqsPage.markAsAnswered`) }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          v-close-popup
          v-if="props.status === 'ANSWERED'"
          @click="$emit('markAsUnanswered')"
        >
          <q-item-section>
            <q-item-label>
              <q-avatar
                size="sm"
                :color="colors.SENT"
                text-color="white"
                class="q-mr-sm"
              >
                <q-icon :name="icons.supplierRfqStatus.SENT" size="14px" />
              </q-avatar>
              {{ $t(`supplierRfqsPage.markAsUnanswered`) }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import icons from "@/config/icons";
import type { SupplierRFQStatus } from "@/types/supplierRfq";
import { computed } from "vue";

const props = defineProps<{
  status: SupplierRFQStatus;
}>();

defineEmits(["markAsAnswered", "markAsUnanswered"]);

const icon = computed(() => {
  switch (props.status) {
    case "DRAFT":
      return icons.supplierRfqStatus.DRAFT;
    case "SENT":
      return icons.supplierRfqStatus.SENT;
    case "ANSWERED":
      return icons.supplierRfqStatus.ANSWERED;
  }
  throw new Error(`Invalid supplierRfq status: ${props.status}`);
});

const colors = {
  DRAFT: "neutral-5",
  SENT: "primary",
  ANSWERED: "positive",
};

const color = computed(() => {
  switch (props.status) {
    case "DRAFT":
      return colors.DRAFT;
    case "SENT":
      return colors.SENT;
    case "ANSWERED":
      return colors.ANSWERED;
  }
  throw new Error(`Invalid supplierRfq status: ${props.status}`);
});

const isDisabled = computed(() => props.status === "DRAFT");

function handleClick(event: Event) {
  if (isDisabled.value) return;
  event.preventDefault();
  event.stopPropagation();
}
</script>
