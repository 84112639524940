<template>
  <q-btn
    icon="sym_r_delete"
    color="neutral-7"
    flat
    dense
    @click="confirmDelete"
    :disable="disabled"
  >
    <q-tooltip>{{ $t("Delete") }}</q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import type { ShortInquiry } from "@/types/inquiry";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";

const q = useQuasar();
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    selectedInquiries: ShortInquiry[];
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const emit = defineEmits<{
  delete: [];
}>();

function confirmDelete() {
  q.dialog({
    title: t("deleteMultiInquiryButton.confirmDelete.title"),
    message: t("deleteMultiInquiryButton.confirmDelete.message", {
      numInquiries: props.selectedInquiries.length,
    }),
    ok: {
      label: t("Delete"),
      color: "negative",
      dense: true,
    },
    cancel: {
      label: t("Cancel"),
      color: "neutral-8",
      flat: true,
      dense: true,
    },
  }).onOk(() => {
    emit("delete");
  });
}
</script>
