<template>
  <div class="q-py-md gaeb-table-row" ref="gaebTableRowEl" v-bind="$attrs">
    <div v-if="$slots.topline || topline" class="row no-wrap gap-sm q-px-lg">
      <div class="gaeb-display-id-col-width"></div>
      <slot name="topline">
        <span class="text-italic">
          {{ topline }}
        </span>
      </slot>
    </div>
    <div class="row no-wrap gap-sm q-px-lg">
      <div class="gaeb-display-id-col-width">
        <slot name="id">
          {{ referenceId }}
        </slot>
      </div>
      <div class="col">
        <div class="row no-wrap justify-start gap-sm q-mb-sm">
          <div class="col text-bold">
            <slot name="textShort">
              {{ textShort }}
            </slot>
          </div>
          <div class="col-auto">
            <slot name="amount">
              {{ amount }}
            </slot>
          </div>
        </div>
        <slot name="text">
          <div v-html="textHtml" :class="{ 'gaeb-pre-wrap': !isHtml }"></div>
        </slot>
      </div>
    </div>
    <div v-if="isSelected" class="selection-overlay"></div>
  </div>
  <q-separator />
</template>

<script setup lang="ts">
import { ref } from "vue";

defineProps<{
  topline?: string;
  referenceId?: string;
  textShort?: string;
  textHtml?: string;
  isHtml?: boolean;
  amount?: string;
  isSelected?: boolean | null;
}>();

const gaebTableRowEl = ref<HTMLDivElement | null>(null);

defineExpose({
  scrollIntoView: () => {
    gaebTableRowEl.value?.scrollIntoView({ block: "start" });
  },
});
</script>

<style scoped lang="scss">
.gaeb-table-row {
  position: relative;
  scroll-margin-top: 100px;
}

.selection-overlay {
  position: absolute;
  top: 0;
  left: -16px;
  width: 16px;
  height: 100%;
  background-color: $primary-1;
}
</style>
