export function formatCurrencyOrNull(
  locale: string,
  value: number | null,
  currency: string | null
): string {
  if (value === null) return "";
  if (currency === null)
    return Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
    }).format(value);
  return Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(value);
}

export function formatTaxRateOrNull(value: number | null): string {
  return value === null ? "" : `${value}%`;
}
