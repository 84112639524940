<template>
  <span v-for="(block, idx) in highlightSpans" :key="idx">
    <span :class="block.type === 'highlight' ? 'text-hl' : ''">
      {{ block.text }}
    </span>
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  text: string;
  searchText: string;
}>();

const highlightSpans = computed(() => {
  const searchTerms = props.searchText
    ? props.searchText.toLowerCase().split(" ").filter(Boolean)
    : [];

  let remainingText = props.text;
  const result = [];

  while (remainingText.length > 0) {
    const nextMatch = searchTerms.reduce(
      (acc, term) => {
        const index = remainingText.toLowerCase().indexOf(term);
        if (index !== -1 && (acc.index === -1 || index < acc.index)) {
          return { index, term };
        }
        return acc;
      },
      { index: -1, term: "" }
    );

    if (nextMatch.index === -1) {
      result.push({
        text: remainingText,
        type: "normal",
      });
      break;
    }

    if (nextMatch.index > 0) {
      result.push({
        text: remainingText.slice(0, nextMatch.index),
        type: "normal",
      });
    }

    result.push({
      text: remainingText.slice(
        nextMatch.index,
        nextMatch.index + nextMatch.term.length
      ),
      type: "highlight",
    });

    remainingText = remainingText.slice(
      nextMatch.index + nextMatch.term.length
    );
  }

  return result;
});
</script>

<style scoped lang="scss">
.text-hl {
  background-color: $primary-2;
}
</style>
