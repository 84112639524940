<template>
  <div class="filename-display row no-wrap">
    <div class="ellipsis col">{{ stem }}</div>
    <div class="ellipsis col-auto">{{ suffix }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  filename: string;
}>();

const filenameParts = computed(() => {
  if (!props.filename) return [];
  return props.filename.split(".");
});

const stem = computed(() => filenameParts.value.slice(0, -1).join("."));

const suffix = computed(() => {
  if (!filenameParts.value.length) return "";
  if (filenameParts.value.length === 1) return filenameParts.value[0];
  return `.${filenameParts.value.slice(-1)[0]}`;
});
</script>

<style scoped lang="scss">
.filename-display {
  max-width: 100%;
}
</style>
