<template>
  <q-btn
    flat
    dense
    color="neutral-7"
    icon="sym_r_trending_flat"
    :disable="disabled"
  >
    <inbox-select-menu @select="confirmMove" />
    <q-tooltip>{{ $t("moveMultiInquiryButton.label") }}</q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import InboxSelectMenu from "@/components/InboxSelectMenu.vue";
import type { NamedInbox } from "@/types/inbox";
import type { ShortInquiry } from "@/types/inquiry";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";

const q = useQuasar();
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    selectedInquiries: ShortInquiry[];
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const emit = defineEmits<{
  move: [targetInboxId: number];
}>();

function confirmMove(targetInbox: NamedInbox) {
  q.dialog({
    title: t("moveMultiInquiryButton.confirmMove.title"),
    message: t("moveMultiInquiryButton.confirmMove.message", {
      numInquiries: props.selectedInquiries.length,
      targetInbox: targetInbox.name,
    }),
    ok: {
      label: t("moveMultiInquiryButton.confirmMove.ok"),
      color: "primary",
      dense: true,
    },
    cancel: {
      label: t("Cancel"),
      color: "neutral-8",
      flat: true,
      dense: true,
    },
  }).onOk(() => {
    emit("move", targetInbox.id);
  });
}
</script>
