<template>
  <div class="row items-center gap-x-sm">
    <FilterChip
      v-if="filterOptions.status?.length"
      :icon="icons.filterStatus"
      @remove="removeFilter('status')"
    >
      {{
        filterOptions.status
          ?.map((status) => t(`supplierRfqStatus.${status}`))
          .join(", ")
      }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.assignedUserId?.length"
      :icon="icons.filterUser"
      @remove="removeFilter('assignedUserId')"
    >
      {{ t("filterChips.nUsers", { n: filterOptions.assignedUserId.length }) }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.buildingProject"
      :icon="icons.filterBuildingProject"
      @remove="removeFilter('buildingProject')"
    >
      {{ filterOptions.buildingProject }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.inquiryShortCode"
      :icon="icons.filterShortCode"
      @remove="removeFilter('inquiryShortCode')"
    >
      {{ filterOptions.inquiryShortCode }}
    </FilterChip>
    <FilterChip
      v-if="filterOptions.inquiryId"
      :icon="icons.filterInquiry"
      @remove="removeFilter('inquiryId')"
    >
      {{ inquiryShortCodeForFilteredId }}
    </FilterChip>
  </div>
</template>

<script setup lang="ts">
import FilterChip from "@/components/FilterChip.vue";
import icons from "@/config/icons";
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();
const currentSupplierRfqsStore = useCurrentSupplierRfqsStore();

const { filterOptions } = storeToRefs(currentSupplierRfqsStore);

const removeFilter = (filterKey: string) => {
  currentSupplierRfqsStore.removeFilter(filterKey);
};

const inquiryShortCodeForFilteredId = computed(
  () => router.currentRoute.value.query.inquiryShortCodeForFilteredId
);
</script>
