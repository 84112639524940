<template>
  <div class="focus-overlay" :style="style"></div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { DisplayHighlight } from "../PdfViewer/types";

const props = defineProps<{
  highlights: DisplayHighlight[];
  show: boolean;
}>();

const style = computed(() => {
  if (!props.show) {
    return { display: "none" };
  }
  // create a polygon with all highlights cut out
  const polygon = ["0 0", "0 100%", "100% 100%", "100% 0", "0 0"];

  for (const highlight of props.highlights) {
    const corners = highlight.boundingRegion.map(
      ([x, y]) => `${x * 100}% ${y * 100}%`
    );
    polygon.push(...corners);
    // end with the first corner to close the polygon
    polygon.push(corners[0]);
  }

  return { "clip-path": `polygon(${polygon.join(", ")})` };
});
</script>

<style scoped lang="scss">
.focus-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.8;
  z-index: 100;
  cursor: pointer;
}
</style>
