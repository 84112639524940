<template>
  <select-menu
    anchor="top end"
    self="top start"
    :options="inboxNames.filter(
                (inbox: NamedInbox) => inbox.id !== inboxId
              )"
    :selected-option="null"
    :search-placeholder="$t('moveInquiryButton.searchPlaceholder')"
    id-value="id"
    :search-values="['name']"
    :label-fn="(option: NamedInbox) => option.name"
    @select="(targetInbox) => $emit('select', targetInbox)"
  />
</template>

<script setup lang="ts">
import SelectMenu from "@/components/SelectMenus/SelectMenu.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentOrganizationInboxNamesStore } from "@/stores/currentOrganizationInboxNames";
import { storeToRefs } from "pinia";
import type { NamedInbox } from "@/types/inbox";

const { inboxId } = useRouteParams();

const { inboxNames } = storeToRefs(useCurrentOrganizationInboxNamesStore());

defineEmits<{
  select: [targetInbox: NamedInbox];
}>();
</script>
