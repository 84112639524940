<template>
  <div>
    <q-input
      dense
      outlined
      clearable
      v-model="search"
      :label="$t('Search')"
      :disable="isLoading"
      @clear="search = ''"
    />
    <template v-if="!isLoading">
      <div class="q-px-md q-mt-md q-mb-xs text-neutral-7">
        {{
          $t("settings.organization.members.count", {
            count: filteredMembers.length,
          })
        }}
      </div>
      <q-separator />
    </template>
    <q-skeleton v-if="isLoading" />
    <q-list v-else separator>
      <q-item v-for="user in filteredMembers" :key="user.id" clickable>
        <q-item-section avatar>
          <user-avatar :user="user" />
        </q-item-section>
        <q-item-section style="flex-grow: 20000">
          <q-item-label>{{ user.firstName }} {{ user.lastName }}</q-item-label>
          <q-item-label caption>{{ user.email }}</q-item-label>
        </q-item-section>
        <q-item-section>
          <q-badge
            class="q-mr-sm"
            color="white"
            text-color="neutral-7"
            :label="$t(RoleLabels[user.role!])"
          >
            <q-menu anchor="bottom left" auto-close v-if="!isYourself(user)">
              <q-item
                v-for="role in organizationRoleOptions"
                :key="role"
                clickable
                v-ripple
                @click="updateRole(user, role)"
              >
                <q-item-section>
                  <q-item-label>{{ $t(RoleLabels[role]) }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-menu>
            <q-tooltip v-else>
              {{ $t("You cannot change your own role") }}
            </q-tooltip>
          </q-badge>
        </q-item-section>
        <q-item-section side style="width: 100px">
          <user-status-badge :user="user" />
        </q-item-section>
        <q-item-section side>
          <q-btn
            flat
            round
            size="sm"
            color="neutral-8"
            icon="sym_r_delete"
            @click.prevent.stop="deleteUser(user.id)"
          >
            <q-tooltip :delay="500"> {{ $t("Remove user") }} </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>
    </q-list>
    <div class="row justify-end q-my-md q-gutter-x-sm">
      <q-btn
        dense
        color="primary"
        icon="sym_r_add"
        :label="$t('Invite users')"
        @click="inviteUsers()"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { listInboxes } from "@/api/inbox";
import * as api from "@/api/user";
import { deleteUser as deleteAPIUser, listUsers } from "@/api/user";
import UserAvatar from "@/components/User/UserAvatar.vue";
import UserStatusBadge from "@/components/User/UserStatusBadge.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentUserStore } from "@/stores/currentUser";
import type { ShortInbox } from "@/types/inbox";
import {
  type OrganizationRoleName,
  organizationRoleOptions,
  RoleLabels,
} from "@/types/role";
import type { UserWithRole } from "@/types/user";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import InviteMembersDialog from "./InviteMembersDialog.vue";

const { user } = storeToRefs(useCurrentUserStore());

function isYourself(member: UserWithRole) {
  return member.id === user.value?.id;
}

const q = useQuasar();
const { t } = useI18n();

const emit = defineEmits(["update-users"]);

const { organizationId } = useRouteParams();

const isLoading = ref(false);

const members = ref<UserWithRole[]>([]);
const inboxes = ref<ShortInbox[]>([]);
const search = ref("");
const filteredMembers = computed(() =>
  members.value.filter(
    (member) =>
      member.firstName.toLowerCase().includes(search.value.toLowerCase()) ||
      member.lastName.toLowerCase().includes(search.value.toLowerCase()) ||
      member.email.toLowerCase().includes(search.value.toLowerCase())
  )
);

onMounted(loadData);
async function loadData() {
  isLoading.value = true;

  try {
    members.value = await listUsers(organizationId.value);
    inboxes.value = await listInboxes(organizationId.value);
  } finally {
    isLoading.value = false;
  }
}

function inviteUsers() {
  q.dialog({
    component: InviteMembersDialog,
    componentProps: {
      organizationId: organizationId.value,
      inboxes: inboxes.value,
    },
  }).onOk(onUsersChanged);
}

async function deleteUser(userId: number) {
  q.dialog({
    title: t("Remove user"),
    message: t("Are you sure you want to remove this user?"),
    ok: {
      color: "negative",
    },
    cancel: {
      flat: true,
      color: "neutral-10",
    },
    persistent: true,
  }).onOk(async () => {
    await deleteAPIUser(organizationId.value, userId);
    onUsersChanged();
  });
}

async function onUsersChanged() {
  emit("update-users");
  await loadData();
}

async function updateRole(member: UserWithRole, role: OrganizationRoleName) {
  await api.updateUserRole(organizationId.value, member, role);
  member.role = role;
}
</script>
