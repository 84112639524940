<template>
  <div class="inquiry-row">
    <div class="inquiry-row__selected"></div>
    <div class="inquiry-row__id"><q-skeleton type="rect" height="20px" /></div>
    <div class="inquiry-row__sender">
      <q-skeleton type="rect" height="20px" />
    </div>
    <div class="inquiry-row__building-project">
      <q-skeleton type="rect" height="20px" />
    </div>
    <q-space />
    <div class="inquiry-row__tags">
      <div class="row gap-sm">
        <q-skeleton type="QChip" height="20px" v-for="i in 2" :key="i" />
      </div>
    </div>
    <div class="inquiry-row__dates">
      <div class="column gap-xs">
        <q-skeleton type="rect" height="10px" width="80px" />
        <q-skeleton type="rect" height="10px" width="80px" />
      </div>
    </div>
    <div class="inquiry-row__progress">
      <q-skeleton type="rect" height="24px" width="80px" />
    </div>
    <div class="inquiry-row__status"></div>
    <q-skeleton type="circle" height="24px" width="24px" />
    <div class="inquiry-row__assigned-to">
      <q-skeleton type="circle" height="24px" width="24px" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.inquiry-row {
  min-height: 48px;
  padding: 0 16px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 32px;

  @media (max-width: $breakpoint-lg-max) {
    gap: 24px;
  }

  @media (max-width: $breakpoint-md-max) {
    gap: 16px;
  }

  @media (max-width: $breakpoint-sm-max) {
    gap: 8px;
  }

  &:hover {
    background-color: $neutral-2;
  }

  &__selected {
    margin-left: -8.75px; // align visible checkbox with rest of page
    width: 35px;
  }

  &__id {
    width: 52px;
    flex-shrink: 0;

    @media (max-width: $breakpoint-sm-max) {
      width: 48px;
      font-size: $text-xs;
    }
  }

  &__sender {
    width: 200px;
    flex-shrink: 0;

    @media (max-width: $breakpoint-lg-max) {
      width: 150px;
    }

    @media (max-width: $breakpoint-sm-max) {
      display: none;
    }
  }

  &__building-project {
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;

    @media (max-width: $breakpoint-sm-max) {
      min-width: 100px;
      font-size: $text-xs;
    }
  }

  &__tags {
    flex-grow: 0;

    @media (max-width: $breakpoint-md-max) {
      display: none;
    }
  }

  &__dates {
    flex-shrink: 0;
    width: 100px;
  }

  &__progress {
    flex-shrink: 0;
    width: 80px;
  }
}
</style>
