<template>
  <div class="inquiry-history-separator">
    <div class="inquiry-history-separator__line"></div>
  </div>
</template>

<style scoped lang="scss">
.inquiry-history-separator {
  padding-left: 28px;

  &__line {
    height: 12px;
    width: 2px;
    background-color: $neutral-5;
  }
}
</style>
