const deDE = {
  decimal: {
    style: "decimal",
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  },
};

export default {
  "de-DE": deDE,
  de: deDE,
};
