const IS_VISIBLE_BUFFER = 20;

/** Return true if a DOM element is currently scrolled into view */
export function isVisible(element: Element, container: Element): boolean {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return (
    elementRect.bottom + IS_VISIBLE_BUFFER >= containerRect.top &&
    elementRect.top - IS_VISIBLE_BUFFER <= containerRect.bottom
  );
}
