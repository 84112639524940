<template>
  <div class="slim-page">
    <settings-page-header section="inbox" page="email" />
    <div class="q-px-md">
      <div class="q-mb-md">
        {{ $t("settings.inbox.email.intakeAddressHelpText") }}
      </div>
      <q-input
        v-if="inbox"
        dense
        outlined
        readonly
        name="email"
        v-model="inbox.intakeAddress"
        :label="$t('inbox.intakeAddress')"
      >
        <template #append>
          <q-btn
            dense
            flat
            color="neutral-7"
            @click="copyToClipboard"
            icon="sym_r_content_copy"
          >
            <q-tooltip>
              {{ $t("settings.inbox.email.copyToClipboard") }}
            </q-tooltip>
          </q-btn>
        </template>
      </q-input>
      <q-skeleton type="QInput" v-else />
    </div>
  </div>
</template>

<script setup lang="ts">
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useInboxesStore } from "@/stores/inboxes";
import type { Inbox } from "@/types/inbox";
import { useQuasar } from "quasar";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const store = useInboxesStore();

const { organizationId, inboxId } = useRouteParams();

const q = useQuasar();
const { t } = useI18n();

const inbox = ref<Inbox | null>(null);
watch(
  () => [organizationId.value, inboxId.value],
  async () => {
    inbox.value = await store.getInbox(inboxId.value);
  },
  { immediate: true }
);

function copyToClipboard() {
  if (!inbox.value) return;
  navigator.clipboard.writeText(inbox.value?.intakeAddress);
  q.notify({
    message: t("settings.inbox.email.copiedToClipboard"),
  });
}
</script>
