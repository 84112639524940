export function useRequestCancellation() {
  // If the user changes any of the rows while the data is loading, the loading should be canceled
  // This is to prevent the data from being overwritten by the old data
  const openLoadingRequests: { [index: string]: () => void } = {};
  function cancelAllOpenLoadingRequests() {
    for (const key in openLoadingRequests) {
      openLoadingRequests[key]();
      delete openLoadingRequests[key];
    }
  }

  function cancelsLoading<T extends Array<any>, U>(
    fn: (...args: T) => Promise<U>
  ) {
    return async function (...args: T): Promise<U> {
      cancelAllOpenLoadingRequests();
      const result = fn(...args);
      cancelAllOpenLoadingRequests();
      return result;
    };
  }

  return {
    cancelsLoading,
    openLoadingRequests,
  };
}
