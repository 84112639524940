<template>
  <div class="row no-wrap gap-xs items-center">
    <q-icon
      size="16px"
      :name="icon"
      :class="
        isOverdue
          ? 'text-negative-5'
          : isClose
          ? 'text-warning-5'
          : 'text-neutral-5'
      "
    >
    </q-icon>
    <div class="text-no-wrap">
      {{ displayString }}
    </div>
    <q-tooltip>
      {{ name }}:
      <template v-if="date">{{ $d(date, "longDate") }}</template>
      <template v-else>&ndash;</template>
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import { formatDistanceToNow } from "@/i18n/formatDistanceToNow";
import { differenceInDays } from "date-fns";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  date: Date | null;
  showOverdueClose: boolean;
  icon: string;
  name: string;
  isOverdue?: boolean;
  isClose?: boolean;
}>();

const { t, locale } = useI18n();

const displayString = computed(() => {
  if (!props.date) return "-";
  const submission = setToNowTime(props.date);
  const now = new Date();
  if (submission >= now) return getFutureDisplayString(submission);
  else return getPastDisplayString(submission);
});

function getFutureDisplayString(submission: Date) {
  const now = new Date();
  if (differenceInDays(submission, now) == 0)
    return t("submissionDisplay.today");
  if (differenceInDays(submission, now) == 1)
    return t("submissionDisplay.tomorrow");
  return formatDistanceToNow(submission, locale.value);
}

function getPastDisplayString(submission: Date) {
  const now = new Date();
  if (differenceInDays(now, submission) == 0)
    return t("submissionDisplay.yesterday");
  return formatDistanceToNow(submission, locale.value);
}

function setToNowTime(date: Date) {
  const result = new Date();
  result.setFullYear(date.getFullYear());
  result.setMonth(date.getMonth());
  result.setDate(date.getDate());
  return result;
}
</script>
