export default {
  "Mark correct": "Als korrekt markieren",
  "Must be a valid date.": "Muss ein gültiges Datum enthalten.",
  "Analyzing document": "Dokument wird analysiert",
  "Exporting document": "Dokument wird exportiert",
  "Try again": "Erneut versuchen",
  "Analysis failed": "Analyse fehlgeschlagen",
  "Export failed": "Export fehlgeschlagen",
  Every: "Alle",
  from_date: "ab dem",
  "Click to search": "Zum Suchen klicken",
  search: "{0} suchen",
  or: "oder",
  "This value should not be empty": "Dieser Wert sollte nicht leer sein",
  "This table should have at least one row":
    "Diese Tabelle sollte mindestens eine Zeile enthalten",
  "Add entry": "Eintrag hinzufügen",
  "Entry removed": "Eintrag entfernt",
  "Remove entry": "Eintrag entfernen",
  "Would you like to remove this entry?":
    "Möchten Sie diesen Eintrag entfernen?",
};
