import { useI18n } from "vue-i18n";

export function useFormValidationRules() {
  const { t } = useI18n();

  function isNotEmpty(value: string | number): boolean | string {
    if (typeof value === "number") return true;
    if (value && value.length > 0) return true;
    return t("This field is required");
  }

  function isEmail(value: string): boolean | string {
    const emailPattern =
      /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    if (emailPattern.test(value)) return true;
    return t("Must contain a valid e-mail address");
  }

  return {
    isNotEmpty,
    isEmail,
  };
}
