<template>
  <div class="flex row no-wrap items-center">
    <q-avatar
      size="sm"
      :color="statusColor.startsWith('#') ? '' : statusColor"
      :text-color="statusTextColor"
      :style="{
        backgroundColor: statusColor.startsWith('#') ? statusColor : '',
      }"
      :class="{ 'q-mr-sm': !iconOnly }"
    >
      <q-icon :name="statusIcon" size="14px" />
    </q-avatar>
    <template v-if="!iconOnly">
      {{ $t(status.name) }}
    </template>
  </div>
</template>

<script setup lang="ts">
import type { InquiryStatus } from "@/types/inquiry";
import { getStatusIcon, getStatusTextColor } from "@/utils/statusColor";
import { computed } from "vue";

const props = defineProps<{
  status: Pick<InquiryStatus, "name" | "color" | "originalStatus">;
  iconOnly?: boolean;
}>();

const statusColor = computed(() => props.status.color || "neutral-6");
const statusTextColor = computed(() =>
  getStatusTextColor(props.status.originalStatus)
);
const statusIcon = computed(() => getStatusIcon(props.status.originalStatus));
</script>
