<template>
  <q-menu context-menu>
    <q-list dense>
      <q-item
        v-if="organization?.useSupplierData"
        clickable
        v-close-popup
        @click="updateShowSupplierRfqs(true)"
        :disable="!showAddSupplierRfq || disabled"
      >
        <q-item-section>{{
          $t("inquiryPositionsPage.offerPositionGroup.addSupplierRfq")
        }}</q-item-section>
      </q-item>
      <q-item
        v-if="organization?.useSupplierData"
        clickable
        v-close-popup
        @click="updateShowSupplierRfqs(false)"
        :disable="!showCancelSupplierRfq || disabled"
      >
        <q-item-section>{{
          $t("inquiryPositionsPage.offerPositionGroup.removeSupplierRfq")
        }}</q-item-section>
      </q-item>
      <q-item
        clickable
        v-close-popup
        :disable="
          !clickedGroupId || disabled || !clickedGroup?.offerPositions.length
        "
        @click="$emit('update:copy-products-source-group-id', clickedGroupId)"
      >
        <q-item-section>{{
          $t("inquiryPositionsPage.offerPositionGroup.copyProducts")
        }}</q-item-section>
      </q-item>
      <q-item
        clickable
        v-close-popup
        :disable="!copyProductsSourceGroupId || disabled"
        @click="pasteProducts"
      >
        <q-item-section>{{
          $t("inquiryPositionsPage.offerPositionGroup.pasteProducts")
        }}</q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script setup lang="ts">
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  disabled: boolean;
  selectedGroupIds: number[];
  clickedGroupId: number | null;
  copyProductsSourceGroupId: number | null;
}>();

const emit = defineEmits<{
  "update-show-supplier-rfqs": [showSupplierRfqs: boolean];
  "update:copy-products-source-group-id": [groupId: number | null];
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());

const opgStore = useCurrentOfferPositionGroupsStore();

const { allOfferPositionGroups } = storeToRefs(opgStore);

const sourceGroup = computed(() => {
  return allOfferPositionGroups.value?.find(
    (group) => group.id === props.copyProductsSourceGroupId
  );
});

const clickedGroup = computed(() => {
  return allOfferPositionGroups.value?.find(
    (group) => group.id === props.clickedGroupId
  );
});

const selectedGroups = computed(
  () =>
    allOfferPositionGroups.value?.filter((group) =>
      props.selectedGroupIds.includes(group.id)
    ) || []
);

const showAddSupplierRfq = computed(() => {
  return selectedGroups.value.some((pos) => !pos.showSupplierRfqs);
});

const showCancelSupplierRfq = computed(() => {
  return selectedGroups.value.some((pos) => pos.showSupplierRfqs);
});

async function updateShowSupplierRfqs(showSupplierRfqs: boolean) {
  emit("update-show-supplier-rfqs", showSupplierRfqs);
  if (showSupplierRfqs) {
    await opgStore.showSupplierRfqs(
      selectedGroups.value.map((group) => group.id)
    );
  } else {
    await opgStore.hideSupplierRfqs(
      selectedGroups.value.map((group) => group.id)
    );
  }
}

const q = useQuasar();
const { t } = useI18n();

async function pasteProducts() {
  if (!clickedGroup.value || !sourceGroup.value)
    throw new Error("Group not found");

  if (clickedGroup.value.offerPositions.length == 0) {
    await doCopyProducts();
    return;
  }

  q.dialog({
    title: t("inquiryPositionsPage.offerPositionGroup.pasteProducts"),
    message: t(
      "inquiryPositionsPage.offerPositionGroup.pasteProductsConfirmation"
    ),
    ok: {
      dense: true,
      label: t("inquiryPositionsPage.offerPositionGroup.pasteProducts"),
      color: "primary",
    },
    cancel: {
      dense: true,
      flat: true,
      label: t("inquiryPositionsPage.offerPositionGroup.cancel"),
      color: "neutral-7",
    },
  }).onOk(async () => {
    await doCopyProducts();
  });
}

async function doCopyProducts() {
  if (!clickedGroup.value || !sourceGroup.value)
    throw new Error("Group not found");

  q.loading.show({ delay: 400 }); // only show if loading takes longer than 400ms
  try {
    await opgStore.copyProducts(sourceGroup.value.id, clickedGroup.value.id);
  } finally {
    q.loading.hide();
  }
}
</script>
