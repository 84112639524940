<template>
  <div class="text-xl text-weight-regular q-mb-xl">
    {{ $t("Welcome to kinisto") }}
  </div>
  <div class="login-form-container">
    <q-form @submit="callOnSubmit">
      <email-input
        :model-value="props.email"
        @update:model-value="$emit('update:email', $event!.toString())"
      />
      <password-input outlined v-model="password">
        <template v-slot:prepend>
          <q-icon name="sym_r_lock" />
        </template>
      </password-input>
      <q-banner
        rounded
        class="bg-negative text-white q-mb-md"
        v-if="loginFailed"
      >
        {{ $t("Login failed. Please check your email and password.") }}
        <template v-slot:action>
          <q-btn
            flat
            color="white"
            :label="$t('Dismiss')"
            @click="loginFailed = false"
          />
        </template>
      </q-banner>
      <q-btn
        unelevated
        size="lg"
        color="primary"
        type="submit"
        class="full-width q-mt-xs"
        :label="$t('Login')"
      />
    </q-form>
    <div class="q-mt-md">
      <a
        href="#"
        @click="$emit('resetPassword', email)"
        class="text-neutral-10"
      >
        {{ $t("Forgot password?") }}
      </a>
    </div>
    <q-inner-loading :showing="isLoading">
      <q-spinner size="50px" color="primary" />
    </q-inner-loading>
  </div>
</template>

<script setup lang="ts">
import { getCsrfCookie } from "@/api/client/csrf";
import type { QForm } from "quasar";
import { ref } from "vue";
import PasswordInput from "./PasswordInput.vue";
import EmailInput from "./EmailInput.vue";

const props = defineProps<{
  email: string;
  onSubmit: (email: string, password: string) => Promise<boolean>;
}>();

defineEmits<{
  "update:email": [email: string];
  resetPassword: [email: string];
}>();

const password = ref<string>("");

const isLoading = ref<boolean>(false);
const loginFailed = ref<boolean>(false);

// Make sure CSRF cookie is set before logging in
getCsrfCookie();

async function callOnSubmit() {
  isLoading.value = true;
  try {
    const loginResult = await props.onSubmit(props.email, password.value);
    loginFailed.value = !loginResult;
  } catch (e) {
  } finally {
    isLoading.value = false;
  }
}
</script>

<style lang="scss">
.login-form-container {
  width: 25rem;
  max-width: 100%;
}
</style>
