<template>
  <q-btn
    dense
    flat
    color="neutral-7"
    size="sm"
    :icon="buttonIcon"
    :loading="!inquiry || isLoading"
    @click="toggleSubscription"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
  >
    <q-tooltip v-if="inquiry">
      {{
        inquiry.isSubscribed
          ? $t("inquiryPage.inquirySubscribeButton.unsubscribe")
          : $t("inquiryPage.inquirySubscribeButton.subscribe")
      }}
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

const store = useCurrentInquiryStore();
const { inquiry } = storeToRefs(store);
const isLoading = ref(false);

const isHover = ref(false);
const buttonIcon = computed(() =>
  isHover.value
    ? inquiry.value?.isSubscribed
      ? "sym_r_notifications_off"
      : "sym_r_notifications_active"
    : inquiry.value?.isSubscribed
    ? "sym_r_notifications_active"
    : "sym_r_notifications_off"
);

async function toggleSubscription() {
  if (!inquiry.value) throw new Error("Inquiry is not loaded");

  isLoading.value = true;
  try {
    if (inquiry.value.isSubscribed) {
      await store.unsubscribeFromNotifications();
    } else {
      await store.subscribeToNotifications();
    }
  } finally {
    isLoading.value = false;
  }
}
</script>
