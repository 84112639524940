export const isMac = navigator.userAgent.includes("Mac");

export function ifCmdOrCtrl(fn: (e: KeyboardEvent) => void) {
  return function (e: KeyboardEvent) {
    if ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) {
      fn(e);
    }
  };
}

export function cmdOrCtrlPressed(e: KeyboardEvent | MouseEvent) {
  return (isMac && e.metaKey) || (!isMac && e.ctrlKey);
}

export const cmdOrCtrlSymbol = isMac ? "⌘" : "Ctrl";
export const optionSymbol = isMac ? "⌥" : "Alt";
