import * as api from "@/api/inquiryHistory";
import { getInquiryHistory } from "@/api/inquiryHistory";
import { useRouteParams } from "@/composables/useRouteParams";
import type {
  CommentBodyContent,
  InquiryHistoryEntry as InquiryHistoryEntryType,
} from "@/types/inquiryHistory";
import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useCurrentInquiryStore } from "./currentInquiry";

export const useCurrentInquiryHistoryStore = defineStore(
  "currentInquiryHistory",
  () => {
    const { organizationId, inboxId, inquiryId } = useRouteParams();

    const currentInquiryStore = useCurrentInquiryStore();
    const { inquiry } = storeToRefs(currentInquiryStore);

    const inquiryHistory = ref<InquiryHistoryEntryType[]>([]);
    const isLoading = ref(true);

    async function update() {
      if (!organizationId.value || !inboxId.value || !inquiryId.value) {
        return;
      }

      inquiryHistory.value = await getInquiryHistory(
        organizationId.value,
        inboxId.value,
        inquiryId.value
      );
      sortHistory();
      isLoading.value = false;
    }

    watch(
      [organizationId, inboxId, inquiryId],
      async () => {
        isLoading.value = true;
        try {
          inquiryHistory.value = [];
          await update();
        } finally {
          isLoading.value = false;
        }
      },
      {
        immediate: true,
      }
    );

    watch(inquiry, update);

    async function addComment(commentBodyContent: CommentBodyContent) {
      if (!organizationId.value || !inboxId.value || !inquiryId.value) {
        return;
      }

      const comment = await api.addComment(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        commentBodyContent
      );
      inquiryHistory.value.push(comment);
      sortHistory();

      currentInquiryStore.subscribeToNotifications();
    }

    async function deleteComment(commentId: number) {
      if (!organizationId.value || !inboxId.value || !inquiryId.value) {
        return;
      }

      await api.deleteInquiryHistory(inboxId.value, inquiryId.value, commentId);
      const index = inquiryHistory.value.findIndex(
        (entry) => entry.id === commentId
      );
      if (index !== -1) {
        inquiryHistory.value.splice(index, 1);
      }
    }

    async function updateComment(
      commentId: number,
      newContent: CommentBodyContent
    ) {
      if (!organizationId.value || !inboxId.value || !inquiryId.value) {
        return;
      }

      const comment = await api.updateComment(
        organizationId.value,
        inboxId.value,
        inquiryId.value,
        commentId,
        newContent
      );
      const index = inquiryHistory.value.findIndex(
        (entry) => entry.id === commentId
      );
      if (index !== -1) {
        inquiryHistory.value[index] = comment;
      }
    }

    function sortHistory() {
      // Need to create a new object to trigger reactivity
      const history = inquiryHistory.value;
      history.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());
      inquiryHistory.value = history;
    }

    return {
      inquiryHistory,
      isLoading,
      addComment,
      deleteComment,
      updateComment,
    };
  }
);
