<template>
  <editor-content :editor="editor" @keydown.esc="editor?.commands.blur()" />
</template>

<script setup lang="ts">
import type { CommentBodyContent } from "@/types/inquiryHistory";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, Extension, useEditor } from "@tiptap/vue-3";
import { watch } from "vue";
import { defaultExtensions } from "./commentEditorExtensions";
import commentEditorSuggestion from "./commentEditorSuggestion";
import { getUserLabel } from "@/components/User/userLabel";

const props = defineProps<{
  modelValue: CommentBodyContent | null;
  placeholder: string;
  editable: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: CommentBodyContent | null];
  blur: [];
}>();

defineExpose({
  focus,
});

// Disable new line on Ctrl+Enter / Cmd+Enter, since it's used to trigger publishing
const DisableCtrlCmdEnter = Extension.create({
  addKeyboardShortcuts() {
    return {
      "Mod-Enter": () => {
        return true;
      },
    };
  },
});

const editor = useEditor({
  content: props.modelValue,
  extensions: [
    ...defaultExtensions,
    Placeholder.configure({
      placeholder: props.placeholder,
    }),
    DisableCtrlCmdEnter,
    Mention.configure({
      HTMLAttributes: {
        class: "mention",
      },
      suggestion: commentEditorSuggestion,
      renderHTML: ({ options, node }) => [
        "span",
        { class: "mention" },
        `${options.suggestion.char}${getUserLabel(node.attrs.id)}`,
      ],
    }),
  ],
  editable: props.editable,
  onUpdate: ({ editor }) => {
    const content = editor.getJSON();

    // If the content is empty, emit null
    // This makes it easier to handle empty content in the parent component
    if (isEmptyContent(content)) {
      emit("update:modelValue", null);
      return;
    }
    emit("update:modelValue", content);
  },
  onBlur: () => {
    emit("blur");
  },
  editorProps: {
    attributes: {
      class: "comment-editor",
    },
  },
});

watch(
  () => props.modelValue,
  (value) => {
    const isSame =
      JSON.stringify(editor.value?.getJSON()) === JSON.stringify(value);
    if (!isSame) {
      editor.value?.commands.setContent(value, false);
    }
  }
);

watch(
  () => props.editable,
  (value) => {
    editor.value?.setEditable(value);
  }
);

function isEmptyContent(content: CommentBodyContent) {
  if (!content || !content.content) {
    return true;
  }
  if (content.type === "doc" && content.content.length === 0) {
    return true;
  }
  if (content.type === "doc" && content.content.length === 1) {
    const node = content.content[0];
    if (node.type === "paragraph" && node.content?.length === 0) {
      return true;
    }
  }
}

function focus() {
  editor.value?.commands.focus("end");
}
</script>

<style lang="scss">
.comment-editor:focus-visible {
  outline: none;
}

.tiptap .mention {
  background-color: $primary-1;
  border-radius: 0.4rem;
  box-decoration-break: clone;
  color: $primary-9;
  padding: 0.1rem 0.3rem;
}

// Enable placeholder for empty editor
// See https://tiptap.dev/docs/editor/api/extensions/placeholder
.tiptap p.is-editor-empty:first-child::before {
  color: $neutral-5;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;
    align-items: center;

    > label {
      flex: 0 0 auto;
      display: flex;
      align-items: baseline;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }

    ul li,
    ol li {
      display: list-item;
    }

    ul[data-type="taskList"] > li {
      display: flex;
    }
  }
}
</style>
