<template>
  <div class="price-input-container row items-center no-wrap justify-end">
    <input
      v-if="adjustable"
      ref="inputRef"
      class="standalone-input text-right price-input"
      @focus="($refs.inputRef as HTMLInputElement).select()"
      :disabled="disabled"
    />
    <template v-else>
      {{ formattedValue }}
    </template>
    <q-tooltip :delay="1000">
      {{ $t("offerPosition.unitPrice") }}
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { formatCurrencyOrNull } from "@/utils/currency";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";
import { useI18n } from "vue-i18n";
import { DEFAULT_CURRENCY } from "@/config/defaults";

const props = defineProps<{
  modelValue: number | null;
  disabled: boolean;
  adjustable: boolean;
}>();

defineEmits<{
  "update:value": [value: number | null];
  change: [value: number | null];
}>();

const { locale } = useI18n();
const { organization } = storeToRefs(useCurrentOrganizationStore());

const currency = computed(
  () => organization.value?.details.currency || DEFAULT_CURRENCY
);

const formattedValue = computed(() => {
  return props.modelValue
    ? formatCurrencyOrNull(locale.value, props.modelValue, currency.value)
    : null;
});

const options = computed(() => ({
  currency: currency.value,
  locale: locale.value,
  precision: 2,
}));

const { inputRef, setOptions, setValue } = useCurrencyInput(options.value);

watch(
  () => [organization.value?.details.currency, locale.value],
  () => setOptions(options.value)
);

watch(
  () => props.modelValue,
  (newValue) => {
    setValue(newValue);
  }
);
</script>

<style scoped lang="scss">
.price-input-container {
  width: 100px;
}

.price-input {
  width: 100%;
}
</style>
