import { onUnmounted, ref } from "vue";

const DEFAULT_DELAY = 5000;

export function usePolling(
  loadData: () => Promise<void>,
  doPoll: () => boolean = () => true,
  delay: number = DEFAULT_DELAY
) {
  const polling = ref(false);

  const poll = async () => {
    if (polling.value) return;
    if (!doPoll()) return;

    polling.value = true;
    await loadData();
    polling.value = false;
  };

  const start = () => {
    const interval = setInterval(poll, delay);
    onUnmounted(() => clearInterval(interval));
  };

  return { polling, start };
}
