<template>
  <q-input
    ref="inputEl"
    v-model="stringValue"
    :mask="mask"
    :placeholder="placeholder"
    clearable
    :disable="disable"
    v-bind="$attrs"
  >
    <template v-slot:append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
          <q-date v-model="isoStringValue">
            <div class="row items-center justify-end">
              <q-btn v-close-popup label="Close" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script setup lang="ts">
import dateFormatStrings from "@/i18n/dateFormatStrings";
import { format, parse } from "date-fns";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  modelValue: Date | null | undefined;
  disable?: boolean;
}>();
const emit = defineEmits<{
  "update:model-value": [date: Date | null | undefined];
}>();

const { locale } = useI18n();
const dateFormat = computed<string | undefined>(
  () => dateFormatStrings[locale.value as keyof typeof dateFormatStrings]
);

const stringValue = computed<string | null>({
  get: () => {
    if (!props.modelValue) return null;
    if (!dateFormat.value)
      throw new Error("No date format found for locale " + locale.value);
    return format(props.modelValue, dateFormat.value);
  },
  set: (value) => {
    if (!value) {
      emit("update:model-value", null);
      return;
    }
    if (!dateFormat.value)
      throw new Error("No date format found for locale " + locale.value);

    if (value.length < dateFormat.value.length) {
      // input not finished
      return;
    }

    const date = parse(value, dateFormat.value, new Date());
    if (!date || date.getFullYear() < 1900) {
      // input not finished
      return;
    }
    emit("update:model-value", date);
  },
});

const mask = computed(() => {
  if (!dateFormat.value) return undefined;
  return dateFormat.value.replace(/[a-zA-Z]/g, "#");
});

const placeholder = computed(() => {
  if (!dateFormat.value) return undefined;
  return dateFormat.value.replace(/[a-zA-Z]/g, "_");
});

const isoFormat = "yyyy/MM/dd";

const isoStringValue = computed<string | null>({
  get: () => {
    if (!props.modelValue) return null;
    return format(props.modelValue, isoFormat);
  },
  set: (value) => {
    if (!value) {
      emit("update:model-value", null);
      return;
    }
    const date = parse(value, isoFormat, new Date());
    emit("update:model-value", date);
  },
});
</script>

<style scoped lang="scss">
.date-input-el {
  width: 100%;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}
</style>
