export default {
  ID: "ID",
  File: "Datei",
  Received: "Empfangen",
  Text: "Text",
  Status: "Status",
  "Assigned to": "Zugewiesen an",
  "documents selected": "{n} Dokumente ausgewählt",
  "leads selected": "{n} Anfragen ausgewählt",
  Warnings: "Warnungen",
  Filenames: "Dateien",
  Texts: "Texte",
  "Overall status": "Status",
  Sender: "Absender",
  Subject: "Betreff",
  Attachments: "Anhänge",
};
