// Render confidence value as a percentage
export function confidenceToPercentage(
  confidence: number | null | undefined
): string {
  if (confidence === null || confidence === undefined) {
    return "0%";
  }
  if (confidence > 0.99) {
    return ">99%";
  }
  return Math.round(confidence * 100) + "%";
}
