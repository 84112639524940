<template>
  <q-menu
    ref="menu"
    :model-value="isVisible"
    no-parent-event
    no-focus
    no-refocus
    fit
    class="product-search-menu"
    :style="{
      'min-height': `${shownProducts.length * 40}px`,
      'max-height': 'calc(100dvh - 96px)',
    }"
  >
    <q-linear-progress v-if="isLoading" indeterminate />
    <template v-if="props.searchText && props.searchText.length >= 3">
      <q-list
        v-if="foundProducts.length"
        dense
        separator
        :style="{
          'max-height': `${MAX_NUM_PRODUCTS_SHOWN_IN_DROPDOWN * 40}px`,
        }"
      >
        <q-item
          v-for="(product, idx) in foundProducts"
          :key="product.id"
          :active="idx === selectedPosition"
          clickable
          active-class="bg-neutral-2 text-neutral-10"
          @click.capture.stop.prevent="$emit('select', product)"
        >
          <q-item-section>
            <q-item-label>
              <highlight-text :text="product.name" :search-text="searchText" />
            </q-item-label>
            <q-item-label caption>
              <highlight-text
                :text="product.externalId"
                :search-text="searchText"
              />
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
      <div
        v-else-if="!isLoading"
        class="q-px-md q-py-sm full-width text-center"
      >
        {{ $t("productSearchMenu.noResults") }}
      </div>
    </template>
    <template v-else-if="props.searchText">
      <q-list dense>
        <q-item>
          <q-item-section>
            <q-item-label>
              {{
                $t("productSearchMenu.enterAtLeastNCharacters", {
                  n: MIN_SEARCH_TEXT_LENGTH,
                })
              }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </template>
    <template v-else>
      <!-- no search text -->
      <q-list v-if="productCandidates.length" dense separator>
        <q-item
          v-for="(candidate, idx) in productCandidates"
          :key="candidate.id"
          :active="idx === selectedPosition"
          active-class="bg-neutral-2 text-neutral-10"
          clickable
          @click.capture.stop.prevent="$emit('select', candidate.product)"
        >
          <q-item-section>
            <q-item-label>{{ candidate.product.name }}</q-item-label>
            <q-item-label caption>{{
              candidate.product.externalId
            }}</q-item-label>
          </q-item-section>
          <q-item-section side right>
            <confidence-icon :confidence="candidate.confidence" />
          </q-item-section>
        </q-item>
      </q-list>
    </template>
  </q-menu>
</template>

<script setup lang="ts">
import { findProducts } from "@/api/product";
import HighlightText from "@/components/HighlightText.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import {
  MAX_NUM_PRODUCTS_SHOWN_IN_DROPDOWN,
  NUM_PRODUCTS_TO_RETRIEVE_IN_ADVANCE,
} from "@/config/constants";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import type { Product } from "@/types/product";
import { QMenu } from "quasar";
import debounce from "debounce-promise";

import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import ConfidenceIcon from "./ConfidenceIcon.vue";

const MIN_SEARCH_TEXT_LENGTH = 3;

const props = defineProps<{
  offerPositionGroup: OfferPositionGroup;
  searchText: string;
  isActive: boolean;
}>();

const emit = defineEmits<{
  select: [Product];
}>();

const { organizationId } = useRouteParams();

const menu = ref<QMenu | null>(null);

const foundProducts = ref<Product[]>([]);
let resultsSearchText = "";

const productCandidates = computed(() =>
  props.offerPositionGroup.productCandidates.filter((c) =>
    props.offerPositionGroup.offerPositions.every(
      (p) => p.product?.id !== c.product.id
    )
  )
);

const shownProducts = computed(() =>
  props.searchText
    ? foundProducts.value
    : productCandidates.value.map((c) => c.product)
);

const isLoading = ref(false);
const isVisible = computed(
  () =>
    props.isActive &&
    (props.searchText.length > 0 || productCandidates.value.length > 0)
);
const selectedPosition = ref(0);

async function searchProducts() {
  const searchText = props.searchText;

  if (searchText.length < MIN_SEARCH_TEXT_LENGTH) {
    foundProducts.value = [];
    return;
  }

  isLoading.value = true;
  try {
    foundProducts.value = await findProducts(
      organizationId.value,
      searchText,
      NUM_PRODUCTS_TO_RETRIEVE_IN_ADVANCE
    );
    selectedPosition.value = 0;
    resultsSearchText = searchText;
  } finally {
    isLoading.value = false;
    // Make sure the menu is repositioned after the search results are updated
    await nextTick();
    menu.value?.updatePosition();
  }
}
watch(
  () => props.searchText,
  debounce(searchProducts, 500, { leading: true }),
  {
    immediate: true,
  }
);

onMounted(() => {
  window.addEventListener("keydown", handleWindowKeydown, true);
});
onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleWindowKeydown, true);
});

function handleWindowKeydown(event: KeyboardEvent) {
  if (!props.isActive) return;
  if (!shownProducts.value.length) return;
  if (resultsSearchText !== props.searchText) return; // search results are outdated

  if (noModifierKeys(event) && event.key === "ArrowDown") {
    selectNext();
    event.preventDefault();
    event.stopPropagation();
  } else if (noModifierKeys(event) && event.key === "ArrowUp") {
    selectPrevious();
    event.preventDefault();
    event.stopPropagation();
  } else if (noModifierKeys(event) && ["Enter", "Tab"].includes(event.key)) {
    emit("select", shownProducts.value[selectedPosition.value]);
    event.preventDefault();
    event.stopPropagation();
  }
}

function selectNext() {
  selectedPosition.value =
    (selectedPosition.value + 1) % shownProducts.value.length;
}

function selectPrevious() {
  selectedPosition.value =
    (selectedPosition.value - 1 + shownProducts.value.length) %
    shownProducts.value.length;
}

function noModifierKeys(event: KeyboardEvent) {
  return !event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey;
}
</script>

<style scoped lang="scss">
.product-search-menu {
  font-size: smaller;
}
</style>
