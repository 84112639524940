<template>
  <pdf-viewer
    :file="file"
    :highlights="displayHighlights"
    :aids="aids"
    :ocr-result="null"
  >
    <template #highlight="{ highlight, rotation, canvasSize }">
      <display-highlight
        :ref="
          (el) =>
            registerDisplayHighlightEl(
              el as typeof DisplayHighlight | null,
              highlight.offerPositionGroup.spotlightId,
              highlight.isFirst
            )
        "
        :bounding-region="highlight.boundingRegion"
        :group="highlight.offerPositionGroup"
        :canvas-size="canvasSize"
        :page-rotation="rotation"
        @click="
          events.emit(
            'selectFromDocument',
            highlight.offerPositionGroup.spotlightId
          )
        "
        :is-selected="
          selectedPositionIds.includes(highlight.offerPositionGroup.spotlightId)
        "
      />
    </template>
    <template #aid="{ aid, rotation, canvasSize }">
      <reading-aid
        :bounding-region="aid.boundingRegion"
        :group="aid.offerPositionGroup"
        :canvas-size="canvasSize"
        :page-rotation="rotation"
        :is-selected="
          selectedPositionIds.includes(aid.offerPositionGroup.spotlightId)
        "
      />
    </template>
    <template #focus-overlay="{ highlights }">
      <focus-overlay
        :highlights="
          highlights.filter((highlight) =>
            selectedPositionIds.includes(
              highlight.offerPositionGroup.spotlightId
            )
          )
        "
        :show="selectedPositionIds.length > 0"
        @click="events.emit('clearSelection')"
      />
    </template>
  </pdf-viewer>
</template>

<script setup lang="ts">
import ReadingAid from "@/components/PdfDisplay/ReadingAid.vue";
import { usePositionsEvents } from "@/composables/usePositionsEvents";
import { useSelectedPositionIds } from "@/composables/useSelectedPositionIds";
import { MAX_WAIT_FOR_DISPLAY_HIGHLIGHT_MS } from "@/config/constants";
import type { Document } from "@/types/document";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { waitFor } from "@/utils/waitFor";
import { computed, nextTick } from "vue";
import PdfViewer from "../PdfViewer/PdfViewer.vue";
import DisplayHighlight from "./DisplayHighlight.vue";
import FocusOverlay from "./FocusOverlay.vue";

const props = defineProps<{
  document: Document;
  offerPositionGroups: OfferPositionGroup[];
  file: File;
}>();

let displayHighlightEls: Record<string, typeof DisplayHighlight | null> = {};
const registerDisplayHighlightEl = (
  el: typeof DisplayHighlight | null,
  spotlightId: string,
  isFirst: boolean
) => {
  if (!el) return;
  if (!isFirst) return; // only register the first highlight of a group for scroll into view
  displayHighlightEls[spotlightId] = el;
};

const displayHighlights = computed(() =>
  props.offerPositionGroups.flatMap((offerPositionGroup) =>
    offerPositionGroup.boundingBoxes.map((boundingBox, idx) => ({
      boundingRegion: boundingBox,
      offerPositionGroup,
      isFirst: idx === 0,
    }))
  )
);

const aids = computed(() =>
  props.offerPositionGroups.flatMap((offerPositionGroup) =>
    offerPositionGroup.highlightPolygons.map((boundingBox) => ({
      boundingRegion: boundingBox,
      offerPositionGroup,
    }))
  )
);

async function scrollToHighlight(spotlightId: string | null) {
  if (!spotlightId) return;

  const group = props.offerPositionGroups.find(
    (group) => group.spotlightId === spotlightId
  );
  if (!group) return;
  const groupId = group.spotlightId;
  // The display highlight ref may not be available yet, because a different document is
  // currently being displayed. Wait for this document to load and the ref to be available.
  const displayHighlightEl = await waitFor(
    () => displayHighlightEls[groupId],
    MAX_WAIT_FOR_DISPLAY_HIGHLIGHT_MS
  );
  if (!displayHighlightEl) {
    console.warn(`Wait for highlight element ${groupId} timed out.`);
    return;
  }
  // wait for the display highlight el to be rendered
  await nextTick();
  displayHighlightEl.scrollIntoView();
}

defineExpose({ scrollToHighlight });

const selectedPositionIds = useSelectedPositionIds();

const events = usePositionsEvents();
</script>
