import { inflightRequests } from "@/api/client/inflightRequests";
import { onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

export function useConfirmUnsavedChanges() {
  const { t } = useI18n();

  let eventListener: any;

  onMounted(() => {
    eventListener = (e: BeforeUnloadEvent) => {
      if (inflightRequests > 0) {
        e.preventDefault();
        // Typically, you need to set returnValue to get the prompt
        e.returnValue = t("Changes that you made may not be saved.");
      }
    };
    window.addEventListener("beforeunload", eventListener);
  });

  onUnmounted(() => {
    window.removeEventListener("beforeunload", eventListener);
  });
}
