<template>
  <router-link :to="to" class="sidebar-menu-item-router-link">
    <div class="row no-wrap full-width">
      <div v-if="!inset" class="sidebar-menu-item-drag-handle-container" />
      <div v-if="inset" class="sidebar-menu-item-avatar">
        <div class="sidebar-menu-item-avatar-inset" />
      </div>
      <q-item
        dense
        clickable
        v-ripple
        class="col sidebar-menu-item no-focus-helper"
        :class="{
          'active-sidebar-menu-item': showActive,
        }"
        v-bind="$attrs"
      >
        <q-item-section>
          <div class="row no-wrap full-width">
            <div
              v-if="!inset"
              class="sidebar-menu-item-avatar row items-center no-wrap"
            >
              <slot name="avatar" />
            </div>
            <div class="sidebar-menu-item-label ellipsis" :title="label">
              {{ label }}
            </div>
            <q-space />
            <q-badge
              v-if="unreadCount"
              color="neutral-4"
              text-color="neutral-10"
            >
              {{ unreadCount }}
            </q-badge>
          </div>
        </q-item-section>
      </q-item>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useLink, useRouter, type RouteLocationRaw } from "vue-router";

const props = defineProps<{
  to: RouteLocationRaw;
  label: string;
  isActive?: (currentRoute: RouteLocationRaw) => boolean;
  unreadCount?: number;
  inset?: boolean;
}>();

const { currentRoute } = useRouter();

const isActiveRoute = useLink({ to: props.to }).isActive;

const showActive = computed(() => {
  if (props.isActive) {
    return props.isActive(currentRoute.value);
  }
  return isActiveRoute.value;
});
</script>

<style lang="scss">
.sidebar-menu-item:hover:not(.active-sidebar-menu-item) {
  background-color: $neutral-3;
}

.sidebar-menu-item-router-link {
  text-decoration: inherit;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.active-sidebar-menu-item {
  background-color: $neutral-4;

  &.white-bg {
    background-color: $neutral-4;
  }
}

.sidebar-menu-item-avatar-inset {
  width: 1px;
  margin-left: 12px;
  height: 100%;
  background-color: $neutral-5;
}
</style>
