<template>
  <q-btn
    dense
    flat
    :disable="
      disabled ||
      (!isStatusSelectable && status?.originalStatus !== 'ANALYSIS_ERROR')
    "
    class="status-select-button"
  >
    <status-chip
      v-if="status !== null"
      :status="status"
      :icon-only="iconOnly"
    />
    <q-avatar
      v-else
      icon="sym_r_pending"
      size="sm"
      color="neutral-8"
      text-color="white"
    />
    <select-menu
      v-if="isStatusSelectable"
      :selected-option="(status as InquiryStatusWithLabel | null)"
      :options="statusOptions"
      :label-fn="(status) => $t(status.name)"
      :search-values="['label']"
      id-value="name"
      :search-placeholder="$t('Search status')"
      @select="updateStatus"
    >
      <template #option-label="{ option }">
        <status-chip :status="option" />
      </template>
    </select-menu>
    <q-menu v-if="status?.originalStatus === 'ANALYSIS_ERROR'">
      <q-item clickable dense>
        <q-item-section @click="$emit('retryAnalysis')" v-close-popup>
          {{ $t("statusSelectButton.retryAnalysis") }}
        </q-item-section>
      </q-item>
    </q-menu>
    <q-tooltip v-if="iconOnly">
      {{ status === null ? $t("Change status") : $t(status.name) }}
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import SelectMenu from "@/components/SelectMenus/SelectMenu.vue";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import type { InquiryStatus } from "@/types/inquiry";
import { CHANGEABLE_BASE_INQUIRY_STATUSES } from "@/types/inquiry";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import StatusChip from "./StatusChip.vue";

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    status: InquiryStatus | null;
    iconOnly?: boolean;
  }>(),
  {
    disabled: false,
    iconOnly: false,
  }
);

const emit = defineEmits<{
  "update:status": [status: InquiryStatus];
  retryAnalysis: [];
}>();

const { inbox } = storeToRefs(useCurrentInboxStore());

type InquiryStatusWithLabel = InquiryStatus & { label: string };

const { t } = useI18n();
const statusOptions = computed<InquiryStatusWithLabel[]>(
  () =>
    inbox.value?.availableStatuses
      .filter((s) => s.isSelectable)
      .map((status) => ({
        ...status,
        label: t(status.name),
      })) ?? []
);

const isStatusSelectable = computed(() =>
  props.status === null
    ? true
    : CHANGEABLE_BASE_INQUIRY_STATUSES.includes(props.status.originalStatus)
);

function updateStatus(status: InquiryStatus) {
  emit("update:status", status);
}
</script>

<style lang="scss">
.status-select-button {
  // don't decorate content
  font-weight: inherit;
}
</style>
