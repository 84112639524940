<template>
  <div class="dialog q-pa-md flex column no-wrap items-stretch bg-white gap-sm">
    <div class="flex row items-start justify-between no-wrap">
      <div>
        <div class="text-neutral-7 text-2xs">
          {{ $t("inquiryPositionsPage.productVariantConfiguratorPopup.title") }}
        </div>
        <div class="text-bold">{{ position.product.name }}</div>
      </div>
      <q-btn
        flat
        dense
        icon="sym_r_close"
        color="neutral-7"
        @click="$emit('close')"
      />
    </div>
    <div class="flex column gap-sm pre-wrap notes">
      {{ position.notes }}
    </div>
    <div class="relative col-grow iframe-wrapper">
      <iframe :src="iframeUrl" class="fit iframe" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OfferPosition } from "@/types/offerPosition";
import type { OfferPositionGroup } from "@/types/offerPositionGroup";
import { storeToRefs } from "pinia";
import { computed, onMounted, onUnmounted } from "vue";

const props = defineProps<{
  group: OfferPositionGroup;
  position: OfferPosition;
  variant: string;
}>();

const { organization } = storeToRefs(useCurrentOrganizationStore());
const { updateVariantConfiguration } = useCurrentOfferPositionGroupsStore();

const iframeUrl = computed(() => {
  const variant = props.variant.length
    ? props.variant
    : props.position.product.externalId; // use product externalId as default variant if no variant is provided
  return organization.value?.variantConfiguratorUrl?.replace(
    ":variant",
    variant
  );
});

const emit = defineEmits(["close"]);

async function updateConfiguration(variant: string, addonKeys?: string[]) {
  const addons =
    addonKeys?.map((addonKey) => ({
      product: getProduct(addonKey),
      variant: addonKey,
    })) || [];

  updateVariantConfiguration(
    props.group.id,
    props.position.id,
    variant,
    addons
  );
}

function getProduct(addonKey: string) {
  return addonKey.split("-")[0];
}

async function handleWindowMessageEvent(event: MessageEvent) {
  const { data, origin } = event;

  if (!iframeUrl.value || !iframeUrl.value.startsWith(origin)) return;
  if (data.type !== "product_configuration") return;
  if (data.event !== "configuration_finished") return;

  await updateConfiguration(data.variantKey, data.addonKeys);

  emit("close");
}

onMounted(() => {
  window.addEventListener("message", handleWindowMessageEvent);
});

onUnmounted(() => {
  window.removeEventListener("message", handleWindowMessageEvent);
});
</script>

<style scoped lang="scss">
.dialog {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.notes {
  line-height: 120%;
}

.iframe-wrapper {
  min-height: 600px;
}

.iframe {
  border-style: solid;
  border-color: $neutral-3;
  border-width: 1px;
  border-radius: 4px;
}
</style>
