<template>
  <template v-if="entry.type == 'CREATED' || entry.type == 'CREATED_AS_COPY'">
    <user-avatar :user="entry.user" v-if="entry.user" />
    <div class="avatar" v-else>
      <q-icon name="sym_r_add" />
    </div>
  </template>
  <template v-if="entry.type == 'STATUS_CHANGED'">
    <status-chip :status="entry.extraData.newStatus" :icon-only="true" />
  </template>
  <template v-if="entry.type == 'EXPORTED'">
    <div class="avatar">
      <q-icon name="sym_r_exit_to_app" />
    </div>
  </template>
  <template v-if="entry.type == 'FINALIZED'">
    <status-chip
      v-if="finalizedStatus"
      :status="finalizedStatus"
      :icon-only="true"
    />
    <div v-else class="avatar">
      <q-icon name="sym_r_check" />
    </div>
  </template>
  <template v-if="entry.type == 'MOVED_TO_ANOTHER_INBOX'">
    <div class="avatar">
      <q-icon name="sym_r_arrow_right_alt" />
    </div>
  </template>
  <template v-if="entry.type == 'COPIED'">
    <div class="avatar">
      <q-icon name="sym_r_content_copy" />
    </div>
  </template>
  <template v-if="entry.type == 'ASSIGNED_USER'">
    <user-avatar :user="entry.extraData.newAssignedUser" />
  </template>
  <template v-if="entry.type == 'COMMENT'">
    <div class="avatar">
      <q-icon name="sym_r_comment" />
    </div>
  </template>
</template>

<script setup lang="ts">
import UserAvatar from "@/components/User/UserAvatar.vue";
import type { InquiryHistoryEntry as InquiryHistoryEntryType } from "@/types/inquiryHistory";
import StatusChip from "@/components/Status/StatusChip.vue";
import { storeToRefs } from "pinia";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { computed } from "vue";

const props = defineProps<{
  entry: InquiryHistoryEntryType;
}>();

const { inbox } = storeToRefs(useCurrentInboxStore());

const finalizedStatus = computed(() => {
  if (props.entry.type !== "FINALIZED") return null;

  return inbox.value?.availableStatuses.find(
    (status) => status.name === "FINALIZED"
  );
});
</script>

<style scoped lang="scss">
$avatar-color: $neutral-7;

.avatar {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 1px solid $avatar-color;
  color: $avatar-color;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
</style>
