<template>
  <div class="column no-wrap items-stretch">
    <div class="col">
      <template v-if="isLoading">
        <template v-for="i in 10" :key="i">
          <inquiry-row-skeleton />
        </template>
      </template>
      <template v-else>
        <template v-for="(quotation, idx) in quotations" :key="quotation.id">
          <q-separator v-if="idx > 0" />
          <quotations-table-row :quotation="quotation" />
        </template>
      </template>
    </div>
    <div class="footer q-px-md flex row no-wrap items-center justify-end">
      <table-pagination
        v-model:page-size="pageSize"
        :page="pageNumber"
        :total-items="numResults ?? 0"
        @nextPage="store.goToNextPage"
        @previousPage="store.goToPreviousPage"
        @update:pageSize="store.setPageSize"
        :rows-per-page-label="$t('quotationsPage.pagination.rowsPerPage')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCurrentQuotationsStore } from "@/stores/currentQuotations";
import { storeToRefs } from "pinia";
import TablePagination from "@/components/TablePagination.vue";
import InquiryRowSkeleton from "@/components/InquiriesTable/InquiryRowSkeleton.vue";
import QuotationsTableRow from "./QuotationsTableRow.vue";

const store = useCurrentQuotationsStore();

const { quotations, isLoading, pageSize, pageNumber, numResults } =
  storeToRefs(store);
</script>

<style scoped lang="scss">
.footer {
  height: 48px;
  border-top: 1px solid $separator-color;
  z-index: 10;
  flex-shrink: 0;
}
</style>
