<template>
  <ocr-text-selection-provider :ocr-result="ocrResult">
    <pdf-page
      v-for="[page, ocrPage] in pagesWithOcr"
      :key="page.pageNumber"
      :page="page"
      :scale="scale"
      :rotation="rotation"
      :scroll-parent="props.scrollParent"
      class="pdf-page"
    >
      <template #ocr-text-layer="{ canvasSize }">
        <ocr-text-layer
          v-if="ocrPage"
          :ocr-page="ocrPage"
          :canvas-size="canvasSize"
          :page-rotation="props.rotation"
        />
      </template>
      <template #highlights="{ canvasSize }">
        <div class="pdf-highlights-wrapper">
          <slot
            v-for="highlight in highlightsByPage[page.pageNumber] || []"
            name="highlight"
            :highlight="highlight"
            :canvasSize="canvasSize"
          />
        </div>
      </template>
      <template #aids="{ canvasSize }">
        <div class="pdf-aids-wrapper">
          <slot
            v-for="aid in aidsByPage[page.pageNumber] || []"
            name="aid"
            :aid="aid"
            :canvasSize="canvasSize"
          />
        </div>
      </template>
      <template #focus-overlay>
        <slot
          name="focus-overlay"
          :highlights="highlightsByPage[page.pageNumber] || []"
        />
      </template>
    </pdf-page>
  </ocr-text-selection-provider>
</template>

<script setup lang="ts">
import type { OCRPage, OCRResult } from "@/types/ocrResult";
import type { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import { computed, onMounted, shallowRef, watch } from "vue";
import OcrTextLayer from "./OcrTextLayer.vue";
import OcrTextSelectionProvider from "./OcrTextSelectionProvider.vue";
import PdfPage from "./PdfPage.vue";
import type { DisplayHighlight, AidHighlight } from "./types";
import {
  getFirstPageViewport,
  getPagesFromPdf,
  getPdfFromFile,
} from "./utils/pdf";
import { getFullPageScale, getFullWidthScale } from "./utils/sizing";
import type { Polygon } from "@/types/boundingRegion";

const props = defineProps<{
  file: File;
  scale: number;
  rotation: number;
  highlights: DisplayHighlight[];
  aids: AidHighlight[];
  ocrResult: OCRResult | null;
  scrollParent: HTMLElement;
  initialScale: "full-width" | "full-page";
}>();
const emit = defineEmits([
  "document-errored",
  "update-scale",
  "update-num-pages",
]);
defineExpose({ setFullWidthScale, setFullPageScale });

const highlightsByPage = computed(() =>
  props.highlights.reduce((acc, highlight) => {
    const { page, polygon } = extractPageNo(highlight.boundingRegion);
    if (!acc[page]) {
      acc[page] = [];
    }
    acc[page].push({ ...highlight, boundingRegion: polygon });
    return acc;
  }, {} as Record<number, DisplayHighlight[]>)
);

const aidsByPage = computed(() =>
  props.aids.reduce((acc, aid) => {
    const { page, polygon } = extractPageNo(aid.boundingRegion);
    if (!acc[page]) {
      acc[page] = [];
    }
    acc[page].push({ ...aid, boundingRegion: polygon });
    return acc;
  }, {} as Record<number, AidHighlight[]>)
);

const pdf = shallowRef<PDFDocumentProxy | undefined>(undefined);
const pages = shallowRef<PDFPageProxy[]>([]);

const pagesWithOcr = computed(() =>
  pages.value.map((page, idx): [PDFPageProxy, OCRPage?] => [
    page,
    props.ocrResult?.pageLayouts && props.ocrResult.pageLayouts.length > idx
      ? props.ocrResult.pageLayouts[idx]
      : undefined,
  ])
);

function extractPageNo(polygonWithPage: Polygon) {
  const page = Math.floor(polygonWithPage[0][1]);
  const polygon = polygonWithPage.map(([x, y]) => [x, y === 1 ? 1 : y % 1]);
  return { page, polygon: polygon as Polygon };
}

watch(() => props.file, fetchDocument);
onMounted(fetchDocument);

async function fetchDocument() {
  try {
    pdf.value = await getPdfFromFile(props.file);
    pages.value = await getPagesFromPdf(pdf.value);
    emit("update-num-pages", pages.value.length);
  } catch (e) {
    console.error("Failed to load PDF", e);
    emit("document-errored", { text: "Failed to load PDF", e });
  }
}

watch(pages, () => {
  if (props.initialScale === "full-width") {
    setFullWidthScale();
  } else if (props.initialScale === "full-page") {
    setFullPageScale();
  }
});

function setFullWidthScale() {
  if (!props.scrollParent) return;
  const firstPageViewport = getFirstPageViewport(pages.value);
  const newScale = getFullWidthScale(
    firstPageViewport,
    props.scrollParent,
    props.rotation
  );
  emit("update-scale", newScale);
}

function setFullPageScale() {
  if (!props.scrollParent) return;
  const firstPageViewport = getFirstPageViewport(pages.value);
  const newScale = getFullPageScale(
    firstPageViewport,
    props.scrollParent,
    props.rotation
  );
  emit("update-scale", newScale);
}
</script>

<style lang="scss">
.pdf-highlights-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
}
</style>
