<template>
  <pdf-viewer
    v-if="documentFile && firstDocument?.fileType == 'PDF'"
    :file="documentFile"
    class="fit"
  />
  <gaeb-viewer
    v-if="firstDocument?.fileType == 'GAEB' && firstDocument.gaebDocumentId"
    :document="firstDocument"
    class="fit"
  />
</template>

<script setup lang="ts">
import { getDocumentFile } from "@/api/document";
import PdfViewer from "@/components/PdfViewer/PdfViewer.vue";
import GaebViewer from "@/components/GaebViewer/GaebViewer.vue";
import type { Inbox } from "@/types/inbox";
import type { Inquiry } from "@/types/inquiry";
import type { Document } from "@/types/document";
import { ref, watch } from "vue";

const props = defineProps<{
  inbox: Inbox;
  inquiry: Inquiry;
}>();

const documentFile = ref<File | null>(null);
const firstDocument = ref<Document | null>(null);

async function loadData() {
  if (!props.inquiry.documents.length) return;

  firstDocument.value = props.inquiry.documents[0];

  if (firstDocument.value.fileType === "PDF") {
    const result = await getDocumentFile(
      props.inbox.id,
      props.inquiry.id,
      firstDocument.value.id
    );
    if (!result) return;
    documentFile.value = result;
  }
}
watch(
  () => props.inquiry.id,
  async () => {
    documentFile.value = null;
    await loadData();
  },
  { immediate: true }
);
</script>
