<template>
  <div class="loading-indicator-container">
    <q-linear-progress v-if="showLoading" indeterminate color="neutral-5" />
  </div>
  <div class="full-height column no-wrap">
    <div class="col full-width column no-wrap q-px-md gap-sm">
      <div class="column no-wrap">
        <multi-email-input
          :disable="disableInput"
          :emails="supplierRfq?.recipientsTo ?? []"
          :contacts="supplierContactsEmails"
          :label="$t('supplierRfq.recipientsTo')"
          @update:emails="update({ recipientsTo: $event })"
        />

        <multi-email-input
          :disable="disableInput"
          :emails="supplierRfq?.recipientsCc ?? []"
          :contacts="supplierContactsEmails"
          :label="$t('supplierRfq.recipientsCc')"
          @update:emails="update({ recipientsCc: $event })"
        />

        <multi-email-input
          :disable="disableInput"
          :emails="supplierRfq?.recipientsBcc ?? []"
          :contacts="supplierContactsEmails"
          :label="$t('supplierRfq.recipientsBcc')"
          @update:emails="update({ recipientsBcc: $event })"
        />
      </div>
      <q-separator />
      <input
        :value="supplierRfq?.subject"
        class="input-no-decoration subject-input"
        :placeholder="$t('supplierRfq.subject')"
        @blur="(e) => update({ subject: (e.target as HTMLInputElement).value })"
        :disabled="disableInput"
      />
      <q-separator />
      <div class="col q-py-md">
        <q-editor
          class="full-height supplier-rfq-body-editor"
          :model-value="supplierRfq?.body || ''"
          :disable="disableInput"
          :placeholder="`${$t('supplierRfq.body')}`"
          @update:model-value="update({ body: $event })"
        />
      </div>
    </div>
    <q-separator />
    <div class="full-width column no-wrap q-pa-md gap-md bg-neutral-1">
      <supplier-rfqs-attachment-bar
        :supplier-rfq="supplierRfq"
        :disable="disableInput"
        @update:attach-original-boq="update({ attachOriginalBoq: $event })"
      />
    </div>
    <div class="full-width column no-wrap q-pa-md gap-md bg-neutral-1">
      <div class="row no-wrap items-end justify-between">
        <date-input
          dense
          outlined
          label="Abgabetermin"
          :model-value="supplierRfq?.dueDate"
          :disable="disableInput"
          @update:model-value="update({ dueDate: $event })"
        />
        <q-btn
          color="primary"
          icon="sym_r_send"
          :disable="disableInput"
          @click="send"
          v-if="supplierRfq?.status === 'DRAFT'"
        >
          <q-tooltip anchor="top right" self="bottom right" :offset="[0, 5]">{{
            $t("supplierRfqsPage.send")
          }}</q-tooltip>
        </q-btn>
        <q-btn
          color="positive"
          :icon="icons.supplierRfqStatus.ANSWERED"
          @click="markAsAnswered"
          v-else-if="supplierRfq?.status === 'SENT'"
        >
          <q-tooltip anchor="top right" self="bottom right" :offset="[0, 5]">{{
            $t("supplierRfqsPage.markAsAnswered")
          }}</q-tooltip>
        </q-btn>
        <q-btn
          color="neutral-7"
          :icon="icons.supplierRfqStatus.SENT"
          @click="markAsUnanswered"
          v-else-if="supplierRfq?.status === 'ANSWERED'"
        >
          <q-tooltip anchor="top right" self="bottom right" :offset="[0, 5]">{{
            $t("supplierRfqsPage.markAsUnanswered")
          }}</q-tooltip>
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DateInput from "@/components/DateInput.vue";
import MultiEmailInput from "@/components/SupplierRfqsPage/MultiEmailInput.vue";
import SupplierRfqsAttachmentBar from "@/components/SupplierRfqsPage/SupplierRfqsAttachmentBar.vue";
import { delayLoadingIndicator } from "@/composables/useLoadingIndicator";
import { useRouteParams } from "@/composables/useRouteParams";
import icons from "@/config/icons";
import { useCurrentSupplierStore } from "@/stores/currentSupplier";
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import type { SupplierRFQ } from "@/types/supplierRfq";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
const { supplierRfqId } = useRouteParams();

const supplierRfq = computed(() =>
  supplierRfqs.value.find((rfq) => rfq.id === supplierRfqId.value)
);
const isLoading = ref(false);
const showLoading = delayLoadingIndicator(isLoading);
const disableInput = computed(() => supplierRfq.value?.status !== "DRAFT");

const { supplier } = storeToRefs(useCurrentSupplierStore());

const supplierContactsEmails = computed(
  () =>
    supplier.value?.contacts.map((contact) => ({
      address: contact.address,
      displayName:
        contact.firstName || contact.lastName
          ? [contact.firstName, contact.lastName].filter(Boolean).join(" ")
          : undefined,
    })) ?? []
);

const store = useCurrentSupplierRfqsStore();
const { updateSupplierRfq } = store;
const { supplierRfqs } = storeToRefs(store);

async function update(data: Partial<SupplierRFQ>) {
  await updateSupplierRfq(supplierRfqId.value, data);
}

async function send() {
  await store.sendSupplierRfq(supplierRfqId.value);
}

async function markAsAnswered() {
  await store.updateSupplierRfq(supplierRfqId.value, {
    status: "ANSWERED",
  });
}

async function markAsUnanswered() {
  await store.updateSupplierRfq(supplierRfqId.value, {
    status: "SENT",
  });
}
</script>

<style scoped lang="scss">
.loading-indicator-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.subject-input {
  background: transparent;
}
</style>

<style lang="scss">
.supplier-rfq-body-editor {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;

  .q-editor__toolbar {
    flex-shrink: 0;
  }

  .q-editor__content {
    flex-grow: 1;
    overflow-y: auto;
  }
}
</style>
