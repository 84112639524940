import { formatDistanceToNow as dateFnsFormat } from "date-fns";
import { dateFnsLocales } from "./dateFnsLocales";
import { useI18n } from "vue-i18n";

export function formatDistanceToNow(
  date: Date,
  locale: string | undefined = undefined
) {
  if (isNaN(date.getTime()))
    throw new Error("Trying to format distance to now for an invalid date");

  if (!locale) {
    locale = useI18n().locale.value;
  }

  const dateFnsLocale = dateFnsLocales[locale];

  if (locale.toLowerCase().startsWith("de")) {
    return dateFnsFormat(date, { locale: dateFnsLocale, addSuffix: true });
  }
}
