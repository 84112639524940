<template>
  <div class="flex column gap-xs no-wrap text-xs">
    <inquiry-date-display
      v-if="show.requestedCustomerSubmissionDate"
      :name="$t('inquiry.requestedCustomerSubmissionDate')"
      :date="requestedCustomerSubmissionDate"
      icon="sym_r_event_busy"
      :show-overdue-close="true"
    />
    <inquiry-date-display
      v-if="show.requestedSubmissionDate"
      :name="$t('inquiry.requestedSubmissionDate')"
      :date="requestedSubmissionDate"
      icon="sym_r_calendar_clock"
      :show-overdue-close="true"
    />
    <inquiry-date-display
      v-if="show.createdAt"
      :name="$t('inquiry.createdAt')"
      :date="createdAt"
      icon="sym_r_forward_to_inbox"
      :show-overdue-close="false"
    />
  </div>
</template>

<script setup lang="ts">
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import InquiryDateDisplay from "./InquiryDateDisplay.vue";

defineProps<{
  createdAt: Date;
  requestedSubmissionDate: Date | null;
  requestedCustomerSubmissionDate: Date | null;
}>();

const { inbox } = storeToRefs(useCurrentInboxStore());
const show = computed(() => {
  const setting = inbox.value?.displayDates || "SUBMISSION_CREATED";
  return {
    createdAt: ["SUBMISSION_CREATED", "CUSTOMER_SUBMISSION_CREATED"].includes(
      setting
    ),
    requestedSubmissionDate: [
      "SUBMISSION_CREATED",
      "CUSTOMER_SUBMISSION_SUBMISSION",
    ].includes(setting),
    requestedCustomerSubmissionDate: [
      "CUSTOMER_SUBMISSION_CREATED",
      "CUSTOMER_SUBMISSION_SUBMISSION",
    ].includes(setting),
  };
});
</script>
