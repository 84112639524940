export function downloadAsJson(objs: any[], filename: string) {
  const json = JSON.stringify(objs, null, 2);
  const blob = new Blob([json], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const anchorElement = document.createElement("a");
  anchorElement.download = filename;
  anchorElement.href = url;
  anchorElement.click();
  URL.revokeObjectURL(url);
  anchorElement.remove();
}
