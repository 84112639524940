export type DatetimeFormat =
  | "shortDate"
  | "longDate"
  | "shortDatetime"
  | "longDatetime";

const deDE = {
  shortDate: {
    hourCycle: "h24",
    dateStyle: "short",
  },
  longDate: {
    hourCycle: "h24",
    dateStyle: "long",
  },
  shortDatetime: {
    hourCycle: "h24",
    dateStyle: "short",
    timeStyle: "short",
  },
  longDatetime: {
    hour12: false,
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
};

export default {
  de: deDE,
  "de-DE": deDE,
};
