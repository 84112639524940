import * as Sentry from "@sentry/vue";

export async function executeInNewTrace(fn: () => Promise<void>, name: string) {
  await Sentry.startSpan(
    // create new trace/transaction to prevent very long traces over 30min
    { name: name, forceTransaction: false },
    async () => {
      return await fn();
    }
  );
}
