<template>
  <router-link
    :to="{
      name: 'notification-details',
      params: {
        notificationId: notification.id,
        inboxId: notification.inquiry.inbox,
        inquiryId: notification.inquiry.id,
        inboxView: 'all',
      },
      hash: `#inquiry-history-entry-${notification.inquiryHistory.id}`,
    }"
    class="no-decoration"
  >
    <div
      class="full-width q-pa-sm rounded-borders row no-wrap items-center gap-md notification-item"
      :class="{ 'notification-item-selected': isSelected }"
      @mouseover="isHover = true"
      @mouseleave="isHover = false"
    >
      <inquiry-history-entry-avatar :entry="notification.inquiryHistory" />
      <div class="column no-wrap items-stretch col">
        <div class="row no-wrap justify-between items-baseline">
          <div
            class="ellipsis text-neutral-10"
            :class="{ 'text-bold': !notification.isRead }"
          >
            <span v-if="!notification.isRead">&rsaquo; </span>
            {{ notification.inquiry.shortCode }}
            <span
              v-if="
                notification.inquiry.buildingProject &&
                notification.inquiry.buildingProject.length
              "
            >
              &bull; {{ notification.inquiry.buildingProject }}</span
            >
          </div>
          <div class="text-right text-neutral-7 text-xs text-no-wrap q-ml-sm">
            <relative-date :date="notification.createdAt" />
          </div>
        </div>
        <div class="text-neutral-10 text-xs">{{ notificationText }}</div>
      </div>
      <div v-show="isHover">
        <q-btn
          flat
          dense
          color="neutral-7"
          :icon="
            notification.isRead
              ? 'sym_r_mark_email_unread'
              : 'sym_r_mark_email_read'
          "
          @click.stop.prevent="store.toggleNotificationReadStatus(notification)"
        >
          <q-tooltip>
            {{
              notification.isRead
                ? $t("notificationsPage.markAsUnread")
                : $t("notificationsPage.markAsRead")
            }}
          </q-tooltip>
        </q-btn>
        <router-link
          :to="{
            name: 'inquiry',
            params: {
              inboxId: notification.inquiry.inbox,
              inquiryId: notification.inquiry.id,
              inboxView: 'all',
            },
          }"
          class="no-decoration"
          @click.stop
        >
          <q-btn flat dense color="neutral-7" icon="sym_r_right_panel_open">
            <q-tooltip> {{ $t("notificationsPage.openInquiry") }} </q-tooltip>
          </q-btn>
        </router-link>
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import InquiryHistoryEntryAvatar from "@/components/InquiryPage/InquiryHistory/InquiryHistoryEntryAvatar.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useNotificationsStore } from "@/stores/notifications";
import type { InquiryStatus } from "@/types/inquiry";
import type { InquiryHistoryEntry } from "@/types/inquiryHistory";
import type { Notification } from "@/types/notification";
import type { ShortUser } from "@/types/user";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import RelativeDate from "../RelativeDate.vue";

const props = defineProps<{
  notification: Notification;
}>();

const { t } = useI18n();

const store = useNotificationsStore();

const isHover = ref(false);

const { notificationId } = useRouteParams();
const isSelected = computed(
  () => notificationId.value === props.notification.id
);
watch(
  isSelected,
  (newValue) => {
    if (newValue && props.notification.isRead === false) {
      store.markNotificationAsRead(props.notification);
    }
  },
  { immediate: true }
);

const notificationText = computed(() => {
  const { inquiryHistory } = props.notification;

  const context = createTranslationContext(inquiryHistory);

  if (inquiryHistory.type === "ASSIGNED_USER") {
    const { newAssignedUser } = inquiryHistory.extraData;
    const assignmentType = !newAssignedUser
      ? "none"
      : newAssignedUser.id === inquiryHistory.user.id
      ? "self"
      : "other";
    return t(
      `inquiryPage.inquiryHistory.events.ASSIGNED_USER.${assignmentType}`,
      context
    );
  }

  return t(`inquiryPage.inquiryHistory.events.${inquiryHistory.type}`, context);
});

function createTranslationContext(inquiryHistory: InquiryHistoryEntry) {
  const context: { [index: string]: string } = {
    user: getUserLabel(inquiryHistory.user),
  };

  if (inquiryHistory.type === "STATUS_CHANGED") {
    context.oldStatus = getStatusName(inquiryHistory.extraData.oldStatus);
    context.newStatus = getStatusName(inquiryHistory.extraData.newStatus);
  }

  if (inquiryHistory.type === "MOVED_TO_ANOTHER_INBOX") {
    context.targetInboxName = inquiryHistory.extraData.toInbox.name;
  }

  if (inquiryHistory.type === "ASSIGNED_USER") {
    context.assignedUserName = getUserLabel(
      inquiryHistory.extraData.newAssignedUser
    );
  }

  if (inquiryHistory.type === "COPIED") {
    context.copyShortCode = inquiryHistory.extraData.copyShortCode;
  }

  if (inquiryHistory.type === "CREATED_AS_COPY") {
    context.originalShortCode = inquiryHistory.extraData.originalShortCode;
  }

  return context;
}

function getStatusName(status: InquiryStatus) {
  if (status.custom) return status.name;
  return t(status.name);
}

function getUserLabel(user: ShortUser | null) {
  if (!user) return t("inquiryPage.inquiryHistory.systemUser");
  if (user.firstName || user.lastName)
    return [user.firstName, user.lastName].join(" ");
  return user.email;
}
</script>

<style lang="scss">
.notification-item {
  transition: background-color 0.2s;
  &:hover {
    background-color: $neutral-2;
  }
}
.notification-item-selected {
  background-color: $neutral-2;
}
</style>
