<template>
  <multi-select-menu
    :selected-options="selectedUsers"
    :options="options"
    :label-fn="(user) => user.email"
    :search-values="['email', 'lastName', 'firstName']"
    id-value="email"
    :search-placeholder="$t('Search user')"
    @select="selectUser"
    @unselect="unselectUser"
    @show="store.refresh"
  >
    <template #option-label="{ option }">
      <user-display :user="option" />
    </template>
  </multi-select-menu>
</template>

<script setup lang="ts">
import MultiSelectMenu from "@/components/SelectMenus/MultiSelectMenu.vue";
import { useCurrentInboxUsersStore } from "@/stores/currentInboxUsers";
import { useCurrentUserStore } from "@/stores/currentUser";
import type { UserOrEmptyUser } from "@/types/user";
import type { StoreWithUsers } from "@/types/storeWithUsers";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import UserDisplay from "./UserDisplay.vue";

const props = withDefaults(
  defineProps<{
    selectedUsers: UserOrEmptyUser[];
    store?: StoreWithUsers;
  }>(),
  {
    store: () => useCurrentInboxUsersStore(),
  }
);

const emit = defineEmits<{
  "update:selectedUsers": [selectedUsers: UserOrEmptyUser[]];
}>();

const { user: currentUser } = storeToRefs(useCurrentUserStore());
const { users, usersWithEmptyUser } = storeToRefs(props.store);
const { emptyUser } = props.store;

const options = computed(() => {
  if (!currentUser.value) return usersWithEmptyUser.value;
  return [
    emptyUser,
    currentUser.value as UserOrEmptyUser,
    ...users.value.filter(
      (u: UserOrEmptyUser) => u.email !== currentUser.value!.email
    ),
  ];
});

function selectUser(user: UserOrEmptyUser) {
  if (
    props.selectedUsers.map((user) => user.id || null).includes(user.id || null)
  )
    return;
  emit("update:selectedUsers", [...props.selectedUsers, user]);
}

function unselectUser(user: UserOrEmptyUser) {
  emit(
    "update:selectedUsers",
    props.selectedUsers.filter((u) => u.id !== user.id)
  );
}
</script>
