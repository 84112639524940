<template>
  <date-display
    :date="date"
    :showOverdueClose="showOverdueClose"
    :icon="icon"
    :name="name"
    :isOverdue="isOverdue"
    :isClose="isClose"
  />
</template>

<script setup lang="ts">
import DateDisplay from "@/components/DateDisplay.vue";
import { WARN_IF_INQUIRY_SUBMISSION_IS_IN_LESS_THAN_BUSINESS_DAYS } from "@/config/constants";
import {
  isClose as isCloseUtils,
  isOverdue as isOverdueUtils,
} from "@/utils/dateUtils";
import { computed } from "vue";

const props = defineProps<{
  date: Date | null;
  showOverdueClose: boolean;
  icon: string;
  name: string;
}>();

const isClose = computed(() =>
  isCloseUtils(
    props.date,
    props.showOverdueClose,
    WARN_IF_INQUIRY_SUBMISSION_IS_IN_LESS_THAN_BUSINESS_DAYS
  )
);
const isOverdue = computed(() =>
  isOverdueUtils(props.date, props.showOverdueClose)
);
</script>
