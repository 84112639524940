import { client } from "@/api/client/client";
import type { Tag } from "@/types/tag";

export async function createTag(
  inboxId: number,
  tag: Omit<Tag, "id">
): Promise<Tag> {
  const result = await client.post<Tag>(`/inboxes/${inboxId}/tags/`, tag);
  return result.data;
}

export async function deleteTag(inboxId: number, tagId: number): Promise<void> {
  await client.delete(`/inboxes/${inboxId}/tags/${tagId}/`);
}

export async function updateTag(inboxId: number, tag: Tag): Promise<Tag> {
  const result = await client.patch<Tag>(
    `/inboxes/${inboxId}/tags/${tag.id}/`,
    tag
  );
  return result.data;
}
