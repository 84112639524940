/**
 * Track inflight POST, PUT, PATCH and DELETE requests so the user can be warned when navigating
 * away while such a request is ongoing.
 */
import type { InternalAxiosRequestConfig, AxiosResponse, Axios } from "axios";

const TRACKED_METHODS = ["post", "put", "patch", "delete"];

export let inflightRequests = 0;

export function trackInflightRequests(client: Axios) {
  client.interceptors.request.use(addRequest);
  client.interceptors.response.use(removeRequest, removeErrorRequest);
}

function addRequest(config: InternalAxiosRequestConfig) {
  if (isRelevantRequest(config)) inflightRequests++;
  return config;
}

function removeRequest(response: AxiosResponse) {
  if (isRelevantRequest(response.config)) inflightRequests--;
  return response;
}

function removeErrorRequest(error: any) {
  if (isRelevantRequest(error.config)) inflightRequests--;
  return Promise.reject(error);
}

function isRelevantRequest(config: InternalAxiosRequestConfig) {
  return config.method && TRACKED_METHODS.includes(config.method.toLowerCase());
}
