<template>
  <div class="flex column gap-xs no-wrap text-xs">
    <date-display
      :name="$t('supplierRfq.dueDate')"
      :date="supplierRfq.dueDate"
      icon="sym_r_calendar_clock"
      :show-overdue-close="true"
      :is-overdue="isOverdue"
      :is-close="isClose"
    />
    <date-display
      :name="$t('supplierRfq.sentAt')"
      :date="supplierRfq.sentAt"
      icon="sym_r_forward_to_inbox"
      :show-overdue-close="false"
    />
  </div>
</template>

<script setup lang="ts">
import DateDisplay from "@/components/DateDisplay.vue";
import { WARN_IF_SUPPLIER_RFQ_SUBMISSION_IS_IN_LESS_THAN_BUSINESS_DAYS } from "@/config/constants";
import type { SupplierRFQ } from "@/types/supplierRfq";
import {
  isClose as isCloseUtils,
  isOverdue as isOverdueUtils,
} from "@/utils/dateUtils";
import { computed } from "vue";

const props = defineProps<{
  supplierRfq: SupplierRFQ;
}>();

const isClose = computed(() =>
  isCloseUtils(
    props.supplierRfq.dueDate,
    true,
    WARN_IF_SUPPLIER_RFQ_SUBMISSION_IS_IN_LESS_THAN_BUSINESS_DAYS
  )
);
const isOverdue = computed(() =>
  isOverdueUtils(props.supplierRfq.dueDate, true)
);
</script>
