import { ref, type Ref } from "vue";
import { range } from "lodash";

export function useSelection(numRows: Ref<number>) {
  const selectedIndices = ref<number[]>([]);

  const previouslySelectedIndices = ref<number[]>([]);

  function select(index: number | null) {
    if (index === null) {
      selectedIndices.value = [];
      return;
    }

    selectedIndices.value = [index];
  }

  function toggleSelectIndex(index: number) {
    if (selectedIndices.value.includes(index)) {
      selectedIndices.value = selectedIndices.value.filter((i) => i !== index);
    } else {
      selectedIndices.value = [...selectedIndices.value, index];
    }
  }

  function extendSelectIndex(index: number) {
    if (selectedIndices.value.length === 0) {
      select(index);
      return;
    }

    const lastIndexAbove = Math.max(
      ...selectedIndices.value.filter((i) => i < index)
    );

    if (lastIndexAbove === -Infinity) {
      // extend selection upwards
      selectedIndices.value = [
        ...range(index, getFirstSelectedIndex()),
        ...selectedIndices.value,
      ];
    } else {
      // extend selection downwards
      selectedIndices.value = [
        ...selectedIndices.value,
        ...range(lastIndexAbove + 1, index + 1),
      ];
    }
  }

  function selectNext() {
    const currentIndex = getLastSelectedIndex();
    select((currentIndex + 1) % numRows.value);
  }

  function selectPrevious() {
    const currentIndex = getFirstSelectedIndex();
    select((currentIndex - 1 + numRows.value) % numRows.value);
  }

  function extendSelectNext() {
    const currentIndex = getLastSelectedIndex();
    extendSelectIndex((currentIndex + 1) % numRows.value);
  }

  function extendSelectPrevious() {
    const currentIndex = getFirstSelectedIndex();
    extendSelectIndex((currentIndex - 1 + numRows.value) % numRows.value);
  }

  function getFirstSelectedIndex() {
    if (selectedIndices.value.length > 0) {
      return Math.min(...selectedIndices.value);
    }

    if (previouslySelectedIndices.value.length > 0) {
      return Math.min(...previouslySelectedIndices.value);
    }

    return -1;
  }

  function getLastSelectedIndex() {
    if (selectedIndices.value.length > 0) {
      return Math.max(...selectedIndices.value);
    }

    if (previouslySelectedIndices.value.length > 0) {
      return Math.max(...previouslySelectedIndices.value);
    }

    return -1;
  }

  function unselect() {
    previouslySelectedIndices.value = selectedIndices.value;
    select(null);
  }

  function selectAll() {
    selectedIndices.value = range(numRows.value);
  }

  return {
    selectedIndices,
    select,
    toggleSelectIndex,
    extendSelectIndex,
    selectNext,
    selectPrevious,
    extendSelectNext,
    extendSelectPrevious,
    unselect,
    selectAll,
  };
}
