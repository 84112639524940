import { persistentRef } from "@/utils/persistentRef";
import { defineStore } from "pinia";
import { computed } from "vue";

const DEFAULT_SIDEBAR_WIDTH = 220;
const MIN_SIDEBAR_WIDTH = 100;

export const useSidebarStateStore = defineStore("sidebarState", () => {
  const sidebarWidth = persistentRef("sidebar-width", DEFAULT_SIDEBAR_WIDTH);
  const previousSidebarWidth = persistentRef(
    "previous-sidebar-width",
    DEFAULT_SIDEBAR_WIDTH
  );

  const isSidebarOpen = computed(() => sidebarWidth.value > MIN_SIDEBAR_WIDTH);

  function toggle() {
    if (sidebarWidth.value < MIN_SIDEBAR_WIDTH) {
      sidebarWidth.value = Math.max(
        previousSidebarWidth.value,
        MIN_SIDEBAR_WIDTH + 1
      );
    } else {
      previousSidebarWidth.value = sidebarWidth.value;
      sidebarWidth.value = 0;
    }
  }

  return {
    sidebarWidth,
    isSidebarOpen,
    toggle,
  };
});
