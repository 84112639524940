<template>
  <q-page class="fit" :style-fn="() => ''">
    <q-splitter v-model="pageSplitPercentage" class="full-height">
      <template #before>
        <header-bar>
          <supplier-rfqs-filter-menu-button />
          <supplier-rfqs-filter-chips />
        </header-bar>
        <div class="page-below-header-bar column no-wrap">
          <supplier-rfqs-table class="col" />
          <div
            class="supplier-rfqs-table-footer flex row no-wrap items-center q-px-md"
          >
            <q-space />
            <supplier-rfqs-pagination />
          </div>
        </div>
      </template>
      <template #after>
        <header-bar secondary />
        <div class="page-below-header-bar">
          <router-view />
        </div>
      </template>
    </q-splitter>
  </q-page>
</template>

<script setup lang="ts">
import HeaderBar from "@/components/Header/HeaderBar.vue";
import SupplierRfqsFilterChips from "@/components/SupplierRfqsPage/SupplierRfqsFilterChips.vue";
import SupplierRfqsFilterMenuButton from "@/components/SupplierRfqsPage/SupplierRfqsFilterMenuButton.vue";
import SupplierRfqsPagination from "@/components/SupplierRfqsPage/SupplierRfqsTable/SupplierRfqsPagination.vue";
import SupplierRfqsTable from "@/components/SupplierRfqsPage/SupplierRfqsTable/SupplierRfqsTable.vue";
import { ref } from "vue";

const pageSplitPercentage = ref(50);
</script>

<style scoped lang="scss">
.no-link-decoration {
  font-weight: inherit;

  &:hover {
    text-decoration: none;
  }
}

.supplier-rfqs-table-footer {
  height: 48px;
  border-top: 1px solid $separator-color;
  z-index: 10;
  flex-shrink: 0;
}
</style>
