import { getSupplier } from "@/api/supplier";
import { useRouteParams } from "@/composables/useRouteParams";
import type { SupplierDetails } from "@/types/supplier";
import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useCurrentSupplierRfqsStore } from "./currentSupplierRfqs";

export const useCurrentSupplierStore = defineStore("currentSupplier", () => {
  const supplier = ref<SupplierDetails | null>(null);

  const { organizationId, supplierRfqId } = useRouteParams();

  const { supplierRfqs } = storeToRefs(useCurrentSupplierRfqsStore());

  watch(
    () => [organizationId.value, supplierRfqId.value, supplierRfqs.value],
    loadSupplier,
    {
      immediate: true,
    }
  );

  async function loadSupplier() {
    if (!organizationId.value || !supplierRfqId.value || !supplierRfqs.value) {
      supplier.value = null;
      return;
    }

    const currentSupplierRfq = supplierRfqs.value.find(
      (supplierRfq) => supplierRfq.id === supplierRfqId.value
    );

    if (!currentSupplierRfq) {
      supplier.value = null;
      return;
    }

    supplier.value = await getSupplier(
      organizationId.value,
      currentSupplierRfq.supplier.id
    );
  }

  return {
    supplier,
  };
});
