<template>
  <div class="row items-center gap-x-sm">
    <FilterChip
      v-if="filterStatusStrings.length > 0"
      :icon="icons.filterStatus"
      @remove="$emit('update:filterStatusStrings', [])"
    >
      {{ t("statuses", filterStatusStrings.length) }}
    </FilterChip>
    <FilterChip
      v-if="filterTagsIds.length > 0"
      :icon="icons.filterTags"
      @remove="$emit('update:filterTagsIds', [])"
    >
      {{ t("tags", filterTagsIds.length) }}
    </FilterChip>
    <FilterChip
      v-if="filterUserIds.length > 0"
      :icon="icons.filterUser"
      @remove="$emit('update:filterUserIds', [])"
    >
      {{ t("filterChips.nUsers", { n: filterUserIds.length }) }}
    </FilterChip>
    <FilterChip
      v-if="filterSender"
      :icon="icons.filterSender"
      @remove="$emit('update:filterSender', null)"
    >
      {{ t("Sender") }}: {{ filterSender }}
    </FilterChip>
    <FilterChip
      v-if="filterBuildingProject"
      :icon="icons.filterBuildingProject"
      @remove="$emit('update:filterBuildingProject', null)"
    >
      {{ t("Building project") }}: {{ filterBuildingProject }}
    </FilterChip>
  </div>
</template>

<script setup lang="ts">
import FilterChip from "@/components/FilterChip.vue";
import { useI18n } from "vue-i18n";
import icons from "@/config/icons";

defineProps<{
  filterStatusStrings: string[];
  filterTagsIds: number[];
  filterUserIds: (number | null)[];
  filterSender: string | null;
  filterBuildingProject: string | null;
}>();

defineEmits<{
  "update:filterStatusStrings": [statuses: string[]];
  "update:filterUserIds": [userIds: (number | null)[]];
  "update:filterSender": [sender: string | null];
  "update:filterBuildingProject": [sender: string | null];
  "update:filterTagsIds": [tagIds: number[]];
}>();

const { t } = useI18n();
</script>
