<template>
  <div class="email-display q-pa-md">
    <q-card>
      <div class="column full-width q-pa-md">
        <div class="row items-center text-base q-mb-md">
          {{ email.subject }}
        </div>
        <div class="row items-start no-wrap full-width q-mb-md">
          <div class="row items-start full-width justify-between no-wrap">
            <div class="column col-shrink full-width">
              <div class="full-width ellipsis">
                <email-address-display :address="email.sender" />
              </div>
              <div class="text-deemphasized full-width ellipsis row">
                {{ $t("email.to") }} &nbsp;
                <template
                  v-for="recipient in email.recipients"
                  :key="recipient.email"
                >
                  <email-address-display :address="recipient" />
                  <span
                    v-if="
                      recipient !==
                      email.recipients[email.recipients.length - 1]
                    "
                  >
                    {{ ", " }}
                  </span>
                </template>
              </div>
              <div
                class="text-deemphasized full-width ellipsis row"
                v-if="email.ccs.length > 0"
              >
                {{ $t("email.cc") }} &nbsp;
                <template v-for="cc in email.ccs" :key="cc.email">
                  <email-address-display :address="cc" />
                  <span v-if="cc !== email.ccs[email.ccs.length - 1]">{{
                    ", "
                  }}</span>
                </template>
              </div>
            </div>
            <div class="text-deemphasized flex-shrink-0">
              {{
                email.sentAt?.toLocaleString(locale, {
                  dateStyle: "short",
                  timeStyle: "short",
                })
              }}
            </div>
          </div>
        </div>
        <q-separator grey class="q-mb-md" />
        <div class="email-content full-width">
          <div v-if="email.html">
            <div v-html="email.html"></div>
          </div>
          <div v-else class="email-text">
            {{ email.text }}
          </div>
        </div>
      </div>
    </q-card>
  </div>
</template>

<script setup lang="ts">
import type { Email } from "@/types/email";
import type { Inbox } from "@/types/inbox";
import { useI18n } from "vue-i18n";
import EmailAddressDisplay from "./EmailAddressDisplay.vue";

const { locale } = useI18n();

defineProps<{
  inbox: Inbox | undefined;
  email: Email;
}>();
</script>

<style lang="scss">
.email-display {
  background-color: $document-background;
  width: 100%;
  min-height: 100%;

  .email-content {
    overflow-y: auto;
    max-width: 100%;
  }

  .email-text {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
}
</style>
