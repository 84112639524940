<template>
  <q-page class="fit" :style-fn="() => ''">
    <q-splitter v-model="pageSplitPercentage" class="full-height">
      <template #before>
        <header-bar>
          <template #right>
            <mark-all-as-read-button />
          </template>
        </header-bar>
        <notifications-list />
      </template>
      <template #after>
        <router-view name="right" />
      </template>
    </q-splitter>
  </q-page>
</template>

<script setup lang="ts">
import HeaderBar from "@/components/Header/HeaderBar.vue";
import NotificationsList from "@/components/Notifications/NotificationsList.vue";
import MarkAllAsReadButton from "@/components/Notifications/MarkAllAsReadButton.vue";
import { ref } from "vue";

const pageSplitPercentage = ref(50);
</script>
