import { useCurrentUserStore } from "@/stores/currentUser";
import { useRouteParams } from "@/composables/useRouteParams";
import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";
import type { Role, OrganizationRoleName, InboxRoleName } from "@/types/role";

export const useCurrentPermissionsStore = defineStore(
  "currentPermissions",
  () => {
    const { organizationRoles, inboxRoles, user } = storeToRefs(
      useCurrentUserStore()
    );

    const { organizationId, inboxId } = useRouteParams();

    const currentOrganizationRoles = computed(() =>
      organizationRoles.value.filter(
        (r: Role) =>
          r.objectModel === "organization" &&
          r.objectId === organizationId.value
      )
    );

    const currentInboxRoles = computed(() =>
      inboxRoles.value.filter(
        (r: Role) => r.objectModel === "inbox" && r.objectId === inboxId.value
      )
    );

    const getHasCurrentOrganizationRole = (role: OrganizationRoleName) =>
      user.value?.isSuperuser ||
      currentOrganizationRoles.value.some(
        (r: Role) => r.role === "organization_admin"
      ) ||
      currentOrganizationRoles.value.some((r: Role) => r.role === role);

    const hasCurrentOrganizationRole = computed(() => ({
      organization_admin: getHasCurrentOrganizationRole("organization_admin"),
      product_admin: getHasCurrentOrganizationRole("product_admin"),
    }));

    const getHasCurrentInboxRole = (role: InboxRoleName) =>
      user.value?.isSuperuser ||
      currentOrganizationRoles.value.some(
        (r: Role) => r.role === "organization_admin"
      ) ||
      currentInboxRoles.value.some((r: Role) => r.role === "inbox_admin") ||
      currentInboxRoles.value.some((r: Role) => r.role === role);

    const hasCurrentInboxRole = computed(() => ({
      inbox_admin: getHasCurrentInboxRole("inbox_admin"),
      inbox_worker: getHasCurrentInboxRole("inbox_worker"),
    }));

    return {
      isSuperuser: user.value?.isSuperuser || false,
      hasCurrentOrganizationRole,
      hasCurrentInboxRole,
    };
  }
);
