// directives/stopPrevent.ts
import type { DirectiveBinding } from "vue";

const catchClicksDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding<(event: Event) => void>) {
    const handler = (event: Event) => {
      event.stopPropagation();
      event.preventDefault();
      if (binding.value) {
        binding.value(event); // Call the provided function if passed
      }
    };

    // Attach all specified events to the element
    el.addEventListener("click", handler);
    el.addEventListener("touchstart", handler);
    el.addEventListener("mousedown", handler);
    el.addEventListener("keydown", handler);

    // Store the handler so it can be removed later if necessary
    (el as any).__stopPreventHandler__ = handler;
  },
  unmounted(el: HTMLElement) {
    const handler = (el as any).__stopPreventHandler__;
    if (handler) {
      el.removeEventListener("click", handler);
      el.removeEventListener("touchstart", handler);
      el.removeEventListener("mousedown", handler);
      el.removeEventListener("keydown", handler);
      delete (el as any).__stopPreventHandler__;
    }
  },
};

export default catchClicksDirective;
