<template>
  <div class="slim-page">
    <settings-page-header section="account" page="emailSending" />
    <q-form
      @submit="updateSignature"
      class="q-gutter-y-sm q-pa-md"
      v-if="userSettingsStore.userSettings"
    >
      <q-editor
        outlined
        v-model="signature"
        :label="$t('settings.account.emailSending.signature')"
        :placeholder="$t('settings.account.emailSending.signaturePlaceholder')"
      />
      <div class="row justify-end">
        <q-btn
          dense
          type="submit"
          color="primary"
          :label="$t('settings.account.emailSending.changeSignature')"
          :disable="!user || !signature"
          :loading="isUpdatingSignature"
        />
      </div>
    </q-form>
    <div v-else>
      <q-skeleton type="QInput" />
      <q-skeleton type="QInput" />
    </div>
  </div>
</template>

<script setup lang="ts">
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useCurrentUserStore } from "@/stores/currentUser";
import { useUserSettingsStore } from "@/stores/userSettings";
import { useQuasar } from "quasar";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const userStore = useCurrentUserStore();
const userSettingsStore = useUserSettingsStore();

if (!userStore.user) {
  userStore.loadUser();
}

const user = ref(userStore.user);
const signature = ref(userSettingsStore.userSettings.emailSignature || "");

const isUpdatingSignature = ref(false);

const q = useQuasar();
const { t } = useI18n();

watch(
  () => userSettingsStore.userSettings,
  (newUserSettings) => {
    signature.value = newUserSettings.emailSignature || "";
  }
);

async function updateSignature() {
  isUpdatingSignature.value = true;
  try {
    await userSettingsStore.updateUserSettings({
      emailSignature: signature.value,
    });
    q.notify({
      type: "positive",
      message: t("settings.account.emailSending.signatureUpdated"),
    });
  } finally {
    isUpdatingSignature.value = false;
  }
}
</script>
