<template>
  <div
    class="q-pa-md row items-center justify-center"
    style="height: 100vh; width: 100%"
  >
    <q-linear-progress
      v-if="isLoading"
      indeterminate
      color="primary"
      style="max-width: 400px"
    />
    <div v-else>
      {{ $t("No inboxes found for this organization. Please create one.") }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { listInboxes } from "@/api/inbox";
import { useRouteParams } from "@/composables/useRouteParams";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const { organizationId } = useRouteParams();
watch(organizationId, redirectToOrganization);

const isLoading = ref(true);

redirectToOrganization();

async function redirectToOrganization() {
  if (!organizationId.value || isNaN(organizationId.value)) return;

  let inboxes;

  try {
    inboxes = await listInboxes(organizationId.value);
  } catch (error: any) {
    if (error.response?.status === 404) {
      // Organization not found is handled by LoggedInLayout
      return;
    }
    throw error;
  }

  if (!organizationId.value || isNaN(organizationId.value)) return;

  if (inboxes.length === 0) {
    isLoading.value = false;
    return;
  }

  router.replace({
    name: "inbox",
    params: {
      organizationId: organizationId.value,
      inboxId: inboxes[0].id,
      inboxView: "in_progress",
    },
  });
}
</script>
