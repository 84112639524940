import { setupApp } from "@/setup";
import * as Sentry from "@sentry/vue";
import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import { CSRF_COOKIE_NAME, CSRF_HEADER_NAME } from "./api/client/csrf";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://7a32dae616c572505422f6b479d28ba6@o4507741312909312.ingest.de.sentry.io/4507741770154064",
  environment: getEnvironment(),
  tracePropagationTargets: ["localhost", /^https:\/\/[^/]*\.kinisto\.com\/.*/],
  integrations: [
    Sentry.browserTracingIntegration({
      router,
    }),
    Sentry.captureConsoleIntegration({ levels: ["error"] }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: true,
      blockAllMedia: false,
      block: [".email-display"],
    }),
  ],
  tracesSampler: (samplingContext) => {
    if (
      ["poll-inquiries", "poll-unread-count"].includes(samplingContext.name)
    ) {
      return 0.02;
    }
    return 0.5;
  },
  profilesSampleRate: 0.3,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
  enabled: getEnvironment() !== "local",
  beforeSend(event) {
    // Scrub sensitive data
    delete event.request?.data?.password;
    delete event.request?.data?.new_password;
    delete event.request?.data?.token;
    delete event.request?.cookies?.[CSRF_COOKIE_NAME];
    delete event.request?.cookies?.sessionId;
    delete event.request?.headers?.[CSRF_HEADER_NAME];
    return event;
  },
  ignoreErrors: [
    "Request failed with status code 401",
    "Request failed with status code 403",
    "Request failed with status code 404",
    "Request aborted",
    "Network Error",
    "Failed to execute 'getComputedStyle' on 'Window': parameter 1 is not of type 'Element'",
  ],
});

function getEnvironment(): string {
  const hostname = window.location.hostname;
  if (hostname.includes("dev.")) {
    return "dev";
  } else if (hostname.includes(".review.")) {
    return "review";
  } else if (
    hostname === "localhost" ||
    hostname === "127.0.0.1" ||
    process.env.NODE_ENV === "development"
  ) {
    return "local";
  } else {
    return "prod";
  }
}

setupApp(app);

app.mount("#app");
