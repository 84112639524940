<template>
  <multi-user-select-menu
    anchor="top right"
    self="top left"
    :offset="[0, 0]"
    no-route-dismiss
    :store="store"
    :selected-users="selectedUsers"
    @update:selected-users="handleSelectedUsersChange"
  />
</template>

<script setup lang="ts">
import MultiUserSelectMenu from "@/components/User/MultiUserSelectMenu.vue";
import { useCurrentOrganizationUsersStore } from "@/stores/currentOrganizationUsers";
import type { UserOrEmptyUser } from "@/types/user";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const props = defineProps<{
  selectedUserIds: (number | null)[];
}>();

const store = useCurrentOrganizationUsersStore();
const { usersWithEmptyUser } = storeToRefs(store);

const selectedUsers = computed(() => {
  return props.selectedUserIds
    .map((id) => usersWithEmptyUser.value.find((user) => user.id === id))
    .filter(Boolean) as UserOrEmptyUser[];
});

const emit = defineEmits<{
  (e: "update:selectedUserIds", value: (number | null)[]): void;
}>();

const handleSelectedUsersChange = (users: UserOrEmptyUser[]) => {
  emit(
    "update:selectedUserIds",
    users.map((user) => user.id)
  );
};
</script>
