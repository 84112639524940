export default {
  "Welcome to kinisto": "Willkommen bei kinisto",
  Login: "Anmelden",
  Email: "E-Mail",
  Password: "Passwort",
  "This field is required": "Dieses Feld muss ausgefüllt sein",
  "Must contain a valid e-mail address":
    "Muss eine gültige E-Mail-Adresse enthalten",
  "Login failed. Please check your email and password.":
    "Einloggen fehlgeschlagen. Bitte überprüfen Sie E-Mail und Passwort.",
  "Forgot password?": "Passwort vergessen?",
  "Password reset failed. Please check the email address.":
    "Passwort zurücksetzen fehlgeschlagen. Bitte überprüfen Sie die E-Mail-Adresse.",
  "Password reset instructions have been sent to your email address.":
    "Die Anweisungen zum Zurücksetzen des Passworts wurden an Ihre E-Mail-Adresse gesendet.",
  "Reset password": "Passwort zurücksetzen",
  "Confirm password": "Passwort bestätigen",
  "Invalid password reset link.":
    "Ungültiger Link zum Zurücksetzen des Passworts.",
  "Please request a new link.": "Bitte fordern Sie einen neuen Link an.",
  "Password reset successful. You can now login with your new password.":
    "Passwort zurücksetzen erfolgreich. Sie können sich jetzt mit Ihrem neuen Passwort anmelden.",
  "Go to login": "Zur Anmeldung",
  "Password reset failed. Please try again or contact your administrator.":
    "Passwort zurücksetzen fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie den Administrator.",
  "Passwords don't match": "Passwörter stimmen nicht überein",
  "Set a new password": "Neues Passwort festlegen",
  "Signup successful. You can now login with your new password.":
    "Registrierung erfolgreich. Sie können sich jetzt mit Ihrem neuen Passwort anmelden.",
  "Create your kinisto account": "Erstellen Sie Ihr kinisto-Konto",
  "Create account": "Konto erstellen",
  "Invalid signup link.": "Ungültiger Registrierungslink.",
  "Invalid invitation code": "Ungültiger Einladungscode",
  "This invite has expired. Please request another invite.":
    "Diese Einladung ist abgelaufen. Bitte fordern Sie eine neue Einladung an.",
};
