<template>
  <div>
    <input
      ref="inputEl"
      :model-value="modelValue"
      :placeholder="$t('Search')"
      :class="{
        'master-data-search-bar': true,
        'master-data-search-bar-active': modelValue && modelValue.length,
        'standalone-input': true,
      }"
      @input="handleInput"
    />
    <q-btn
      flat
      dense
      color="neutral-5"
      icon="sym_r_close"
      v-if="modelValue"
      @click="clear"
    />
    <q-tooltip
      no-parent-event
      v-model="showTooltip"
      class="absolute"
      :offset="[2, 2]"
    >
      {{
        $t("masterDataSearchBar.enterAtLeastNCharacters", {
          n: MIN_SEARCH_TEXT_LENGTH,
        })
      }}
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import debounce from "debounce-promise";
import { ref } from "vue";

const MIN_SEARCH_TEXT_LENGTH = 3;

const props = withDefaults(
  defineProps<{
    modelValue: string | null;
    debounceMs?: number;
  }>(),
  {
    debounceMs: 300,
  }
);

const showTooltip = ref(false);

function updateTooltipVisibility(value: string) {
  showTooltip.value = value.length > 0 && value.length < MIN_SEARCH_TEXT_LENGTH;
}

const emit = defineEmits<{
  "update:modelValue": [value: string | null];
}>();

const inputEl = ref<HTMLInputElement | null>(null);

function handleInput(event: Event) {
  const value = (event.target as HTMLInputElement).value;
  updateTooltipVisibility(value);

  if (value.length < MIN_SEARCH_TEXT_LENGTH) {
    emitUpdate("");
    return;
  }
  emitUpdate(value);
}

function clear() {
  emitUpdate("");
  inputEl.value!.value = "";
}

const emitUpdate = debounce(
  (value: string) => {
    emit("update:modelValue", value);
  },
  props.debounceMs,
  { leading: true }
);
</script>

<style lang="scss">
.master-data-search-bar {
  width: 200px;
  transition: width 0.3s;

  &:focus-visible,
  &-active {
    width: 600px;
  }
}
</style>
