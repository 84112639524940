export function getConfidenceColor(confidence: number) {
  const color = interpolateColors(
    negativeColor,
    neutralColor,
    positiveColor,
    confidence
  );
  return color;
}

const negativeColor = "#ef4e4e";
const neutralColor = "#f7c948";
const positiveColor = "#3ebd93";

type RGB = {
  r: number;
  g: number;
  b: number;
};

function hexToRgb(hex: string): RGB {
  hex = hex.replace(/^#/, "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return { r, g, b };
}

function rgbToHex({ r, g, b }: RGB): string {
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

function mixColors(colorA: string, colorB: string, x: number): string {
  const rgbA = hexToRgb(colorA);
  const rgbB = hexToRgb(colorB);

  const r = Math.round(rgbA.r + (rgbB.r - rgbA.r) * x);
  const g = Math.round(rgbA.g + (rgbB.g - rgbA.g) * x);
  const b = Math.round(rgbA.b + (rgbB.b - rgbA.b) * x);

  return rgbToHex({ r, g, b });
}

function interpolateColors(
  color1: string,
  color2: string,
  color3: string,
  x: number
): string {
  if (x <= 0.5) {
    // Adjust x for the first half of the interpolation
    const adjustedX = x * 2; // Scale x to be between 0 and 1
    return mixColors(color1, color2, adjustedX);
  } else {
    // Adjust x for the second half of the interpolation
    const adjustedX = (x - 0.5) * 2; // Scale x to be between 0 and 1
    return mixColors(color2, color3, adjustedX);
  }
}
