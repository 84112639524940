<template>
  <q-btn flat dense :disable="disabled">
    <quotation-status-chip :status="status" :icon-only="iconOnly" />
    <q-tooltip :delay="1000">{{ $t(`quotationStatus.${status}`) }}</q-tooltip>
    <select-menu
      :selected-option="selectedStatusOption"
      :options="statusOptions"
      :label-fn="(opt: StatusOption) => opt.label"
      :search-values="['label']"
      id-value="name"
      :search-placeholder="$t('Search status')"
      @select="(opt: StatusOption) => $emit('update:status', opt.name)"
    >
      <template #option-label="{ option }">
        <quotation-status-chip :status="option.name" />
      </template>
    </select-menu>
  </q-btn>
</template>

<script setup lang="ts">
import SelectMenu from "@/components/SelectMenus/SelectMenu.vue";
import {
  QUOTATION_STATUS_OPTIONS,
  type QuotationStatus,
} from "@/types/quotation";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import QuotationStatusChip from "./QuotationStatusChip.vue";

type StatusOption = {
  name: QuotationStatus;
  label: string;
};

const props = defineProps<{
  status: QuotationStatus;
  iconOnly?: boolean;
  disabled?: boolean;
}>();

defineEmits<{
  "update:status": [status: QuotationStatus];
}>();

const { t } = useI18n();

const statusOptions: StatusOption[] = QUOTATION_STATUS_OPTIONS.map(
  (status) => ({
    name: status as QuotationStatus,
    label: t(`quotationStatus.${status}`),
  })
);

const selectedStatusOption = computed<StatusOption | null>(
  () => statusOptions.find((opt) => opt.name === props.status)!
);
</script>
