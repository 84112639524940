<template>
  <div class="slim-page">
    <settings-page-header section="organization" page="general" />
    <q-form @submit="updateOrganization" class="q-gutter-y-sm q-pa-md">
      <q-input
        v-if="organization"
        dense
        outlined
        autofocus
        name="name"
        v-model="organization.name"
        :label="$t('Name')"
        :readonly="!canWrite"
      />
      <q-skeleton type="QInput" v-else />
      <q-input
        v-if="organization"
        dense
        outlined
        name="description"
        v-model="organization.description"
        :label="$t('Description')"
        :readonly="!canWrite"
      />
      <q-skeleton type="QInput" v-else />
      <q-btn
        v-if="canWrite"
        dense
        class="float-right q-mt-md"
        type="submit"
        color="primary"
        :label="$t('Save')"
        :disable="!organization"
        :loading="isUpdating"
      />
    </q-form>
  </div>
</template>

<script setup lang="ts">
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import type { Organization } from "@/types/organization";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const q = useQuasar();
const { t } = useI18n();

const isUpdating = ref(false);

const store = useCurrentOrganizationStore();
const organization = ref<Organization | null>(
  // We need to clone the organization to avoid reactivity issues
  store.organization ? { ...store.organization } : null
);
watch(
  () => store.organization,
  () => {
    // We need to clone the organization to avoid reactivity issues
    organization.value = store.organization ? { ...store.organization } : null;
  }
);

const { hasCurrentOrganizationRole } = storeToRefs(
  useCurrentPermissionsStore()
);
const canWrite = computed(
  () => hasCurrentOrganizationRole.value["organization_admin"]
);

async function updateOrganization() {
  if (!organization.value) return;
  isUpdating.value = true;
  try {
    await store.updateOrganization({
      name: organization.value.name,
      description: organization.value.description,
    });
    q.notify({
      message: t("Organization updated"),
      color: "positive",
      actions: [{ label: t("OK"), color: "white" }],
    });
  } finally {
    isUpdating.value = false;
  }
}
</script>
