<template>
  <span>{{ userLabel }}</span>
</template>

<script setup lang="ts">
import type { ShortUser } from "@/types/user";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { getUserLabel } from "./userLabel";

const props = defineProps<{
  user: Omit<ShortUser, "id"> | null;
  iconOnly?: boolean;
}>();

const { t } = useI18n();

const userLabel = computed(() => {
  if (!props.user) return t("No assignee");

  return getUserLabel(props.user);
});
</script>
