<template>
  <span>{{ currencyFormatter.format(Number(value)) }}</span>
</template>

<script setup lang="ts">
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import { storeToRefs } from "pinia";

defineProps<{
  value: number | string;
}>();

const { currencyFormatter } = storeToRefs(useCurrentOrganizationStore());
</script>
