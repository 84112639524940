<template>
  <sort-menu-button
    :options="sortOptions"
    :sort-by="sortBy"
    :descending="descending"
    @update-sort="$emit('updateSort', $event)"
  />
</template>

<script setup lang="ts">
import icons from "@/config/icons";
import { useI18n } from "vue-i18n";
import SortMenuButton from "../SortMenuButton.vue";

defineProps<{
  sortBy: string | null;
  descending: boolean | null;
}>();

defineEmits<{
  updateSort: [sort: { sortBy: string; descending: boolean }];
}>();

const { t } = useI18n();

const sortOptions = [
  {
    value: "serial_id",
    label: t("quotation.serialId"),
    icon: icons.quotation.serialId,
  },
  {
    value: "created_at",
    label: t("quotation.createdAt"),
    icon: icons.quotation.createdDate,
  },
  {
    value: "accepted_or_declined_at",
    label: t("quotation.acceptedOrDeclinedAt"),
    icon: icons.quotation.acceptedOrDeclinedAt,
  },
  {
    value: "total_price_net",
    label: t("quotation.totalPriceNet"),
    icon: icons.quotation.totalPriceNet,
  },
  {
    value: "position_count",
    label: t("quotation.positionCount"),
    icon: icons.quotation.positionCount,
  },
];
</script>
