<template>
  <div class="fit column no-wrap items-center justify-center gap-sm">
    <q-icon name="sym_r_notifications" size="64px" color="neutral-5" />
    <div class="text-lg text-neutral-5">
      <i18n-t
        keypath="notificationsPage.unreadNotifications"
        tag="div"
        scope="global"
      >
        <template #unreadCount>
          {{ unreadCount }}
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNotificationsStore } from "@/stores/notifications";
import { storeToRefs } from "pinia";
import { I18nT } from "vue-i18n";

const { unreadCount } = storeToRefs(useNotificationsStore());
</script>
