<template>
  <q-page class="wrapper">
    <div class="box login-box">
      <div>
        <login-form
          v-if="!isPasswordReset"
          v-model:email="email"
          :on-submit="handleLoginFormSubmit"
          @reset-password="togglePasswordReset"
        />
        <password-reset-form
          v-else
          v-model:email="email"
          @go-to-login="togglePasswordReset"
        />
      </div>
    </div>
    <div class="box left-box">
      <img class="logo" src="@/assets/logo/kinisto-logo.svg" />
      <img
        class="illustration"
        src="@/assets/illustrations/kinisto-visual.svg"
      />
    </div>
  </q-page>
</template>

<script setup lang="ts">
import { login } from "@/api/auth";
import LoginForm from "@/components/LoginForm.vue";
import PasswordResetForm from "@/components/PasswordResetForm.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const isPasswordReset = ref(false);
const email = ref("");

function togglePasswordReset() {
  isPasswordReset.value = !isPasswordReset.value;
}

async function handleLoginFormSubmit(
  email: string,
  password: string
): Promise<boolean> {
  const loggedIn = await login(email, password);
  if (!loggedIn) return false;

  const redirectUrl = router.currentRoute.value.query.redirect;

  if (redirectUrl && redirectUrl.length) router.push(redirectUrl as string);
  else router.push("/");

  return true;
}
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 1;
}

.left-box {
  background-color: #fff;
  padding: 4rem;
}

.login-box {
  background-color: $neutral-1;
  width: 550px;
  flex-shrink: 0;
}

.illustration {
  max-width: 80%;
  max-height: 80%;
}

.logo {
  position: absolute;
  left: 2rem;
  top: 2rem;
  height: 2rem;
}
</style>
