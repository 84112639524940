<template>
  <AsyncMultiSelectMenu
    :findOptions="findSuppliers"
    :selectedOptions="selectedSuppliers.suppliersInAllSelectedGroups"
    :partiallySelectedOptions="selectedSuppliers.suppliersInSomeSelectedGroups"
    :searchPlaceholder="$t('supplierSelectMenu.searchPlaceholder')"
    :labelFn="(option: Supplier) => option.name"
    idValue="id"
    :preloadOptions="draftSuppliers"
    @select="(supplier: Supplier) => addSupplierRfq(supplier)"
    @unselect="(supplier: Supplier) => removeSupplierRfq(supplier)"
  />
</template>

<script setup lang="ts">
import { findSuppliers as apiFindSuppliers } from "@/api/supplier";
import AsyncMultiSelectMenu from "@/components/SelectMenus/AsyncMultiSelectMenu.vue";
import { useSupplierRfq } from "@/composables/positionsTable/useSupplierRfq";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentOfferPositionGroupsStore } from "@/stores/currentOfferPositionsGroups";
import type { Supplier } from "@/types/supplier";
import { storeToRefs } from "pinia";

const { selectedSuppliers, addSupplierRfq, removeSupplierRfq } =
  useSupplierRfq();

const { draftSuppliers } = storeToRefs(useCurrentOfferPositionGroupsStore());

const { organizationId } = useRouteParams();

async function findSuppliers(searchText: string) {
  if (!organizationId.value) return [];

  return await apiFindSuppliers(organizationId.value, searchText);
}
</script>
