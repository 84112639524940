<template>
  <div
    class="page-below-header-bar"
    id="notifications-container"
    :offset="1000"
  >
    <q-infinite-scroll
      @load="loadNotifications"
      scroll-target="#notifications-container"
      class="q-pa-sm"
    >
      <notification-item
        v-for="notification in notifications.values()"
        :key="notification.id"
        :notification="notification"
      />

      <template #loading>
        <div class="row justify-center q-my-md">
          <q-spinner-dots color="neutral-5" size="40px" />
        </div>
      </template>
    </q-infinite-scroll>
  </div>
</template>

<script setup lang="ts">
import { useNotificationsStore } from "@/stores/notifications";
import { storeToRefs } from "pinia";
import NotificationItem from "./NotificationItem.vue";

const store = useNotificationsStore();
const { notifications } = storeToRefs(store);

async function loadNotifications(index: number, done: (stop: boolean) => void) {
  const { next } = await store.loadNotifications();
  const stop = !next;
  done(stop);
}
</script>
