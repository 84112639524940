<template>
  <div>
    <input
      v-model="inputText"
      ref="inputEl"
      @keydown="handleKeypress"
      @blur="emitUpdate"
      :class="{
        'text-right': true,
        'number-input': true,
        'standalone-input': !embedded,
        'embedded-input': embedded,
        'full-width': true,
        'cursor-text': !disabled,
        highlighted,
      }"
      :disabled="disabled"
    />
    <q-tooltip v-if="highlighted"
      >{{ $t("numberInput.valueMissing") }}
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  value: number | null;
  precision: number;
  nullable: boolean;
  highlighted?: boolean;
  disabled?: boolean;
  embedded?: boolean;
}>();

const emit = defineEmits<{
  "update:value": [value: number | null];
}>();

const { locale } = useI18n();

const decimalSeparator = computed(() => {
  return (1.1).toLocaleString(locale.value).replace(/\d/g, "");
});

const localValue = ref(props.value);
watch(
  () => props.value,
  async () => {
    localValue.value = props.value;
    await nextTick();
    if (inputEl.value)
      inputEl.value.value =
        props.value === null ? "" : numberToString(props.value);
  }
);

defineExpose({
  focus: () => {
    inputEl.value?.focus();
  },
  select: () => {
    inputEl.value?.select();
  },
  blur: () => {
    inputEl.value?.blur();
  },
});

function emitUpdate() {
  emit("update:value", localValue.value);
  if (inputEl.value)
    inputEl.value.value =
      localValue.value === null ? "" : numberToString(localValue.value);
}

function handleKeypress(event: KeyboardEvent) {
  if (event.key === "Enter") {
    emitUpdate();
  }
  preventNonNumericInput(event);
}

function preventNonNumericInput(event: KeyboardEvent) {
  if (event.ctrlKey || event.metaKey || event.altKey) return;
  if (event.key.length > 1) return; // special key
  if (event.key == decimalSeparator.value) return;
  if (
    ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(event.key)
  )
    return;

  event.stopPropagation();
  event.preventDefault();
}

const inputEl = ref<HTMLInputElement | null>(null);

const inputText = computed({
  get: () => {
    if (
      inputEl.value &&
      stringToNumber(inputEl.value.value) === localValue.value
    )
      return inputEl.value.value;
    return localValue.value === null ? "" : numberToString(localValue.value);
  },
  set: (value: string) => {
    if (value === "" && props.nullable) return null;
    const number = stringToNumber(value);
    if (number === undefined) return;
    localValue.value = number;
  },
});

function numberToString(value: number) {
  return value.toLocaleString(locale.value, {
    minimumFractionDigits: props.precision,
    maximumFractionDigits: props.precision,
  });
}

function stringToNumber(value: string): number | undefined {
  if (!value.length) return;

  const decimalString = convertToDecimalString(value);
  if (!isValidNumberString(decimalString)) {
    return;
  }
  let number = parseFloat(decimalString);
  if (isNaN(number)) {
    return;
  }

  // Round down to precision
  return (
    Math.floor(number * Math.pow(10, props.precision)) /
    Math.pow(10, props.precision)
  );
}

const convertToDecimalString = (value: string) => {
  let result = value;
  if (decimalSeparator.value !== ".") {
    result = value.replace(".", "");
  }
  return result.replace(decimalSeparator.value, ".");
};

const isValidNumberString = (value: string) => {
  return value.match(/[^\d.]/g) === null;
};
</script>

<style scoped lang="scss">
.cursor-text {
  cursor: text !important;
}
.embedded-input {
  border: none;
  outline: none;
}
</style>
