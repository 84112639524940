export async function waitFor<T>(
  getter: () => T,
  timeout = 1000,
  interval = 100
): Promise<T | undefined> {
  const endTime = Date.now() + timeout;
  while (Date.now() < endTime) {
    const result = getter();
    if (result) return result;
    await new Promise((resolve) => setTimeout(resolve, interval));
  }
  return undefined;
}
