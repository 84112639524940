<template>
  <multi-select-menu
    :selected-options="selectedTags"
    :options="options"
    :label-fn="(tag) => tag.label"
    :search-values="['label']"
    id-value="id"
    :search-placeholder="$t('multiTagSelect.searchTag')"
    @select="selectTag"
    @unselect="unselectTag"
  >
    <template #option-label="{ option }">
      <tag-chip :tag="option" />
    </template>
  </multi-select-menu>
</template>

<script setup lang="ts">
import MultiSelectMenu from "@/components/SelectMenus/MultiSelectMenu.vue";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import TagChip from "@/components/Tags/TagChip.vue";
import type { Tag } from "@/types/tag";
import { useCurrentInboxStore } from "@/stores/currentInbox";

const props = defineProps<{
  selectedTags: Tag[];
}>();

const emit = defineEmits<{
  "update:selectedTags": [selectedTags: Tag[]];
  select: [tag: Tag];
  unselect: [tag: Tag];
}>();

const { inbox } = storeToRefs(useCurrentInboxStore());
const options = computed(() => inbox.value?.tags || []);

function selectTag(tag: Tag) {
  if (props.selectedTags.includes(tag)) return;
  emit("select", tag);
  emit("update:selectedTags", [...props.selectedTags, tag]);
}

function unselectTag(tag: Tag) {
  emit("unselect", tag);
  emit(
    "update:selectedTags",
    props.selectedTags.filter((t) => t.id !== tag.id)
  );
}
</script>
