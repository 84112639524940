<template>
  <div class="inquiries-table">
    <div class="inquiries-table-rows">
      <template v-if="isLoading">
        <template v-for="i in 10" :key="i">
          <q-separator v-if="i > 0" />
          <inquiry-row-skeleton />
        </template>
      </template>
      <template v-else>
        <template v-for="(inquiry, idx) in inquiries" :key="inquiry.id">
          <q-separator v-if="idx > 0" />
          <inquiry-row
            :inquiry="inquiry"
            :inbox="inbox"
            :selected="selected.includes(inquiry)"
            @click="$emit('open', inquiry.id)"
            @add-tag="(tag) => addTag(inquiry, tag)"
            @remove-tag="(tag) => removeTag(inquiry, tag)"
            @update:status="
              (newStatus) => $emit('update:status', inquiry.id, newStatus)
            "
            @update:assigned-to="
              (user: User) => $emit('update:assigned-to', inquiry.id, user)
            "
            @update:selected="(newVal) => updateSelected(inquiry.id, newVal)"
            @retry-analysis="$emit('retry-analysis', inquiry.id)"
          />
        </template>
      </template>
    </div>
    <div class="inquiries-table-footer flex row no-wrap items-center q-px-md">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { addTag as apiAddTag, removeTag as apiRemoveTag } from "@/api/inquiry";
import type { Inbox } from "@/types/inbox";
import type { InquiryStatus, ShortInquiry } from "@/types/inquiry";
import type { Tag } from "@/types/tag";
import type { User } from "@/types/user";
import InquiryRow from "./InquiryRow.vue";
import InquiryRowSkeleton from "./InquiryRowSkeleton.vue";

const props = defineProps<{
  inquiries?: ShortInquiry[];
  selected: ShortInquiry[];
  isLoading: boolean;
  rowsNumber: number;
  inbox: Inbox | null;
}>();

const emit = defineEmits<{
  open: [id: number];
  "update:assigned-to": [id: number, user: User];
  "update:selected": [selected: readonly ShortInquiry[]];
  "update:status": [id: number, newStatus: InquiryStatus];
  "retry-analysis": [id: number];
}>();

function updateSelected(inquiryId: number, newVal: boolean) {
  if (!props.inquiries) return;

  if (newVal) {
    emit("update:selected", [
      ...props.selected,
      props.inquiries.find((i) => i.id === inquiryId)!,
    ]);
  } else {
    emit("update:selected", [
      ...props.selected.filter((i) => i.id !== inquiryId),
    ]);
  }
}

async function addTag(row: ShortInquiry, tag: Tag) {
  if (!props.inbox) return;
  row.tags.push(tag);
  await apiAddTag(props.inbox.id, row.id, tag.id);
}
async function removeTag(row: ShortInquiry, tag: Tag) {
  if (!props.inbox) return;
  row.tags = row.tags.filter((t: Tag) => t.id !== tag.id);
  await apiRemoveTag(props.inbox.id, row.id, tag.id);
}
</script>

<style scoped lang="scss">
.inquiries-table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.inquiries-table-rows {
  flex-grow: 0;
  flex-shrink: 1;
  height: 100%;
  overflow-y: auto;
}

.inquiries-table-footer {
  height: 48px;
  border-top: 1px solid $separator-color;
  z-index: 10;
  flex-shrink: 0;
}
</style>
