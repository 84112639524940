<template>
  <div class="slim-page">
    <settings-page-header section="inbox" page="tags" />
    <q-linear-progress indeterminate class="q-mb-sm" v-if="isUpdating" />
    <q-skeleton v-if="isLoading" />
    <q-list v-else separator>
      <q-item v-for="tag in tags" :key="tag.id">
        <q-item-section avatar>
          <q-avatar :style="`background-color: ${tag.color}`" size="sm" />
        </q-item-section>
        <q-item-section>
          <q-item-label>{{ tag.label }}</q-item-label>
        </q-item-section>
        <q-item-section side v-if="canWrite">
          <div class="row items-center">
            <q-btn
              dense
              flat
              size="sm"
              icon="sym_r_edit"
              @click="editTag(tag)"
              aria-label="Edit tag"
              :disable="isUpdating"
            />
            <q-btn
              dense
              flat
              size="sm"
              icon="sym_r_delete"
              @click="deleteTag(tag)"
              aria-label="Delete tag"
              class="q-ml-sm"
              :disable="isUpdating"
            />
          </div>
        </q-item-section>
      </q-item>
    </q-list>
    <q-btn
      v-if="canWrite"
      dense
      color="primary"
      class="q-mt-md float-right"
      :label="$t('settings.inbox.tags.createTag')"
      @click="createTag"
      :disable="isUpdating"
    />
  </div>
</template>

<script setup lang="ts">
import { getInbox as apiGetInbox } from "@/api/inbox";
import * as api from "@/api/tag";
import CreateOrEditTagDialog from "@/components/Settings/Inbox/CreateOrEditTagDialog.vue";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import type { Tag } from "@/types/tag";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const tags = ref<Tag[]>([]);
const isLoading = ref(false);
const isUpdating = ref(false);

const { organizationId, inboxId } = useRouteParams();

const { hasCurrentInboxRole } = storeToRefs(useCurrentPermissionsStore());
const canWrite = computed(() => hasCurrentInboxRole.value["inbox_admin"]);

const { t } = useI18n();

async function loadData() {
  isLoading.value = true;

  try {
    const inbox = await apiGetInbox(organizationId.value, inboxId.value);
    tags.value = inbox.tags;
  } finally {
    isLoading.value = false;
  }
}
watch([organizationId, inboxId], loadData, { immediate: true });

const q = useQuasar();

function editTag(tag: Tag) {
  q.dialog({
    component: CreateOrEditTagDialog,
    componentProps: { tag },
  }).onOk(async (data: { label: string; color: string }) => {
    isUpdating.value = true;
    try {
      await api.updateTag(inboxId.value, {
        id: tag.id,
        label: data.label,
        color: data.color,
      });
    } finally {
      isUpdating.value = false;
      await loadData();
    }
  });
}

function deleteTag(tag: Tag) {
  q.dialog({
    title: t("settings.inbox.tags.confirmDelete.title", { label: tag.label }),
    message: t("settings.inbox.tags.confirmDelete.message", {
      label: tag.label,
    }),
    cancel: true,
    persistent: true,
  }).onOk(async () => {
    isUpdating.value = true;
    try {
      await api.deleteTag(inboxId.value, tag.id);
    } finally {
      isUpdating.value = false;
      await loadData();
    }
  });
}

function createTag() {
  q.dialog({
    component: CreateOrEditTagDialog,
  }).onOk(async (data: { label: string; color: string }) => {
    isUpdating.value = true;
    try {
      await api.createTag(inboxId.value, data);
    } finally {
      isUpdating.value = false;
      await loadData();
    }
  });
}
</script>
