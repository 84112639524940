<template>
  <q-ajax-bar color="primary" position="bottom" ref="el" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { QAjaxBar } from "quasar";

const el = ref<InstanceType<typeof QAjaxBar> | undefined>(undefined);

defineExpose({
  start: () => {
    el.value?.start();
  },
  stop: () => {
    el.value?.stop();
  },
});
</script>
