<template>
  <sort-menu-button
    :options="sortOptions"
    :sort-by="sortBy"
    :descending="descending"
    @update-sort="$emit('updateSort', $event)"
  />
</template>

<script setup lang="ts">
import SortMenuButton from "../SortMenuButton.vue";
import { useI18n } from "vue-i18n";

defineProps<{
  sortBy: string | null;
  descending: boolean | null;
}>();

defineEmits<{
  updateSort: [sort: { sortBy: string; descending: boolean }];
}>();

const { t } = useI18n();

const sortOptions = [
  {
    value: "short_code",
    label: t("inboxPage.sortOptions.id"),
    icon: "sym_r_qr_code_2",
  },
  {
    value: "created_at",
    label: t("inboxPage.sortOptions.created_at"),
    icon: "sym_r_event",
  },
  {
    value: "status",
    label: t("inboxPage.sortOptions.status"),
    icon: "sym_r_assignment",
  },
  {
    value: "assigned_user_id",
    label: t("inboxPage.sortOptions.assigned_user"),
    icon: "sym_r_person",
  },
  {
    value: "requested_submission_date",
    label: t("inboxPage.sortOptions.requested_submission_date"),
    icon: "sym_r_calendar_clock",
  },
  {
    value: "requested_customer_submission_date",
    label: t("inboxPage.sortOptions.requested_customer_submission_date"),
    icon: "sym_r_event_busy",
  },
  {
    value: "offered",
    label: t("inboxPage.sortOptions.offered_positions"),
    icon: "sym_r_work",
  },
  {
    value: "progress",
    label: t("inboxPage.sortOptions.progress"),
    icon: "sym_r_timeline",
  },
];
</script>
