export default {
  Type: "Typ",
  "File size": "Dateigröße",
  Received: "Empfangen",
  Status: "Status",
  "Assigned to": "Zugewiesen an",
  Archive: "Archivieren",
  Unarchive: "Aus Archiv holen",
  Filename: "Dateiname",
  "File information": "Dateiinformationen",
  Fields: "Felder",
  Changes: "Änderungen",
  History: "Historie",
  files: "keine Dateien | eine Datei | {count} Dateien",
  attachments: "keine Anhänge | ein Anhang | {count} Anhänge",
  Request: "Anfrage",
  Owner: "Bearbeiter",
  Files: "Dateien",
  "Download as a single file": "Als einzelne Datei herunterladen",
  "Move to another request": "Zu einer anderen Anfrage verschieben",
  Progress: "Fortschritt",
};
