import { listOrganizationMembers } from "@/api/user";
import { useRouteParams } from "@/composables/useRouteParams";
import type { User, UserOrEmptyUser } from "@/types/user";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export const useCurrentOrganizationUsersStore = defineStore(
  "currentOrganizationUsers",
  () => {
    const { organizationId } = useRouteParams();

    const users = ref<User[]>([]);

    async function refresh() {
      if (isNaN(organizationId.value)) {
        return;
      }
      try {
        users.value = await listOrganizationMembers(organizationId.value);
      } catch (error: any) {
        if (error.response?.status === 404) {
          // 404 errors are handled by currentOrganization store
          return;
        } else {
          throw error;
        }
      }
    }
    refresh();
    watch(organizationId, refresh);

    // dummy empty user to display "No user"
    const emptyUser = {
      id: null,
      email: "",
      firstName: "",
      lastName: "",
    };

    const usersWithEmptyUser = computed<UserOrEmptyUser[]>(() => [
      emptyUser,
      ...users.value,
    ]);

    return { users, refresh, emptyUser, usersWithEmptyUser };
  }
);
