<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" seamless position="right">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row justify-between items-center">
        <div class="text-xl">{{ $t("keyboardShortcutsDialog.title") }}</div>
        <q-btn flat round dense icon="sym_r_close" @click="onDialogHide" />
      </q-card-section>
      <q-card-section>
        <div class="text-subtitle1">
          {{ $t("keyboardShortcutsDialog.positionsPage") }}
        </div>
        <table class="keyboard-shortcuts-table">
          <tr
            v-for="{ key, label } in keyboardShortcutsPositionsPage"
            :key="key"
          >
            <td>
              <kbd>{{ key }}</kbd>
            </td>
            <td>{{ $t(`keyboardShortcutsDialog.descriptions.${label}`) }}</td>
          </tr>
        </table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useDialogPluginComponent } from "quasar";

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits,
]);

const { dialogRef, onDialogHide } = useDialogPluginComponent();

const keyboardShortcutsPositionsPage = [
  { key: "↑", label: "selectPrevious" },
  { key: "↓", label: "selectNext" },
  { key: "→", label: "expand" },
  { key: "←", label: "collapse" },
  { key: "a", label: "offer" },
  { key: "f", label: "markFinished" },
  { key: "Enter", label: "selectProduct" },
  { key: "Esc", label: "unselect" },
  { key: "Tab", label: "jumpToNextInput" },
];
</script>

<style scoped lang="scss">
.keyboard-shortcuts-table {
  td {
    vertical-align: top;
    padding: 5px;
  }
}
</style>
