import { listInboxNames } from "@/api/inbox";
import { useRouteParams } from "@/composables/useRouteParams";
import type { NamedInbox } from "@/types/inbox";
import { defineStore } from "pinia";
import { ref, watch } from "vue";

export const useCurrentOrganizationInboxNamesStore = defineStore(
  "organizationInboxNames",
  () => {
    const inboxNames = ref<NamedInbox[]>([]);

    const { organizationId } = useRouteParams();

    async function loadInboxNames() {
      if (isNaN(organizationId.value)) return;
      inboxNames.value = await listInboxNames(organizationId.value);
    }
    watch(organizationId, loadInboxNames, { immediate: true });

    return {
      inboxNames,
    };
  }
);
