<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <file-dropzone
          @drop="upload"
          @click="fileInputEl?.click()"
          class="cursor-pointer"
        >
          <div
            style="
              height: 250px;
              border: 1px solid #ccc;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <div class="column items-center" style="gap: 8px">
              <q-icon name="sym_r_cloud_upload" size="5rem" color="primary" />
              <div>
                {{ $t("Drop files here or click to select") }}
              </div>
            </div>
          </div>
        </file-dropzone>
        <input
          type="file"
          multiple
          style="display: none"
          ref="fileInputEl"
          accept="application/pdf, .eml, .msg, .x83, .x93, .d83"
          @change="(event: any) => upload(event.target?.files)"
        />
      </q-card-section>
      <q-card-actions class="q-px-md q-pb-md" align="right">
        <q-btn flat :label="$t('Cancel')" @click="onDialogCancel" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useLeadUpload } from "@/composables/useLeadUpload";
import { useRouteParams } from "@/composables/useRouteParams";
import { useDialogPluginComponent } from "quasar";
import { ref } from "vue";
import FileDropzone from "./FileDropzone.vue";

const props = defineProps<{
  loadData: () => Promise<void>;
}>();
defineEmits(useDialogPluginComponent.emits);

const { inboxId } = useRouteParams();

const { uploadDocuments } = useLeadUpload(inboxId, props.loadData);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const fileInputEl = ref<HTMLInputElement | null>(null);

async function upload(files: FileList) {
  uploadDocuments(files);
  onDialogOK();
}
</script>
