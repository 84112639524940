<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section
        class="q-gutter-y-sm"
        @keydown.enter.stop.prevent="onOKClick"
      >
        <q-input
          ref="labelInput"
          v-model="label"
          :label="$t('tag.label')"
          outlined
          dense
          autofocus
          lazy-rules
          :rules="[(val) => !!val || $t('settings.inbox.tags.labelRequired')]"
        />
        <q-input
          ref="colorInput"
          :label="$t('tag.color')"
          outlined
          dense
          v-model="color"
          :rules="['anyColor']"
        >
          <template v-slot:append>
            <q-icon
              name="sym_r_colorize"
              class="cursor-pointer"
              :style="`color: ${color}`"
            >
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-color v-model="color" format-model="hex" />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat dense label="Cancel" @click="onDialogCancel" />
        <q-btn
          dense
          color="primary"
          label="OK"
          @click="onOKClick"
          :disable="!label || labelInput?.hasError || colorInput?.hasError"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { DEFAULT_TAG_COLOR } from "@/config/constants";
import type { Tag } from "@/types/tag";
import { QInput, useDialogPluginComponent } from "quasar";
import { ref, watchEffect } from "vue";

const props = defineProps<{
  tag?: Tag;
}>();

const label = ref(props.tag?.label);
const color = ref(props.tag?.color || DEFAULT_TAG_COLOR);
watchEffect(() => {
  label.value = props.tag?.label;
  color.value = props.tag?.color || DEFAULT_TAG_COLOR;
});

const labelInput = ref<QInput | null>(null);
const colorInput = ref<QInput | null>(null);

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

function onOKClick() {
  onDialogOK({ label: label.value, color: color.value });
}
</script>
