<template>
  <div class="row no-wrap items-baseline gap-sm multi-email-input">
    <div class="col-auto">
      {{ label }}
    </div>
    <q-select
      ref="selectEl"
      dense
      borderless
      multiple
      hide-dropdown-icon
      input-debounce="0"
      use-input
      :model-value="emails"
      :disable="disable"
      :options="options"
      new-value-mode="add-unique"
      @new-value="handleNewValue"
      @update:model-value="$emit('update:emails', $event)"
      @keydown.backspace.exact.stop="handleBackspace"
      @filter="handleFilter"
    >
      <template #selected-item="scope">
        <q-chip
          dense
          removable
          outline
          color="neutral-5"
          @remove="scope.removeAtIndex(scope.index)"
          :tabindex="scope.tabindex"
          class="q-ml-none email-chip"
        >
          <div v-if="scope.opt.displayName">{{ scope.opt.displayName }}</div>
          <div v-else>{{ scope.opt.address }}</div>
          <q-tooltip>{{ scope.opt.address }}</q-tooltip>
        </q-chip>
      </template>
      <template #option="scope">
        <q-item v-bind="scope.itemProps">
          <q-item-section>
            <template v-if="scope.opt.displayName">
              <q-item-label>
                {{ scope.opt.displayName }}
              </q-item-label>
              <q-item-label caption>
                {{ scope.opt.address }}
              </q-item-label>
            </template>
            <template v-else>
              <q-item-label>
                {{ scope.opt.address }}
              </q-item-label>
            </template>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script setup lang="ts">
import type { SupplierRfqEmail } from "@/types/supplierRfq";
import type { QSelect } from "quasar";
import { ref } from "vue";

const props = defineProps<{
  emails: SupplierRfqEmail[];
  label: string;
  disable?: boolean;
  contacts: SupplierRfqEmail[];
}>();

const emit = defineEmits<{
  "update:emails": [emails: SupplierRfqEmail[]];
}>();

const selectEl = ref<QSelect | null>(null);

const options = ref<SupplierRfqEmail[]>(props.contacts);

function handleNewValue(
  emailAddress: string,
  done: (
    email?: SupplierRfqEmail,
    mode?: "add-unique" | "add" | "toggle"
  ) => void
) {
  if (props.emails.find((email) => email.address === emailAddress)) {
    done();
    return;
  }
  const email = { address: emailAddress };
  done(email, "add-unique");
}

function handleBackspace() {
  if (!props.emails.length) return;
  if (getTextCursorPosition() !== 0) return;
  emit("update:emails", props.emails.slice(0, -1));
}

function getTextCursorPosition() {
  if (!selectEl.value) return -1;
  const inputEl = selectEl.value.$el.querySelector("input");
  if (!inputEl) return -1;
  return inputEl.selectionStart;
}

type voidFn = () => void;
type FilterUpdate = (callbackFn: voidFn, afterFn?: voidFn) => void;

function handleFilter(val: string, update: FilterUpdate) {
  update(() => {
    const needle = val.toLowerCase();
    options.value = props.contacts.filter(
      (v) => `${v.displayName} ${v.address}`.toLowerCase().indexOf(needle) > -1
    );
  });
}
</script>

<style scoped lang="scss">
.multi-email-input {
  min-height: 42px;
}

.email-chip {
  background-color: white !important;
  div {
    color: var(--q-neutral-10);
    cursor: default;
  }
}
</style>
