<template>
  <q-avatar
    v-if="props.user?.email.length"
    size="sm"
    :style="`background-color: ${color};`"
    text-color="white"
  >
    {{ initials }}
  </q-avatar>
  <q-avatar
    v-else
    size="sm"
    color="transparent"
    text-color="neutral-7"
    class="user-avatar-empty"
  >
    <q-icon name="sym_r_person" size="14px" />
  </q-avatar>
</template>

<script setup lang="ts">
import type { ShortUser } from "@/types/user";
import { colors } from "quasar";
import { computed } from "vue";

const props = defineProps<{ user: Omit<ShortUser, "id"> | null }>();

const initials = computed(() => {
  if (!props.user) return "";
  if (!props.user.firstName && !props.user.lastName) {
    return props.user.email.slice(0, 2);
  }
  const { firstName, lastName } = props.user;

  if (!firstName) {
    return lastName[0];
  }
  if (!lastName) {
    return firstName[0];
  }
  return `${firstName[0]}${lastName[0]}`;
});

const color = computed(() => {
  if (!props.user) return "";
  const hash = props.user.email
    .toString()
    .split("")
    .reduce((a, b) => (a << 5) - a + b.charCodeAt(0), 0);
  const hue = ((hash % 360) + 360) % 360;
  const rgb = colors.hsvToRgb({ h: hue, s: 23, v: 53 });
  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
});
</script>

<style scoped lang="scss">
.user-avatar-empty {
  border: 1px dashed $neutral-7;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
