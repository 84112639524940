import { client } from "@/api/client/client";
import type {
  CommentBodyContent,
  InquiryHistoryEntry,
  InquiryHistoryEntryComment,
} from "@/types/inquiryHistory";
import type { ShortUser } from "@/types/user";

interface ApiInquiryHistoryEntry
  extends Omit<InquiryHistoryEntry, "createdAt"> {
  createdAt: string;
}

export async function getInquiryHistory(
  organizationId: number,
  inboxId: number,
  inquiryId: number
): Promise<InquiryHistoryEntry[]> {
  const { data } = await client.get<ApiInquiryHistoryEntry[]>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/history/`
  );
  return data.map(parseInquiryHistoryEntry);
}

export async function deleteInquiryHistory(
  inboxId: number,
  inquiryId: number,
  entryId: number
): Promise<void> {
  await client.delete(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/history/${entryId}/`
  );
}

function parseInquiryHistoryEntry(
  entry: ApiInquiryHistoryEntry
): InquiryHistoryEntry {
  const parsedEntry = {
    ...entry,
    createdAt: new Date(entry.createdAt),
  } as InquiryHistoryEntry;

  if (entry.type === "COMMENT") {
    const commentEntry = entry as ApiInquiryHistoryComment;
    return {
      ...parsedEntry,
      extraData: {
        ...parsedEntry.extraData,
        editedAt: commentEntry.extraData.editedAt
          ? new Date(commentEntry.extraData.editedAt)
          : undefined,
      },
    } as InquiryHistoryEntryComment;
  }

  return parsedEntry;
}

interface ApiInquiryHistoryComment
  extends Omit<ApiInquiryHistoryEntry, "extraData"> {
  extraData: {
    bodyContent: CommentBodyContent;
    editedBy?: ShortUser;
    editedAt?: string;
  };
}

export async function addComment(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  body: CommentBodyContent
): Promise<InquiryHistoryEntryComment> {
  const { data } = await client.post<ApiInquiryHistoryEntry>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/history/`,
    {
      type: "COMMENT",
      extraData: {
        bodyContent: body,
      },
    }
  );
  return parseInquiryHistoryEntry(data) as InquiryHistoryEntryComment;
}

export async function updateComment(
  organizationId: number,
  inboxId: number,
  inquiryId: number,
  entryId: number,
  body: CommentBodyContent
): Promise<InquiryHistoryEntryComment> {
  const { data } = await client.patch<ApiInquiryHistoryComment>(
    `/inboxes/${inboxId}/inquiries/${inquiryId}/history/${entryId}/`,
    {
      extraData: {
        bodyContent: body,
      },
    }
  );

  return parseInquiryHistoryEntry(data) as InquiryHistoryEntryComment;
}
