<template>
  <div
    class="q-pa-md row items-center justify-center"
    style="height: 100vh; width: 100vw"
  >
    <q-linear-progress indeterminate color="primary" style="max-width: 400px" />
  </div>
</template>

<script setup lang="ts">
import { useCurrentUserStore } from "@/stores/currentUser";
import { computed, watch } from "vue";
import { useRouter } from "vue-router";

const userStore = useCurrentUserStore();
const router = useRouter();

const organizationId = computed(() => userStore.organizationRoles[0]?.objectId);

if (organizationId.value && !isNaN(organizationId.value)) {
  redirectToOrganization();
} else {
  watch(organizationId, redirectToOrganization);
  userStore.loadUser();
}

async function redirectToOrganization() {
  if (!organizationId.value || isNaN(organizationId.value)) return;

  router.replace({
    name: "organization",
    params: { organizationId: organizationId.value },
  });
}
</script>
@/stores/currentUser
