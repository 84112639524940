<template>
  <div class="column">
    <donut-chart :data="elements" :size="100" :border-size="16" />
  </div>
</template>

<script setup lang="ts">
import type { InquiryProgress } from "@/types/inquiry";
import { computed } from "vue";
import DonutChart from "./DonutChart.vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  progress: InquiryProgress;
}>();

const { t } = useI18n();

const elements = computed(() => {
  const { own, neutral, external } = props.progress;
  return [
    { value: own, color: "var(--q-secondary-7)", label: t("own") },
    { value: external, color: "var(--q-neutral-5)", label: t("external") },
    { value: neutral, color: "var(--q-neutral-2)", label: t("neutral") },
  ];
});
</script>
