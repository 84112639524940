<template>
  <q-page class="wrapper">
    <div class="box">
      <img class="logo q-mb-lg" src="@/assets/logo/kinisto-logo.svg" />
      <template v-if="passwordResetSent">
        <div class="text-body1 text-center full-width q-mb-md">
          {{
            $t(
              "Password reset successful. You can now login with your new password."
            )
          }}
        </div>
        <router-link
          class="full-width text-center q-mt-md"
          :to="{ name: 'login' }"
        >
          {{ $t("Go to login") }}
        </router-link>
      </template>
      <template v-else-if="isValidLink">
        <div class="text-body1 text-center full-width q-mb-md">
          {{ $t("Set a new password") }}
        </div>
        <q-form v-if="isValidLink" @submit="confirmResetPassword">
          <set-password-form
            :user-inputs="[]"
            @update:errorStatus="isPasswordValid = !$event"
            @update:password="password = $event"
            autofocus
          />
          <q-banner
            rounded
            class="bg-negative text-white q-mb-md"
            v-if="passwordResetFailed"
          >
            {{ passwordResetFailedMessage }}
            <template v-slot:action>
              <q-btn
                flat
                color="white"
                :label="$t('Dismiss')"
                @click="passwordResetFailed = false"
              />
            </template>
          </q-banner>
          <q-btn
            v-if="!passwordResetSent"
            unelevated
            color="primary"
            type="submit"
            class="full-width q-mt-xs"
            :label="$t('Set a new password')"
            :disable="!isPasswordValid"
          />
        </q-form>
      </template>
      <template v-else>
        <div class="q-mb-md">{{ $t("Invalid password reset link.") }}</div>
        <div class="q-mb-md">{{ $t("Please request a new link.") }}</div>
      </template>
      <q-inner-loading :showing="isLoading">
        <q-spinner size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </q-page>
</template>

<script setup lang="ts">
import { confirmResetPassword as apiConfirmResetPassword } from "@/api/auth";
import { getCsrfCookie } from "@/api/client/csrf";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import SetPasswordForm from "@/components/SetPasswordForm.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();

const password = ref("");
const isLoading = ref(false);
const passwordResetSent = ref(false);
const passwordResetFailed = ref(false);
const passwordResetFailedMessage = ref("");
const isPasswordValid = ref(false);

const uidb64 = computed(() => router.currentRoute.value.query.uidb64 as string);
const token = computed(() => router.currentRoute.value.query.token as string);
const isValidLink = computed(
  () => uidb64.value && token.value && uidb64.value.length && token.value.length
);

// Make sure CSRF cookie is set before resetting password
getCsrfCookie();

async function confirmResetPassword(): Promise<void> {
  isLoading.value = true;
  passwordResetFailed.value = false;
  passwordResetFailedMessage.value = "";
  passwordResetSent.value = false;
  try {
    await apiConfirmResetPassword(uidb64.value, token.value, password.value);
    passwordResetSent.value = true;
  } catch (e: any) {
    if (e.response?.data.password) {
      passwordResetFailedMessage.value = e.response.data.password
        .map(t)
        .join(" ");
    } else {
      passwordResetFailedMessage.value = t(
        "Password reset failed. Please try again or contact your administrator."
      );
    }
    passwordResetFailed.value = true;
  } finally {
    isLoading.value = false;
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $neutral-1;
}
.box {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: stretch;
  justify-content: center;
  background-color: #fff;
  border: 1px solid $neutral;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 24rem;
  flex-grow: 0;
  flex-shrink: 1;
}
.logo {
  height: 2rem;
}
</style>
@/api/client/csrf
