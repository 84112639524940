import type { InboxView, ShortInbox } from "@/types/inbox";
import { computed, type Ref } from "vue";
import { useRouteParams } from "./useRouteParams";

export interface InboxViewSpec {
  label: string;
  isActive: Ref<boolean>;
  link: Ref<{
    name: string;
    params: {
      organizationId: number;
      inboxId: number;
      inboxView: InboxView;
    };
  }>;
}

const INBOX_VIEWS = [
  "in_progress",
  "mine",
  "archived",
  "finalized",
  "all",
] as const;

export function useInboxViews() {
  const { organizationId } = useRouteParams();

  function getInboxViews(inbox: ShortInbox) {
    return INBOX_VIEWS.map((view) => ({
      label: view,
      link: computed(() => ({
        name: "inbox",
        params: {
          organizationId: organizationId.value,
          inboxId: inbox.id,
          inboxView: view,
        },
      })),
    }));
  }

  return { getInboxViews };
}
