<template>
  <div>
    <supplier-rfq-item
      v-for="supplierRfq in supplierRfqs"
      :key="supplierRfq.id"
      :supplier-rfq="supplierRfq"
    />
  </div>
</template>

<script setup lang="ts">
import { useCurrentSupplierRfqsStore } from "@/stores/currentSupplierRfqs";
import { storeToRefs } from "pinia";
import SupplierRfqItem from "./SupplierRfqItem.vue";

const { supplierRfqs } = storeToRefs(useCurrentSupplierRfqsStore());
</script>
