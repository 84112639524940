<template>
  <div
    class="amount-and-unit-input-container row no-wrap items-center"
    :class="{ highlighted }"
  >
    <div class="q-px-xs">
      <number-input
        ref="amountInputEl"
        :value="amount"
        embedded
        nullable
        :precision="3"
        :disabled="disabled"
        @update:value="$emit('update:amount', $event)"
        @keydown.stop.left
        @keydown.stop.right
        @keydown.stop.escape="amountInputEl?.blur()"
        class="amount-input"
        :class="{ focused: isFocused }"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </div>
    <div class="unit-select-container row items-center q-px-xs">
      <unit-select
        :selected="selectedUnit"
        :choices="unitChoices"
        :disabled="disabled"
        @update:selected="$emit('update:selectedUnit', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import UnitSelect from "@/components/InquiryPositionsPage/OfferPosition/UnitSelect.vue";
import NumberInput from "@/components/NumberInput.vue";
import type { Unit } from "@/types/product";
import { ref } from "vue";

withDefaults(
  defineProps<{
    amount: number | null;
    selectedUnit: Unit | null;
    unitChoices: Unit[];
    highlighted?: boolean;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
    highlighted: false,
  }
);

const emit = defineEmits<{
  "update:amount": [amount: number | null];
  "update:selectedUnit": [selectedUnit: Unit | null];
  focus: [];
  blur: [];
}>();

const amountInputEl = ref<typeof NumberInput | null>(null);
const isFocused = ref(false);

function focus() {
  amountInputEl.value?.focus();
  amountInputEl.value?.select();
}

defineExpose({ focus });

function handleFocus() {
  emit("focus");
}

function handleBlur() {
  emit("blur");
}
</script>

<style scoped lang="scss">
.amount-and-unit-input-container {
  border: 1px solid $neutral-5;
  border-radius: 4px;
  height: 24px;
  overflow: hidden;
  background-color: $white;

  &:hover {
    border: 1px solid $neutral-9;
  }

  &.highlighted {
    border: 1px solid $warning;
    outline: 1px solid $warning;
  }

  &:focus-within {
    border: 1px solid $neutral-9;
    outline: 1px solid $neutral-9;
  }
}

.amount-input {
  width: 80px;
}

.unit-select-container {
  border-left: 1px solid $neutral-5;
  background-color: $neutral-2;
  height: 100%;
}
</style>
