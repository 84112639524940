/** This store hold information about the current app user */
import type { FrontendPreferences } from "@/types/userSettings";
import { defineStore, storeToRefs } from "pinia";
import { useCurrentUserStore } from "./currentUser";

export const useUserSettingsStore = defineStore("userSettings", () => {
  const userStore = useCurrentUserStore();
  const { user, userSettings } = storeToRefs(userStore);
  const { updateUserSettings } = userStore;

  async function updateFrontendPreferences(
    preferences: Partial<FrontendPreferences>
  ) {
    if (!user.value) {
      return;
    }

    await updateUserSettings({
      frontendPreferences: {
        ...userSettings.value.frontendPreferences,
        ...preferences,
      },
    });
  }

  return {
    userSettings,
    updateUserSettings,
    updateFrontendPreferences,
  };
});
