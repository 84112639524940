<template>
  <q-page class="fit" :style-fn="() => ''">
    <div
      v-if="is404"
      class="fit row items-center justify-center text-lg text-neutral-7"
    >
      {{ $t("inquiryPage.notFound") }}
    </div>
    <template v-else>
      <q-splitter v-model="pageSplitPercentage" class="full-height">
        <template #before>
          <header-bar>
            <template #right>
              <inquiry-navigation />
            </template>
          </header-bar>
          <email-or-document-display class="page-below-header-bar" />
        </template>
        <template #after>
          <header-bar-right>
            <template #right>
              <q-btn
                flat
                dense
                color="neutral-7"
                icon="sym_r_list_alt"
                :to="{ name: 'inquiry-positions' }"
                class="no-decoration"
                size="md"
              >
                <q-tooltip>
                  {{ $t("inquiryPage.progress.edit") }}
                </q-tooltip>
              </q-btn>
            </template>
          </header-bar-right>
          <div
            class="page-below-header-bar bg-neutral-1 q-pa-md column gap-md no-wrap"
          >
            <inquiry-values-display />
            <inquiry-history />
          </div>
        </template>
      </q-splitter>
    </template>
  </q-page>
</template>

<script setup lang="ts">
import HeaderBar from "@/components/Header/HeaderBar.vue";
import HeaderBarRight from "@/components/InquiryHeaderBarRight.vue";
import InquiryNavigation from "@/components/InquiryNavigation.vue";
import EmailOrDocumentDisplay from "@/components/InquiryPage/EmailOrDocumentDisplay.vue";
import InquiryValuesDisplay from "@/components/InquiryPage/InquiryValuesDisplay/InquiryValuesDisplay.vue";
import InquiryHistory from "@/components/InquiryPage/InquiryHistory/InquiryHistory.vue";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import { ref } from "vue";

const pageSplitPercentage = ref(50);

const { is404 } = storeToRefs(useCurrentInquiryStore());
</script>

<style scoped lang="scss">
.no-link-decoration {
  font-weight: inherit;

  &:hover {
    text-decoration: none;
  }
}
</style>
