import { ref, watch, type Ref } from "vue";

/** Persist the value of a ref to localstorage */
export function persistentRef<T>(key: string, initialValue: T): Ref<T> {
  if (typeof window === "undefined") {
    return ref<T>(initialValue) as Ref<T>;
  }
  key = "persistentRef:" + key;
  const value = localStorage.getItem(key);
  const r = ref<T>(value ? JSON.parse(value) : initialValue);
  watch(r, (v) => {
    localStorage.setItem(key, JSON.stringify(v));
  });
  return r as Ref<T>;
}
