import { MAX_NUM_SUPPLIERS_SHOWN_IN_DROPDOWN } from "@/config/constants";
import type { Paginated } from "@/types/pagination";
import type { Supplier, SupplierDetails } from "@/types/supplier";
import { client } from "./client/client";
import { parseCursor } from "./utils";

export async function findSuppliers(
  organizationId: number,
  search: string | null,
  limit: number = MAX_NUM_SUPPLIERS_SHOWN_IN_DROPDOWN
) {
  const response = await client.get<{ results: Supplier[] }>(
    `/organizations/${organizationId}/suppliers/`,
    { params: { search, page_size: limit } }
  );
  return response.data.results.slice(0, limit);
}

export async function listSuppliers(
  organizationId: number,
  search: string | null,
  cursor: string | null,
  pageSize: number
) {
  const response = await client.get<Paginated<Supplier>>(
    `/organizations/${organizationId}/suppliers/`,
    { params: { search, cursor, page_size: pageSize, page_result_count: true } }
  );
  return {
    ...response.data,
    next: parseCursor(response.data.next),
    previous: parseCursor(response.data.previous),
  };
}

export async function getSupplier(organizationId: number, supplierId: number) {
  const response = await client.get<SupplierDetails>(
    `/organizations/${organizationId}/suppliers/${supplierId}/`
  );
  return response.data;
}
