<template>
  <q-separator />
  <div class="q-pb-md">
    <div class="row no-wrap justify-between">
      <div class="text-neutral-7 text-base">
        {{ $t("inquiryPage.inquiryHistory.title") }}
      </div>
      <inquiry-subscribe-button />
    </div>
    <div class="q-py-md">
      <inquiry-history-skeletons v-if="isLoading" />
      <div v-else class="column gap-xs">
        <template v-for="(entry, idx) in inquiryHistory" :key="entry.id">
          <inquiry-history-separator v-if="idx > 0" />
          <inquiry-history-comment
            v-if="entry.type === 'COMMENT'"
            :entry="entry"
          />
          <inquiry-history-entry v-else :entry="entry" />
        </template>
      </div>
    </div>
  </div>
  <div class="q-pb-md">
    <add-comment-box />
  </div>
</template>

<style lang="scss">
.history-highlight {
  color: $neutral-10;
  font-weight: 600;
}
</style>

<script setup lang="ts">
import InquirySubscribeButton from "@/components/InquiryPage/InquirySubscribeButton.vue";
import { useCurrentInquiryHistoryStore } from "@/stores/currentInquiryHistory";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import { useRouter } from "vue-router";
import AddCommentBox from "./AddCommentBox.vue";
import InquiryHistoryComment from "./InquiryHistoryComment.vue";
import InquiryHistoryEntry from "./InquiryHistoryEntry.vue";
import InquiryHistorySeparator from "./InquiryHistorySeparator.vue";
import InquiryHistorySkeletons from "./InquiryHistorySkeletons.vue";
import { waitFor } from "@/utils/waitFor";

const { inquiryHistory, isLoading } = storeToRefs(
  useCurrentInquiryHistoryStore()
);

const { currentRoute } = useRouter();

const hash = computed(() => currentRoute.value.hash);

watch(
  hash,
  async (newHash) => {
    if (!newHash) return;
    const entry = await waitFor(() => document.querySelector(newHash));
    if (!entry) return;
    entry.scrollIntoView();
  },
  {
    immediate: true,
  }
);
</script>
