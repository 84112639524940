<template>
  <div class="slim-page">
    <settings-page-header section="inbox" page="customStatuses" />
    <q-linear-progress indeterminate class="q-mb-sm" v-if="isUpdating" />
    <q-skeleton v-if="isLoading" />
    <q-list v-else separator>
      <q-item v-for="customStatus in customStatuses" :key="customStatus.id">
        <q-item-section avatar>
          <q-avatar
            :style="`background-color: ${customStatus.color}`"
            size="sm"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="row items-baseline">
            <div class="text-sm">{{ customStatus.name }}</div>
            <div class="q-ml-sm text-xs text-neutral-7">
              ({{ $t(customStatus.originalStatus) }})
            </div>
          </q-item-label>
        </q-item-section>
        <q-item-section side v-if="canWrite">
          <div class="row items-center">
            <q-btn
              dense
              flat
              size="sm"
              icon="sym_r_edit"
              @click="editCustomStatus(customStatus)"
              aria-label="Edit customStatus"
              :disable="isUpdating"
            />
            <q-btn
              dense
              flat
              size="sm"
              icon="sym_r_delete"
              @click="deleteCustomStatus(customStatus)"
              aria-label="Delete customStatus"
              class="q-ml-sm"
              :disable="isUpdating"
            />
          </div>
        </q-item-section>
      </q-item>
    </q-list>
    <q-btn
      v-if="canWrite"
      dense
      color="primary"
      class="q-mt-md float-right"
      :label="$t('settings.inbox.customStatuses.createCustomStatus')"
      @click="createCustomStatus"
      :disable="isUpdating"
    />
  </div>
</template>

<script setup lang="ts">
import * as api from "@/api/customStatus";
import CreateOrEditCustomStatusDialog from "@/components/Settings/Inbox/CreateOrEditCustomStatusDialog.vue";
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentPermissionsStore } from "@/stores/currentPermissions";
import type { CustomStatus } from "@/types/customStatus";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const customStatuses = ref<CustomStatus[]>([]);
const isLoading = ref(false);
const isUpdating = ref(false);

const { organizationId, inboxId } = useRouteParams();

const { hasCurrentInboxRole } = storeToRefs(useCurrentPermissionsStore());
const canWrite = computed(() => hasCurrentInboxRole.value["inbox_admin"]);

const { t } = useI18n();

async function loadData() {
  isLoading.value = true;

  try {
    customStatuses.value = await api.listCustomStatuses(inboxId.value);
  } finally {
    isLoading.value = false;
  }
}
watch([organizationId, inboxId], loadData, { immediate: true });

const q = useQuasar();

function editCustomStatus(customStatus: CustomStatus) {
  q.dialog({
    component: CreateOrEditCustomStatusDialog,
    componentProps: { customStatus },
  }).onOk(async (data: Omit<CustomStatus, "id">) => {
    isUpdating.value = true;
    try {
      await api.updateCustomStatus(inboxId.value, customStatus.id, data);
    } finally {
      isUpdating.value = false;
      await loadData();
    }
  });
}

function deleteCustomStatus(customStatus: CustomStatus) {
  q.dialog({
    title: t("settings.inbox.customStatuses.confirmDelete.title", {
      name: customStatus.name,
    }),
    message: t("settings.inbox.customStatuses.confirmDelete.message", {
      name: customStatus.name,
    }),
    cancel: true,
    persistent: true,
  }).onOk(async () => {
    isUpdating.value = true;
    try {
      await api.deleteCustomStatus(inboxId.value, customStatus.id);
    } finally {
      isUpdating.value = false;
      await loadData();
    }
  });
}

function createCustomStatus() {
  q.dialog({
    component: CreateOrEditCustomStatusDialog,
  }).onOk(async (data: Omit<CustomStatus, "id">) => {
    isUpdating.value = true;
    try {
      await api.createCustomStatus(inboxId.value, data);
    } finally {
      isUpdating.value = false;
      await loadData();
    }
  });
}
</script>
