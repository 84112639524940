<template>
  <q-dialog ref="dialogRef">
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <div class="text-xl">{{ $t("selectBOQsDialog.title") }}</div>
      </q-card-section>
      <q-card-section>
        <div v-if="inquiry" class="column">
          <q-checkbox
            v-for="document in inquiry.documents"
            :key="document.id"
            dense
            class="q-my-xs"
            :disable="document.fileType == 'OTHER'"
            :model-value="document.isBoq"
            :label="document.filename"
            @update:model-value="
              ($event) => {
                dataModified = true;
                document.isBoq = $event;
              }
            "
          >
            <q-tooltip v-if="document.fileType == 'OTHER'">
              {{ $t("selectBOQsDialog.nonPDF") }}
            </q-tooltip>
          </q-checkbox>
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          dense
          flat
          :label="$t('selectBOQsDialog.cancel')"
          @click="onDialogCancel"
        />
        <q-btn
          dense
          color="primary"
          :label="$t('selectBOQsDialog.ok')"
          :disable="isLoading || !inquiry"
          @click="handleOkClick"
        />
      </q-card-actions>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import * as api from "@/api/inquiry";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";
import { useDialogPluginComponent, useQuasar } from "quasar";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
const { dialogRef, onDialogOK, onDialogCancel } = useDialogPluginComponent();
const isLoading = ref(false);
const errorMessage = ref<string | null>(null);
const dataModified = ref(false);

const { inboxId } = useRouteParams();

const { inquiry } = storeToRefs(useCurrentInquiryStore());
const { loadData } = useCurrentInquiryStore();

const q = useQuasar();
const { t } = useI18n();

function handleOkClick() {
  if (!inquiry.value) return;
  if (dataModified.value) {
    q.dialog({
      title: t("selectBOQsDialog.warningTitle"),
      message: t("selectBOQsDialog.warningMessage"),
      ok: {
        dense: true,
        label: t("selectBOQsDialog.ok"),
      },
      cancel: {
        dense: true,
        flat: true,
        primary: true,
        label: t("selectBOQsDialog.cancel"),
      },
      persistent: true,
    }).onOk(() => {
      setInquiryBOQs();
    });
  } else {
    onDialogOK();
  }
}

onMounted(async () => {
  isLoading.value = true;
  try {
    await loadData();
  } catch (e) {
    onDialogCancel();
    console.error(e);
  } finally {
    isLoading.value = false;
    dataModified.value = false;
  }
});

async function setInquiryBOQs() {
  if (!inquiry.value) return;
  errorMessage.value = null;
  isLoading.value = true;
  inquiry.value.status = {
    id: "ANALYZING",
    name: "ANALYZING",
    originalStatus: "ANALYZING",
    isSelectable: false,
    color: "grey-6",
    custom: false,
  };

  try {
    await api.setInquiryBOQs(
      inboxId.value,
      inquiry.value.id,
      inquiry.value.documents.filter((d) => d.isBoq).map((d) => d.id)
    );
    onDialogOK();
  } catch (e: any) {
    errorMessage.value = t(e.response.data);
  } finally {
    isLoading.value = false;
  }
}
</script>
