<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row justify-between items-center">
        <div class="text-xl">{{ $t("contactDialog.title") }}</div>
        <q-btn flat round dense icon="sym_r_close" @click="onDialogHide" />
      </q-card-section>
      <q-card-section>
        <i18n-t keypath="contactDialog.text" tag="p" scope="global">
          <template #email>
            <a :href="`mailto:${$t('contactDialog.emailAddress')}`">{{
              $t("contactDialog.emailAddress")
            }}</a>
          </template>
        </i18n-t>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useDialogPluginComponent } from "quasar";

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits,
]);

const { dialogRef, onDialogHide } = useDialogPluginComponent();
</script>
