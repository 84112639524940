import * as quotationApi from "@/api/quotation";
import { useRouteParams } from "@/composables/useRouteParams";
import { DEFAULT_PAGE_SIZE_FOR_QUOTATIONS } from "@/config/constants";
import type {
  Quotation,
  QuotationStatus,
  QuotationWrite,
} from "@/types/quotation";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useRouter, type LocationQueryValue } from "vue-router";

const RELEVANT_ROUTES = ["quotations"];

export const useCurrentQuotationsStore = defineStore(
  "currentQuotations",
  () => {
    const { organizationId } = useRouteParams();
    const { currentRoute } = useRouter();
    const router = useRouter();

    const quotations = ref<Quotation[]>([]);
    const numResults = ref<number | null>(null);
    const nextCursor = ref<string | null>(null);
    const previousCursor = ref<string | null>(null);
    const pageNumber = ref<number>(1);
    const pageSize = ref<number>(DEFAULT_PAGE_SIZE_FOR_QUOTATIONS);
    const isLoading = ref<boolean>(false);

    const filterOptions = ref<quotationApi.ListQuotationFilterOptions>({});

    const isQuotationsRouteActive = () =>
      currentRoute.value &&
      RELEVANT_ROUTES.includes(currentRoute.value.name as string);

    function clearQuotations() {
      quotations.value = [];
      numResults.value = null;
      nextCursor.value = null;
      previousCursor.value = null;
      pageNumber.value = 1;
      pageSize.value = DEFAULT_PAGE_SIZE_FOR_QUOTATIONS;
      filterOptions.value = {};
    }

    const loadFilterOptionsFromRoute = () => {
      const query = currentRoute.value.query;
      filterOptions.value = {
        status: parseStatusQueryParam(query.status),
        assignedUserId: parseAssignedUserIdQueryParam(query.assignedUserId),
        inboxId: query.inboxId
          ? (query.inboxId as string).split(",").map((id) => parseInt(id))
          : undefined,
        customer: query.customer ? [query.customer as string] : undefined,
        inquiryShortCode: query.inquiryShortCode
          ? [query.inquiryShortCode as string]
          : undefined,
        buildingProject: query.buildingProject
          ? [query.buildingProject as string]
          : undefined,
      };
    };

    async function fetchQuotations(cursor: string | null = null) {
      isLoading.value = true;
      try {
        const { data, next, previous, count } =
          await quotationApi.listQuotations(
            organizationId.value,
            pageSize.value,
            cursor,
            filterOptions.value
          );
        quotations.value = data;
        numResults.value = count;
        nextCursor.value = next;
        previousCursor.value = previous;
      } finally {
        isLoading.value = false;
      }
    }

    async function reloadQuotations() {
      pageNumber.value = 1;
      await fetchQuotations();
    }

    async function goToNextPage() {
      if (!nextCursor.value) return;
      pageNumber.value = pageNumber.value + 1;
      await fetchQuotations(nextCursor.value);
    }

    async function goToPreviousPage() {
      if (!previousCursor.value) return;
      pageNumber.value = pageNumber.value - 1;
      await fetchQuotations(previousCursor.value);
    }

    async function updateQuotation(
      quotationId: number,
      data: Partial<Quotation>
    ) {
      const quotation = quotations.value.find(
        (quotation) => quotation.id === quotationId
      );

      if (!quotation) {
        throw new Error("Quotation not found");
      }

      const updatedQuotation = await quotationApi.updateQuotation(
        organizationId.value,
        quotationId,
        convertToWriteFormat(data)
      );

      Object.assign(quotation, updatedQuotation);
    }

    function addFilter(
      filterKey: keyof quotationApi.ListQuotationFilterOptions,
      filterValue: quotationApi.ListQuotationFilterOptions[keyof quotationApi.ListQuotationFilterOptions]
    ) {
      filterOptions.value = {
        ...filterOptions.value,
        [filterKey]: filterValue,
      };

      const query = { ...router.currentRoute.value.query };
      if (filterValue !== undefined) {
        query[filterKey] = createQueryParam(filterValue);
      } else {
        delete query[filterKey];
      }
      router.replace({ query });

      reloadQuotations();
    }

    function removeFilter(filterKey: string) {
      filterOptions.value = {
        ...filterOptions.value,
        [filterKey]: undefined,
      };

      const query = { ...router.currentRoute.value.query };
      delete query[filterKey];
      router.replace({ query });

      reloadQuotations();
    }

    watch(
      () => currentRoute.value,
      async (newCurrentRoute, oldCurrentRoute) => {
        if (!isQuotationsRouteActive()) {
          clearQuotations();
          return;
        }

        if (
          newCurrentRoute.params.organizationId !==
            oldCurrentRoute?.params.organizationId ||
          newCurrentRoute.name !== oldCurrentRoute?.name
        ) {
          loadFilterOptionsFromRoute();
          await reloadQuotations();
        }
      },
      { immediate: true }
    );

    async function setPageSize(newPageSize: number) {
      pageSize.value = newPageSize;
      await reloadQuotations();
    }

    const sortBy = computed(
      () => filterOptions.value.ordering?.replace(/^-/, "") ?? null
    );

    const descending = computed(
      () => filterOptions.value.ordering?.startsWith("-") || false
    );

    async function updateSort(sort: { sortBy: string; descending: boolean }) {
      filterOptions.value.ordering = sort.descending
        ? `-${sort.sortBy}`
        : sort.sortBy;
      await reloadQuotations();
    }

    return {
      quotations,
      updateQuotation,
      reloadQuotations,
      goToNextPage,
      goToPreviousPage,
      pageNumber,
      pageSize,
      numResults,
      nextCursor,
      previousCursor,
      isLoading,
      setPageSize,
      filterOptions,
      removeFilter,
      addFilter,
      sortBy,
      descending,
      updateSort,
    };
  }
);

function convertToWriteFormat(
  quotation: Partial<Quotation>
): Partial<QuotationWrite> {
  const { assignedUser, ...rest } = quotation;
  return {
    ...rest,
    assignedUserId: assignedUser === null ? null : assignedUser?.id,
  };
}

function parseStatusQueryParam(
  queryValue: LocationQueryValue | LocationQueryValue[]
): QuotationStatus[] {
  if (!queryValue) {
    return [];
  }

  if (Array.isArray(queryValue)) {
    return queryValue as QuotationStatus[];
  }

  return queryValue.split(",") as QuotationStatus[];
}

function createQueryParam(
  value: string | number | null | (string | number | null)[]
): string {
  if (Array.isArray(value)) {
    return value.map(createSingleQueryParam).join(",");
  }

  return createSingleQueryParam(value);
}

function createSingleQueryParam(value: string | number | null): string {
  return value === null ? "null" : value.toString();
}

function parseAssignedUserIdQueryParam(
  queryValue: LocationQueryValue | LocationQueryValue[]
): (number | null)[] {
  if (!queryValue) {
    return [];
  }

  if (Array.isArray(queryValue)) {
    return queryValue.map((userId) => parseUserId(userId as string));
  }

  return queryValue.split(",").map(parseUserId);
}

function parseUserId(userId: string): number | null {
  return userId === "null" ? null : parseInt(userId);
}
