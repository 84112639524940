<template>
  <q-btn :icon="icons.filter" :label="$t('Filter')" class="font-weight-inherit">
    <q-menu no-route-dismiss :offset="[0, 2]">
      <q-item
        dense
        clickable
        v-ripple
        active-class="text-primary-7 bg-primary-1"
        :active="filterStatusStrings.length > 0"
      >
        <q-item-section>
          <div class="row items-center">
            <q-icon name="sym_r_assignment" size="xs" class="q-mr-sm" />
            {{ $t("Status") }}
          </div>
        </q-item-section>
        <!-- TODO: implement cleanly using MultiSelectMenu -->
        <q-menu anchor="top right" self="top left" no-route-dismiss>
          <q-item
            dense
            clickable
            v-ripple
            v-for="status in availableInquiryStatuses"
            :key="status.name"
            @click="toggleStatusOption(status.name)"
            :class="{
              'bg-neutral-3': filterStatusStrings.includes(status.name),
            }"
          >
            <q-item-section>
              <q-item-label class="status-option-label">
                <q-checkbox
                  dense
                  size="sm"
                  class="q-mr-sm"
                  :model-value="filterStatusStrings.includes(status.name)"
                  @click="toggleStatusOption(status.name)"
                />
                <status-chip :status="status" />
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-menu>
      </q-item>
      <q-item
        dense
        clickable
        v-ripple
        active-class="text-primary-7 bg-primary-1"
        :active="(filterSender && filterSender?.length > 0) || false"
      >
        <q-item-section>
          <div class="row items-center">
            <q-icon name="sym_r_alternate_email" size="xs" class="q-mr-sm" />
            {{ $t("email.sender") }}
          </div>
        </q-item-section>
        <q-menu anchor="center right" self="center left" no-route-dismiss>
          <q-item dense>
            <q-input
              borderless
              dense
              :placeholder="$t('inboxPage.filterMenuButton.searchSender')"
              v-model="filterSenderString"
              debounce="300"
              autofocus
            />
          </q-item>
        </q-menu>
      </q-item>
      <q-item
        dense
        clickable
        v-ripple
        active-class="text-primary-7 bg-primary-1"
        :active="
          (filterBuildingProject && filterBuildingProject.length > 0) || false
        "
      >
        <q-item-section>
          <div class="row items-center">
            <q-icon
              :name="icons.inquiry.buildingProject"
              size="xs"
              class="q-mr-sm"
            />
            {{ $t("inquiry.buildingProject") }}
          </div>
        </q-item-section>
        <q-menu anchor="center right" self="center left" no-route-dismiss>
          <q-item dense>
            <q-input
              borderless
              dense
              :placeholder="
                $t('inboxPage.filterMenuButton.searchBuildingProject')
              "
              v-model="filterBuildingProjectString"
              debounce="300"
              autofocus
            />
          </q-item>
        </q-menu>
      </q-item>
      <q-item
        dense
        clickable
        v-ripple
        active-class="text-primary-7 bg-primary-1"
        :active="filterUserIds?.length > 0"
      >
        <q-item-section>
          <div class="row items-center">
            <q-icon name="sym_r_person" size="xs" class="q-mr-sm" />
            {{ $t("User") }}
          </div>
        </q-item-section>
        <multi-user-select-menu
          anchor="top right"
          self="top left"
          no-route-dismiss
          v-model:selected-users="selectedUsers"
        />
      </q-item>
      <q-item
        dense
        clickable
        v-ripple
        active-class="text-primary-7 bg-primary-1"
        :active="filterTagsIds?.length > 0"
      >
        <q-item-section>
          <div class="row items-center">
            <q-icon name="sym_r_sell" size="xs" class="q-mr-sm" />
            {{ $t("inquiry.tag") }}
          </div>
        </q-item-section>
        <multi-tag-select-menu
          anchor="top right"
          self="top left"
          no-route-dismiss
          v-model:selected-tags="selectedTags"
        />
      </q-item>
    </q-menu>
  </q-btn>
</template>

<script setup lang="ts">
import StatusChip from "@/components/Status/StatusChip.vue";
import { useCurrentInboxStore } from "@/stores/currentInbox";
import { useCurrentInboxUsersStore } from "@/stores/currentInboxUsers";
import type { UserOrEmptyUser } from "@/types/user";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import MultiUserSelectMenu from "../User/MultiUserSelectMenu.vue";
import MultiTagSelectMenu from "@/components/Tags/MultiTagSelectMenu.vue";
import type { Tag } from "@/types/tag";
import icons from "@/config/icons";

const props = defineProps<{
  filterStatusStrings: string[];
  filterTagsIds: number[];
  filterUserIds: (number | null)[];
  filterSender: string | null;
  filterBuildingProject: string | null;
}>();

const emit = defineEmits<{
  "update:filterStatusStrings": [statuses: string[]];
  "update:filterTagsIds": [tagIds: number[]];
  "update:filterUserIds": [userIds: (number | null)[]];
  "update:filterSender": [sender: string | null];
  "update:filterBuildingProject": [sender: string | null];
}>();

const { availableInquiryStatuses } = storeToRefs(useCurrentInboxStore());
const { usersWithEmptyUser } = storeToRefs(useCurrentInboxUsersStore());
const { inbox } = storeToRefs(useCurrentInboxStore());

const filterSenderString = computed({
  get: () => props.filterSender,
  set: (sender: string | null) => {
    emit("update:filterSender", sender);
  },
});

const filterBuildingProjectString = computed({
  get: () => props.filterBuildingProject,
  set: (buildingProject: string | null) => {
    emit("update:filterBuildingProject", buildingProject);
  },
});

function toggleStatusOption(status: string) {
  const newStatuses = props.filterStatusStrings.includes(status)
    ? props.filterStatusStrings.filter((s) => s !== status)
    : [...props.filterStatusStrings, status];
  emit("update:filterStatusStrings", newStatuses);
}

const selectedUsers = computed({
  get: () =>
    usersWithEmptyUser.value.filter((user) =>
      props.filterUserIds.includes(user.id)
    ),
  set: (users: UserOrEmptyUser[]) => {
    emit(
      "update:filterUserIds",
      users.map((user) => user.id)
    );
  },
});

const selectedTags = computed({
  get: () => {
    if (!inbox.value) return [];
    return inbox.value.tags.filter((tag) =>
      props.filterTagsIds.includes(tag.id)
    );
  },
  set: (tags: Tag[]) => {
    emit(
      "update:filterTagsIds",
      tags.map((tag) => tag.id)
    );
  },
});
</script>

<style scoped lang="scss">
.status-option-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
</style>
