<template>
  <q-table
    flat
    dense
    lass=".master-data-table"
    table-header-class="bg-neutral-3"
    row-key="id"
    :loading="isLoading"
    :columns="columns"
    :rows="data"
    :rows-per-page-options="[10, 20, 50, 100]"
    v-model:pagination="pagination"
    @request="(request) => $emit('request', request)"
  >
    <template #pagination="scope">
      {{ (scope.pagination.page - 1) * scope.pagination.rowsPerPage + 1 }}-
      {{
        Math.min(scope.pagination.page * scope.pagination.rowsPerPage, total)
      }}
      {{ $t("of") }} {{ total }}
      <q-btn
        icon="sym_r_chevron_left"
        color="neutral-8"
        round
        dense
        flat
        :disable="scope.isFirstPage || isLoading"
        @click="scope.prevPage"
      />
      <q-btn
        icon="sym_r_chevron_right"
        color="neutral-8"
        round
        dense
        flat
        :disable="scope.isLastPage || isLoading"
        @click="scope.nextPage"
      />
    </template>
    <template #body-cell-externalId="scope">
      <q-td :props="scope">
        <highlight-text :text="scope.value" :search-text="props.searchText" />
      </q-td>
    </template>
    <template #body-cell-name="scope">
      <q-td :props="scope">
        <highlight-text :text="scope.value" :search-text="props.searchText" />
      </q-td>
    </template>
  </q-table>
</template>

<script setup lang="ts">
import type { QTableProps } from "quasar";
import { computed } from "vue";
import HighlightText from "../HighlightText.vue";

const props = defineProps<{
  isLoading: boolean;
  data: unknown[];
  pageSize: number;
  page: number;
  total: number;
  searchText: string;
  columns: QTableProps["columns"];
}>();

defineEmits<{
  request: [request: { pagination: QTableProps["pagination"] }];
}>();

const pagination = computed({
  get: () => ({
    page: props.page,
    rowsPerPage: props.pageSize,
    rowsNumber: props.total,
  }),
  // Update is done via handleUpdate
  set: () => {},
});
</script>

<style lang="scss">
.master-data-table {
  .q-table__middle {
    width: 100%;
  }

  th {
    color: $neutral-7;
  }

  thead tr th {
    position: sticky;
    z-index: 1;
  }

  thead tr:first-child th {
    top: 0;
  }

  /* this is when the loading indicator appears */
  &q-table--loading thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
  }

  /* prevent scrolling behind sticky top row on focus */
  tbody {
    /* height of all previous header rows */
    scroll-margin-top: 48px;
  }
}
</style>
