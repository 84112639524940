<template>
  <multi-select-menu
    anchor="top right"
    self="top left"
    :offset="[0, 0]"
    no-route-dismiss
    :options="options"
    :selected-options="selectedOptions"
    :search-placeholder="$t('Search status')"
    id-value="name"
    :search-values="['label']"
    :label-fn="(opt: StatusOption) => opt.label"
    @select="$emit('select', $event.name)"
    @unselect="$emit('unselect', $event.name)"
    @update:selectedOptions="
      $emit(
        'update:selectedStatuses',
        $event.map((o) => o.name)
      )
    "
  >
    <template #option-label="{ option }">
      <quotation-status-chip :status="option.name" />
    </template>
  </multi-select-menu>
</template>

<script setup lang="ts">
import MultiSelectMenu from "@/components/SelectMenus/MultiSelectMenu.vue";
import {
  QUOTATION_STATUS_OPTIONS,
  type QuotationStatus,
} from "@/types/quotation";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import QuotationStatusChip from "./QuotationStatusChip.vue";

const props = defineProps<{
  selectedStatuses: QuotationStatus[];
}>();

defineEmits<{
  select: [status: QuotationStatus];
  unselect: [status: QuotationStatus];
  "update:selectedStatuses": [status: QuotationStatus[]];
}>();

type StatusOption = {
  name: QuotationStatus;
  label: string;
};

const { t } = useI18n();

const options: StatusOption[] = QUOTATION_STATUS_OPTIONS.map((status) => ({
  name: status,
  label: t(`quotationStatus.${status}`),
}));

const selectedOptions = computed(() =>
  options.filter((option) => props.selectedStatuses.includes(option.name))
);
</script>
