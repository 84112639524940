<template>
  <router-link :to="to" class="no-underline text-neutral-10">
    <q-item
      dense
      clickable
      :active="isExactActive"
      active-class="bg-neutral-4 text-neutral-10"
    >
      <q-item-section :class="inset ? 'q-ml-xl' : 'q-ml-lg'">
        <slot name="label">
          <q-item-label>{{ $t(label) }}</q-item-label>
        </slot>
      </q-item-section>
    </q-item>
  </router-link>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router";
import { useLink } from "vue-router";

const props = withDefaults(
  defineProps<{
    label: string;
    to: RouteLocationRaw;
    inset?: boolean;
  }>(),
  {
    inset: false,
  }
);

const { isExactActive } = useLink({ to: props.to });
</script>
