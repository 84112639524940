<template>
  <div class="row no-wrap gap-sm q-py-sm q-px-lg bg-neutral-2">
    <div class="gaeb-display-id-col-width">
      {{ element.referenceIdLevels.join(".") }}
    </div>
    <div class="col text-bold" v-html="element.name"></div>
  </div>
  <gaeb-element
    v-for="(subElement, idx) in element.elements"
    :element="subElement"
    :key="getPositionIdOrNull(subElement) ?? idx"
    :is-html="isHtml"
  />
</template>
<script setup lang="ts">
import GaebElement from "@/components/GaebViewer/GaebElement.vue";
import type {
  GaebElement as GaebElementType,
  GaebSection as GaebSectionType,
} from "@/types/gaeb";

defineProps<{
  element: GaebSectionType;
  isHtml: boolean;
}>();

const getPositionIdOrNull = (element: GaebElementType) => {
  if (element.type === "position") return element.referenceId;
  return null;
};
</script>

<style scoped lang="scss"></style>
