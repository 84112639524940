<template>
  <div
    class="product-chip"
    :style="{
      border: isAiSuggestion
        ? `1px solid ${confidenceBackgroundColor}`
        : '1px solid var(--q-neutral-4)',
    }"
  >
    <div class="product-id">
      <div class="ellipsis full-width">{{ product.name }}</div>
    </div>
    <div
      v-if="isAiSuggestion"
      class="confidence-indicator"
      :style="`color: ${confidenceTextColor}; background-color: ${confidenceBackgroundColor}`"
    >
      {{ confidenceToPercentage(confidence) }}
    </div>
    <q-tooltip>
      <div class="row items-center no-wrap" style="max-width: 400px">
        <div class="column no-wrap gap-xs">
          <div>{{ product.externalId }}</div>
          <div>{{ product.name }}</div>
          <div v-if="product.description?.length" style="white-space: pre-wrap">
            {{ product.description }}
          </div>
          <div v-if="isAiSuggestion" class="q-mt-sm">
            {{ $t("Confidence") }}: {{ confidenceToPercentage(confidence) }}
          </div>
        </div>
      </div>
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import type { Product } from "@/types/product";
import { confidenceToPercentage } from "@/utils/confidence";
import { getConfidenceColor } from "@/utils/confidenceColor";
import { computed } from "vue";
import { colors } from "quasar";

const props = defineProps<{
  product: Product;
  isAiSuggestion: boolean;
  confidence?: number;
}>();

const confidenceColor = computed(() =>
  props.confidence === undefined
    ? "neutral-10"
    : getConfidenceColor(props.confidence)
);

const confidenceTextColor = computed(() =>
  props.confidence === undefined
    ? "neutral-10"
    : colors.lighten(confidenceColor.value, -50)
);

const confidenceBackgroundColor = computed(() =>
  props.confidence === undefined ? "neutral-4" : `${confidenceColor.value}55`
);
</script>

<style scoped lang="scss">
.product-chip {
  background-color: $neutral-2;
  color: $neutral-10;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: space-between;
  max-width: 200px;

  .product-id {
    padding: 0px 4px;
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    align-items: center;
    min-width: 0;
  }

  .confidence-indicator {
    padding-left: 4px;
    padding-right: 4px;
    color: $neutral-9;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    flex-basis: 0;
  }
}
</style>
