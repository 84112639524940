<template>
  <div class="row no-wrap items-center justify-start">
    <q-circular-progress
      :value="progressPercentage"
      :max="100"
      color="primary-3"
      track-color="neutral-3"
      size="24px"
      class="q-mr-sm"
      :thickness="0.35"
      rounded
    >
      {{ progressPercentage.toFixed(0) }}%
    </q-circular-progress>
    <div class="text-xs line-height-1">
      {{ progress.completed }} / {{ progress.offered }} <br />
      <span class="text-deemphasized">({{ progress.total }})</span>
    </div>
    <q-tooltip :delay="500">
      <ul class="q-pl-md">
        <li>
          {{
            $t("inquiriesTable.inquiryProgressDisplay.tooltip.total", {
              total: progress.total,
            })
          }}
        </li>
        <li>
          {{
            $t("inquiriesTable.inquiryProgressDisplay.tooltip.offered", {
              offered: progress.offered,
            })
          }}
        </li>
        <li>
          {{
            $t("inquiriesTable.inquiryProgressDisplay.tooltip.completed", {
              completed: progress.completed,
            })
          }}
        </li>
      </ul>
    </q-tooltip>
  </div>
</template>

<script setup lang="ts">
import type { InquiryProgress } from "@/types/inquiry";
import { computed } from "vue";
const props = defineProps<{
  progress: InquiryProgress;
}>();

const progressPercentage = computed(() => {
  return (props.progress.completed / props.progress.offered) * 100;
});
</script>

<style scoped lang="scss">
.line-height-1 {
  line-height: 1;
}
</style>
