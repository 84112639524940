<template>
  <q-list separator>
    <q-item v-if="inquiry?.hasQuotation" dense>
      <q-item-section>
        <q-item-label class="ellipsis">
          <q-icon name="sym_r_local_offer" class="q-mr-sm" color="neutral-8" />
          {{ $t("inquiryPage.quotationFiles.finalQuotation") }}
        </q-item-label>
      </q-item-section>
      <q-item-section side right>
        <q-btn
          flat
          dense
          size="sm"
          icon="sym_r_cloud_download"
          color="neutral-8"
          @click.stop.prevent="downloadQuotation(inquiry.id)"
        >
          <q-tooltip :delay="1000" anchor="center middle" self="center middle">
            {{ $t("inquiryPage.quotationFiles.downloadFile") }}
          </q-tooltip>
        </q-btn>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import { downloadQuotation } from "@/api/draftQuotation";
import { useCurrentInquiryStore } from "@/stores/currentInquiry";
import { storeToRefs } from "pinia";

const { inquiry } = storeToRefs(useCurrentInquiryStore());
</script>
