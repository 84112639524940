<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-form @submit="onSubmit">
        <q-card-section>
          <q-select
            :label="$t('Organization')"
            :disable="isLoading"
            :options="organizations"
            v-model="organization"
          >
            <template v-slot:selected>
              {{ organization?.id }} - {{ organization?.name }}
            </template>
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section>
                  <q-item-label>
                    {{ scope.opt.id }} - {{ scope.opt.name }}
                  </q-item-label>
                  <q-item-label caption>{{
                    scope.opt.description
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            color="primary"
            :label="$t('Cancel')"
            @click="onDialogCancel"
          />
        </q-card-actions>
      </q-form>
      <q-inner-loading :showing="isLoading" />
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { listOrganizations } from "@/api/organization";
import { useRouteParams } from "@/composables/useRouteParams";
import type { Organization } from "@/types/organization";
import { useDialogPluginComponent } from "quasar";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

const isLoading = ref(false);
const organization = ref<Organization | undefined>();
const organizations = ref<Organization[]>([]);

const router = useRouter();
const routeParams = useRouteParams();

onMounted(loadData);

async function loadData() {
  isLoading.value = true;

  try {
    organizations.value = await listOrganizations();
    organization.value = organizations.value.find(
      (o) => o.id == routeParams.organizationId.value
    );
    watch(organization, onSubmit);
  } finally {
    isLoading.value = false;
  }
}

defineEmits(useDialogPluginComponent.emits);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

async function onSubmit() {
  isLoading.value = true;

  try {
    router.push({
      name: "organization",
      params: { organizationId: organization.value?.id },
    });
  } finally {
    isLoading.value = false;
  }

  onDialogOK();
}
</script>
