import { LOADING_INDICATOR_DELAY_MS } from "@/config/constants";
import { ref, watch, type Ref } from "vue";

export function delayLoadingIndicator(isLoading: Ref<boolean>) {
  const showLoading = ref(false);
  let loadingTimeout: NodeJS.Timeout | null = null;

  watch(isLoading, (newValue) => {
    if (newValue) {
      loadingTimeout = setTimeout(() => {
        showLoading.value = true;
      }, LOADING_INDICATOR_DELAY_MS);
    } else {
      showLoading.value = false;
      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
    }
  });

  return showLoading;
}
