<template>
  <multi-select-menu
    anchor="top right"
    self="top left"
    :offset="[0, 0]"
    no-route-dismiss
    :options="options"
    :selected-options="selectedOptions"
    :search-placeholder="$t('Search status')"
    id-value="name"
    :search-values="['label']"
    :label-fn="(opt: InboxOption) => opt.label"
    @select="$emit('select', $event.name)"
    @unselect="$emit('unselect', $event.name)"
    @update:selectedOptions="
      $emit(
        'update:selectedInboxIds',
        $event.map((o) => o.name)
      )
    "
  />
</template>

<script setup lang="ts">
import MultiSelectMenu from "@/components/SelectMenus/MultiSelectMenu.vue";
import { useInboxesStore } from "@/stores/inboxes";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const { inboxes } = storeToRefs(useInboxesStore());

const props = defineProps<{
  selectedInboxIds: number[];
}>();

defineEmits<{
  select: [inboxId: number];
  unselect: [inboxId: number];
  "update:selectedInboxIds": [inboxIds: number[]];
}>();

type InboxOption = {
  name: number;
  label: string;
};

const options: InboxOption[] =
  inboxes.value?.map((inbox) => ({
    name: inbox.id,
    label: inbox.name,
  })) ?? [];

const selectedOptions = computed(() => {
  console.log(props.selectedInboxIds);
  return options.filter((option) =>
    props.selectedInboxIds.includes(option.name)
  );
});
</script>
