<template>
  <q-input
    v-if="value !== undefined"
    dense
    outlined
    v-model="value"
    v-bind="$attrs"
    :label="$t(`organization.details.${property}`)"
  />
  <q-skeleton v-else type="QInput" />
</template>

<script setup lang="ts">
import type { OrganizationDetails } from "@/types/organization";
import { computed, inject, type Ref } from "vue";

const props = defineProps<{
  property: keyof OrganizationDetails;
}>();

const details = inject<Ref<OrganizationDetails | undefined>>(
  "organizationDetails"
);
if (!details) {
  throw new Error("organizationDetails has not been provided");
}

const value = computed({
  get: () => details.value?.[props.property],
  set: (value) => {
    if (!details.value) return;

    details.value[props.property] = (value as never) ?? "";
  },
});
</script>
