<template>
  <q-input
    bg-color="white"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event!.toString())"
    :type="passwordFieldType"
    lazy-rules
    :rules="[isNotEmpty]"
    :label="$t('Password')"
    for="login-box-password"
    v-bind="$attrs"
  >
    <template v-if="$slots.prepend" v-slot:prepend>
      <slot name="prepend" />
    </template>
    <template v-slot:append>
      <q-icon
        :name="visibilityIcon"
        @click="toggleVisibility"
        class="cursor-pointer"
      />
    </template>
  </q-input>
</template>

<script setup lang="ts">
import { useFormValidationRules } from "@/composables/formValidation/useFormValidationRules";
import { ref } from "vue";

defineProps<{
  modelValue: string;
}>();

defineEmits<{
  "update:modelValue": [value: string];
}>();

const passwordFieldType = ref<"password" | "text">("password");
const visibilityIcon = ref<string>("sym_r_visibility");

function toggleVisibility() {
  passwordFieldType.value =
    passwordFieldType.value == "password" ? "text" : "password";
  visibilityIcon.value =
    passwordFieldType.value == "password"
      ? "sym_r_visibility_off"
      : "sym_r_visibility";
}

const { isNotEmpty } = useFormValidationRules();
</script>
