import { inject, provide } from "vue";
import mitt, { type Emitter, type EventType } from "mitt";

type Events = Record<EventType, unknown>;

export function provideEventBus<T extends Events>(name: string): Emitter<T> {
  const events = mitt<T>();
  provide(name, events);
  return events;
}

export function useEventBus<T extends Events>(name: string) {
  const events = inject<Emitter<T>>(name);
  if (!events) {
    throw new Error(`useEventBus must be used within a ${name}Provider`);
  }
  return events;
}
