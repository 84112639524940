<template>
  <div
    class="unit-select"
    :class="{ hasChoices }"
    :disabled="disabled || undefined"
  >
    <div>{{ selected && $t(`productUnit.${selected}`) }}</div>
    <q-menu
      v-if="hasChoices && !disabled"
      fit
      anchor="bottom left"
      self="top left"
      :offset="[0, 2]"
      auto-close
    >
      <q-list dense separator class="unit-select-menu">
        <q-item
          v-for="choice in choices"
          :key="choice"
          clickable
          @click="$emit('update:selected', choice)"
          :active="choice === selected"
        >
          <q-item-section>{{ $t(`productUnit.${choice}`) }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </div>
</template>

<script setup lang="ts">
import type { Unit } from "@/types/product";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    selected: Unit | null;
    choices: Unit[];
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

defineEmits(["update:selected"]);

const hasChoices = computed(() => props.choices.length > 1);
</script>

<style scoped lang="scss">
.unit-select {
  color: $neutral-7;
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: nowrap;
  padding: 0 2px;
  min-width: 30px;
  text-align: left;

  &.hasChoices {
    cursor: pointer;
    color: $neutral-10;
  }
}

.unit-select-menu {
  font-size: smaller;
}
</style>
