<template>
  <q-btn
    dense
    flat
    color="neutral-7"
    icon="sym_r_done_all"
    @click="store.markAllNotificationsAsRead()"
  >
    <q-tooltip>
      {{ $t("notificationsPage.markAllAsRead") }}
    </q-tooltip>
  </q-btn>
</template>

<script setup lang="ts">
import { useNotificationsStore } from "@/stores/notifications";
const store = useNotificationsStore();
</script>
