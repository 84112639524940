<template>
  <div v-if="owner.dpNo || owner.awardNo">
    {{ $t("gaebViewer.awardNo") }}: {{ owner.dpNo ?? owner.awardNo }}
  </div>
  <div v-if="owner.address">
    <div v-for="(part, idx) in addressParts" :key="idx">
      {{ part }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GaebOwner } from "@/types/gaeb";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  owner: GaebOwner;
}>();

const { t } = useI18n();

const addressParts = computed(() =>
  [
    props.owner.address?.name1,
    props.owner.address?.name2,
    props.owner.address?.name3,
    props.owner.address?.name4,
    props.owner.address?.name5,
    props.owner.address?.street,
    [props.owner.address?.pCode, props.owner.address?.city]
      .filter(Boolean)
      .join(" "),
    props.owner.address?.country,
    props.owner.address?.phone &&
      t("gaebViewer.phone") + ": " + props.owner.address.phone,
    props.owner.address?.fax &&
      t("gaebViewer.fax") + ": " + props.owner.address.fax,
    props.owner.address?.email &&
      t("gaebViewer.email") + ": " + props.owner.address.email,
    props.owner.address?.vatid &&
      t("gaebViewer.vatid") + ": " + props.owner.address.vatid,
  ].filter(Boolean)
);
</script>
