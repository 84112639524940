<template>
  <slot />
</template>

<script setup lang="ts">
import type { OCRResult } from "@/types/ocrResult";
import { orderBy } from "lodash";
import { computed, onBeforeUnmount, ref } from "vue";
import {
  provideTextSelection,
  type SelectedWordIndex,
} from "./utils/ocrTextSelection";

const props = defineProps<{ ocrResult: OCRResult | null }>();

const isSelecting = ref(false);
const firstSelectedWord = ref<SelectedWordIndex | undefined>(undefined);
const lastSelectedWord = ref<SelectedWordIndex | undefined>(undefined);
const selectedText = computed(() => {
  if (
    firstSelectedWord.value === undefined ||
    lastSelectedWord.value === undefined
  ) {
    return null;
  }

  const [firstWord, lastWord] = orderBy(
    [firstSelectedWord.value, lastSelectedWord.value],
    ["pageNumber", "index"]
  );

  const pages = props.ocrResult?.pageLayouts?.slice(
    firstWord.pageNumber - 1,
    lastWord.pageNumber
  );

  if (!pages) {
    return null;
  }

  if (pages.length == 1)
    return pages[0].words
      .slice(firstWord.index, lastWord.index + 1)
      .map((word) => word.value)
      .join(" ");

  const firstPageWords = pages[0].words.slice(firstWord.index);
  const lastPageWords = pages[pages.length - 1].words.slice(
    0,
    lastWord.index + 1
  );

  const allWords = [
    ...firstPageWords,
    ...pages.slice(1, pages.length - 1).flatMap((page) => page.words),
    ...lastPageWords,
  ];

  return allWords.map((word) => word.value).join(" ");
});
const isKeypressHandlerRegistered = ref(false);

function registerKeypressHandler() {
  // this function is called by all pages with OCR text
  // we only want to register the keypress handler once
  if (isKeypressHandlerRegistered.value) {
    return;
  }
  document.addEventListener("keydown", handleKeyPress, false);
  isKeypressHandlerRegistered.value = true;
}

onBeforeUnmount(() => {
  if (isKeypressHandlerRegistered.value) {
    document.removeEventListener("keydown", handleKeyPress);
  }
});

function handleKeyPress(evt: KeyboardEvent) {
  if (evt.key === "Escape") {
    resetSelection();
  }
  if (evt.key === "c" && evt.ctrlKey) {
    copyText();
  }
}

function startSelection(pageNumber: number, index: number) {
  isSelecting.value = true;
  firstSelectedWord.value = { pageNumber, index };
  lastSelectedWord.value = { pageNumber, index };
}

function updateSelection(pageNumber: number, index: number) {
  if (!isSelecting.value) {
    return;
  }
  lastSelectedWord.value = { pageNumber, index };
}

function stopOrResetSelection() {
  if (
    !isSelecting.value ||
    firstSelectedWord.value === undefined ||
    lastSelectedWord.value === undefined
  ) {
    // Clicked outside of text layer
    resetSelection();
    return;
  }

  isSelecting.value = false;
}

function resetSelection() {
  isSelecting.value = false;
  firstSelectedWord.value = undefined;
  lastSelectedWord.value = undefined;
}

function isSelected(pageNumber: number, wordIndex: number) {
  if (
    firstSelectedWord.value == undefined ||
    lastSelectedWord.value == undefined
  )
    return false;

  const [firstWord, lastWord] = orderBy(
    [firstSelectedWord.value, lastSelectedWord.value],
    ["pageNumber", "index"]
  );

  if (pageNumber < firstWord.pageNumber || pageNumber > lastWord.pageNumber)
    return false;

  if (pageNumber == firstWord.pageNumber && wordIndex < firstWord.index)
    return false;

  if (pageNumber == lastWord.pageNumber && wordIndex > lastWord.index)
    return false;

  return true;
}

function copyText() {
  if (selectedText.value === null) {
    return;
  }
  navigator.clipboard.writeText(selectedText.value);
}

provideTextSelection({
  startSelection,
  updateSelection,
  stopOrResetSelection,
  isSelected,
  copyText,
  selectedText,
  registerKeypressHandler,
});
</script>
