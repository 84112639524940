<template>
  <input
    ref="el"
    v-model="inputValue"
    class="currentPageInput"
    @focus="(event: any) => event.target?.select()"
    @keypress="handleInput"
    @blur="handleBlur"
    data-testid="pdf-viewer-current-page-input"
  />
</template>

<script setup lang="ts">
import { nextTick, ref, watch, type Ref } from "vue";

const props = defineProps<{ currentPage: number }>();
const emit = defineEmits(["page-changed"]);

const inputValue = ref(props.currentPage);
const el: Ref<HTMLInputElement | undefined> = ref(undefined);

watch(
  () => props.currentPage,
  (newVal, oldVal) => {
    if (newVal != oldVal) inputValue.value = newVal;
  }
);

function handleInput(event: KeyboardEvent) {
  // submit on Enter
  if (event.key == "Enter") {
    if (el.value) el.value.blur();
    return;
  }

  // allow entering number keys
  const isNumberKey = /^[0-9]$/i.test(event.key);
  if (isNumberKey) return true;

  // disallow other keys
  event.preventDefault();
}

function handleBlur() {
  emit("page-changed", inputValue.value);
  // reset the page value, in case the change doesn't go through (e.g. invalid number)
  nextTick(() => (inputValue.value = props.currentPage));
}
</script>

<style lang="scss">
.currentPageInput {
  width: 2rem;
  outline: none;
  border: none;
  background-color: $neutral-4;
  text-align: center;
  border-radius: 4px;
}
</style>
