export default {
  "Password is too weak": "Passwort ist zu schwach",
  "Use a few words, avoid common phrases.":
    "Verwende ein paar Wörter, vermeide gängige Phrasen.",
  "No need for symbols, digits, or uppercase letters.":
    "Keine Notwendigkeit für Symbole, Zahlen oder Großbuchstaben.",
  "Add another word or two. Uncommon words are better.":
    "Füge ein oder zwei weitere Wörter hinzu. Ungewöhnliche Wörter sind besser.",
  "Straight rows of keys are easy to guess.":
    "Gerade Tastenreihen sind leicht zu erraten.",
  "Short keyboard patterns are easy to guess.":
    "Kurze Tastaturmuster sind leicht zu erraten.",
  "Use a longer keyboard pattern with more turns.":
    "Verwende ein längeres Tastaturmuster mit mehr Wendungen.",
  'Repeats like "aaa" are easy to guess.':
    'Wiederholungen wie "aaa" sind leicht zu erraten.',
  'Repeats like "abcabcabc" are only slightly harder to guess than "abc".':
    'Wiederholungen wie "abcabcabc" sind nur etwas schwerer zu erraten als "abc".',
  "Avoid repeated words and characters.":
    "Vermeide wiederholte Wörter und Zeichen.",
  'Sequences like "abc" or "6543" are easy to guess.':
    'Sequenzen wie "abc" oder "6543" sind leicht zu erraten.',
  "Avoid sequences.": "Vermeide Sequenzen.",
  "Recent years are easy to guess.":
    "Kürzlich vergangene Jahre sind leicht zu erraten.",
  "Avoid recent years.": "Vermeide kürzlich vergangene Jahre.",
  "Avoid years that are associated with you.":
    "Vermeide Jahre, die mit dir verbunden sind.",
  "Dates are often easy to guess.": "Daten sind oft leicht zu erraten.",
  "Avoid dates and years that are associated with you.":
    "Vermeide Daten und Jahre, die mit dir verbunden sind.",
  "This is a top-10 common password.": "Dies ist ein Top-10 gängiges Passwort.",
  "This is a top-100 common password.":
    "Dies ist ein Top-100 gängiges Passwort.",
  "This is a very common password.": "Dies ist ein sehr gängiges Passwort.",
  "This is similar to a commonly used password.":
    "Dies ähnelt einem häufig verwendeten Passwort.",
  "A word by itself is easy to guess.":
    "Ein einzelnes Wort ist leicht zu erraten.",
  "Names and surnames by themselves are easy to guess.":
    "Namen und Nachnamen allein sind leicht zu erraten.",
  "Common names and surnames are easy to guess.":
    "Gängige Namen und Nachnamen sind leicht zu erraten.",
  "Capitalization doesn't help very much.":
    "Großschreibung hilft nicht sehr viel.",
  "All-uppercase is almost as easy to guess as all-lowercase.":
    "Alles in Großbuchstaben ist fast genauso leicht zu erraten wie alles in Kleinbuchstaben.",
  "Reversed words aren't much harder to guess.":
    "Umgekehrte Wörter sind nicht viel schwerer zu erraten.",
  "Predictable substitutions like '@' instead of 'a' don't help very much.":
    "Vorhersehbare Substitutionen wie '@' anstelle von 'a' helfen nicht sehr viel.",
};
