<template>
  <password-input
    dense
    outlined
    hide-bottom-space
    v-model="password1"
    :label="$t('New password')"
    :autofocus="autofocus"
    :error="isPasswordError"
    @blur="validatePasswords"
    @update:modelValue="
      validatePasswords();
      emit('update:password', password1);
    "
  />
  <div
    class="row no-wrap items-start q-mt-xs gap-x-sm"
    v-if="password1.length > 0"
  >
    <div class="column gap-y-xs col-grow">
      <q-linear-progress
        :color="passwordMeterColor"
        :value="passwordStrength"
      />
      <div
        :class="`text-xs text-${passwordMeterColor} row items-center no-wrap gap-x-xs`"
      >
        {{ passwordStrengthString }}
        <q-icon
          v-if="passwordSuggestions.length > 0"
          name="sym_r_help"
          size="xs"
        >
          <q-tooltip v-if="passwordSuggestions.length > 0">
            <div v-for="suggestion in passwordSuggestions" :key="suggestion">
              {{ t(suggestion) }}
            </div>
          </q-tooltip>
        </q-icon>
      </div>
    </div>
  </div>
  <password-input
    dense
    outlined
    v-model="password2"
    :label="$t('Confirm password')"
    :error-message="password2 && $t('Passwords don\'t match')"
    :error="password2 !== '' && isPasswordMatchError"
    class="q-my-md"
    @update:modelValue="validatePasswords"
  />
</template>
<script setup lang="ts">
import { getCsrfCookie } from "@/api/client/csrf";
import PasswordInput from "@/components/PasswordInput.vue";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { zxcvbn } from "zxcvbn-typescript";

getCsrfCookie();

const isPasswordMatchError = ref(false);
const isPasswordError = ref(false);
const passwordSuggestions = ref<string[]>([]);
const passwordMeterColor = ref("");
const passwordStrengthString = ref("");
const password1 = ref("");
const password2 = ref("");
const passwordStrength = ref(0);

const { t } = useI18n();

const props = defineProps<{
  userInputs: string[];
  autofocus?: boolean;
}>();

const emit = defineEmits<{
  "update:password": [password: string];
  "update:errorStatus": [errorStatus: boolean];
}>();

function validatePasswords() {
  if (!password1.value) return;
  const testResult = zxcvbn(password1.value, props.userInputs);
  passwordStrength.value = (testResult.score + 1) / 5;
  if (testResult.score < 3) {
    passwordMeterColor.value = "negative";
    passwordStrengthString.value = t("setPasswordForm.passwordStrength.weak");
  } else if (testResult.score < 4) {
    passwordMeterColor.value = "warning";
    passwordStrengthString.value = t("setPasswordForm.passwordStrength.ok");
  } else {
    passwordMeterColor.value = "positive";
    passwordStrengthString.value = t("setPasswordForm.passwordStrength.strong");
  }

  if (testResult.score < 3) {
    isPasswordError.value = true;
    passwordSuggestions.value =
      testResult.feedback.suggestions.map(addDotIfMissing);
  } else {
    passwordSuggestions.value = [];
    isPasswordError.value = false;
  }

  isPasswordMatchError.value = password1.value !== password2.value;
}

watch([isPasswordError, isPasswordMatchError], () => {
  emit(
    "update:errorStatus",
    isPasswordError.value || isPasswordMatchError.value
  );
});

function addDotIfMissing(suggestion: string) {
  return suggestion.endsWith(".") ? suggestion : `${suggestion}.`;
}
</script>

<style scoped lang="scss"></style>
