<template>
  <div>
    <div class="logo-select">
      <div
        v-if="isUpdating"
        class="no-logo-frame row items-center justify-center"
      >
        <q-circular-progress indeterminate size="40px" color="primary" />
      </div>
      <file-dropzone
        v-else
        @drop="(files: File[]) => handleFileUploaded(files[0])"
      >
        <img v-if="details?.logo" :src="details.logo" class="current-logo" />
        <div
          v-else
          class="no-logo-frame bg-neutral-2 rounded-borders row items-center justify-center q-pa-md text-neutral-7 gap-xs"
        >
          <q-icon name="image" size="40px" color="neutral-5" />
          {{ $t("settings.organization.companyInformation.noLogo") }}
        </div>
        <template #overlay>
          <div
            class="bg-neutral-2 text-neutral-10 rounded-borders q-pa-sm q-ma-sm"
          >
            {{ $t("settings.organization.companyInformation.dropNewLogoHere") }}
          </div>
        </template>
      </file-dropzone>
    </div>
    <a
      v-if="details?.logo"
      href="javascript:void(0)"
      @click="handleRemoveLogo"
      :class="{ 'disabled-link': isUpdating }"
    >
      {{ $t("settings.organization.companyInformation.removeLogo") }}
    </a>
    <q-file
      dense
      outlined
      :disable="isUpdating"
      :model-value="undefined"
      class="file-picker q-mt-md"
      :label="$t('settings.organization.companyInformation.selectLogo')"
      @update:model-value="handleFileUploaded"
    >
      <template #prepend>
        <q-icon name="sym_r_attach_file" />
      </template>
    </q-file>
  </div>
</template>

<script setup lang="ts">
import { setLogo } from "@/api/organization";
import FileDropzone from "@/components/FileDropzone.vue";
import { useRouteParams } from "@/composables/useRouteParams";
import { useCurrentOrganizationStore } from "@/stores/currentOrganization";
import type { OrganizationDetails } from "@/types/organization";
import { ref } from "vue";
const { organizationId } = useRouteParams();

defineProps<{
  details: OrganizationDetails;
}>();

const { loadOrganization } = useCurrentOrganizationStore();

const isUpdating = ref(false);

const handleRemoveLogo = async () => {
  if (isUpdating.value) return;
  isUpdating.value = true;
  try {
    await setLogo(organizationId.value, null);
    await loadOrganization();
  } finally {
    isUpdating.value = false;
  }
};

const handleFileUploaded = async (file: File) => {
  if (isUpdating.value) return;
  isUpdating.value = true;
  try {
    await setLogo(organizationId.value, file);
    await loadOrganization();
  } finally {
    isUpdating.value = false;
  }
};
</script>

<style scoped lang="scss">
.current-logo {
  max-width: 200px;
  height: auto;
}

.no-logo-frame {
  width: 200px;
  height: 100px;
}

.logo-select {
  width: 200px;
}

.file-picker {
  max-width: 300px;
}

.disabled-link {
  pointer-events: none;
  color: $neutral-7;
}
</style>
