export default {
  Overview: "Übersicht",
  Dashboard: "Dashboard",
  Documents: "Dokumente",
  Settings: "Einstellungen",
  "New Documents": "Neue Dokumente",
  "All Documents": "Alle Dokumente",
  "My Documents": "Meine Dokumente",
  "Active Documents": "Aktive Dokumente",
  "Exported Documents": "Exportierte Dokumente",
  "Finalized Documents": "Abgeschlossene Dokumente",
  "Archived Documents": "Archivierte Dokumente",
  All: "Alle",
  "Assigned to me": "Mir zugewiesen",
  "In progress": "In Bearbeitung",
  Exported: "Exportiert",
  Finalized: "Abgeschlossen",
  Archived: "Archiviert",
};
